<template>
  <div class="song-wrapper" ref="top">
    <div class="mobile-header mb-2 justify-space-between">
      <h2 class="page-title">{{ isOwner ? 'My Songs' : 'Songs' }}</h2>
      <div v-if="isOwner">
        <button class="btn-yellow btn-upload" @click="$refs.UploadSongPopup.openPopup()"
                v-if="$route.query.tab === 'songs'">
          <img src="../assets/svg/icons/upload.svg" alt="Ukrmusic">
          <span>Upload song</span>
        </button>
        <button class="btn-yellow btn-upload" @click="$router.push({name: 'create playlist'})"
                v-if="$route.query.tab === 'playlist'">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.9981 4.58301C10.6964 14.6695 10.8481 14.9252 10.9981 17.4163" stroke="white" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.58203 10.9999C11.3316 10.8801 14.9871 10.8208 17.4154 10.9999" stroke="white" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span>New playlist</span>
        </button>
      </div>
    </div>
    <ProfileNavBar :userUuid="$route.query.userUuid"/>
    <div class="song-header">
      <h1 class="page-title">{{ isOwner ? 'My Songs' : 'Songs' }}</h1>
      <div v-if="isOwner">
        <button class="btn-yellow btn-upload" @click="$refs.UploadSongPopup.openPopup()"
                v-if="$route.query.tab === 'songs'">
          <img src="../assets/svg/icons/upload.svg" alt="Ukrmusic">
          <span>Upload song</span>
        </button>
        <button class="btn-yellow btn-upload" @click="$router.push({name: 'create playlist'})"
                v-if="$route.query.tab === 'playlist'">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.9981 4.58301C10.6964 14.6695 10.8481 14.9252 10.9981 17.4163" stroke="white" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.58203 10.9999C11.3316 10.8801 14.9871 10.8208 17.4154 10.9999" stroke="white" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span>New playlist</span>
        </button>
      </div>
    </div>
<!--    <div class="song-search">-->
<!--      <input type="search" id="song-search" placeholder="Search">-->
<!--      <div class="search-icon">-->
<!--        <img src="../assets/svg/icons/search.svg" alt="Ukrmusic">-->
<!--      </div>-->
<!--    </div>-->
    <div class="song-content">
      <ul class="content-tabs">
        <li class="tab-item" :style="{background: getTab === 'songs' ? '#87C0FF' : '#FFFFFF'}"
            @click="$router.push({path: `/profile/${userNickName}/songs`, query: {tab: 'songs', userUuid: $route.query.userUuid}}).catch(() => {})">
          <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.25154 17.2181C8.25154 17.276 7.66905 20.8893 6.60469 20.9881C4.66448 21.1683 1.05942 19.2652 1.34838 17.437C1.59772 15.8595 3.5053 15.301 5.13484 15.301C6.4684 15.4023 7.23704 16.3984 8.25154 17.2181ZM8.25154 17.2181C8.88297 14.6425 8.66656 15.5884 9.1125 12.9122C9.50059 10.5833 9.41758 8.24709 9.79942 5.92247C9.88326 5.41205 9.68859 4.64698 10.3534 4.43194C12.095 3.86859 13.7349 3.10386 15.4942 2.55258C17.2197 2.01189 19.1385 1.60863 20.7901 1.00651C21.3065 0.818281 21.0782 4.76372 21.0782 6.2465C21.0782 8.96216 21.154 11.6596 21.3065 14.3701M21.3065 14.3701C21.3553 15.2376 21.3453 15.451 21.1779 16.1062C20.5766 18.4604 17.5183 19.5866 16.2808 19.365C14.6454 19.0721 13.1941 17.9055 13.3448 16.4302C13.5381 14.5387 15.0795 13.818 17.2558 13.6621C18.4807 13.5744 21.2577 13.5027 21.3065 14.3701Z"
                :stroke="getTab === 'songs' ? '#FFFFFF' : '#0057B7'" stroke-width="2" stroke-linecap="round"/>
          </svg>
          <span :style="{color: getTab === 'songs' ? '#FFFFFF' : '#0057B7'}">Songs</span>
        </li>
        <li class="tab-item" :style="{background: getTab === 'playlist' ? '#87C0FF' : '#FFFFFF'}"
            @click="$router.push({path: `/profile/${userNickName}/songs`, query: {tab: 'playlist', page: '1', userUuid: $route.query.userUuid}}).catch(() => {})">
          <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.6896 15.6636C11.6896 15.707 11.2528 18.417 10.4545 18.4911C8.99934 18.6262 6.29554 17.1989 6.51226 15.8278C6.69927 14.6446 8.12995 14.2258 9.35211 14.2258C10.3523 14.3017 10.9288 15.0488 11.6896 15.6636ZM11.6896 15.6636C12.1632 13.7319 12.0009 14.4413 12.3354 12.4342C12.6264 10.6874 12.5642 8.93532 12.8505 7.19185C12.9134 6.80904 12.7674 6.23523 13.266 6.07396C14.5722 5.65145 15.8022 5.0779 17.1216 4.66444C18.4158 4.25892 19.8548 3.95648 21.0936 3.50488C21.4808 3.36371 21.3096 6.32279 21.3096 7.43487C21.3096 9.47162 21.3665 11.4947 21.4808 13.5276M21.4808 13.5276C21.5174 14.1782 21.5099 14.3382 21.3844 14.8296C20.9335 16.5953 18.6397 17.4399 17.7116 17.2737C16.485 17.0541 15.3966 16.1791 15.5096 15.0727C15.6545 13.654 16.8106 13.1135 18.4428 12.9966C19.3615 12.9308 21.4442 12.877 21.4808 13.5276Z"
                :stroke="getTab === 'playlist' ? '#FFFFFF' : '#0057B7'" stroke-width="2" stroke-linecap="round"/>
            <path
                d="M2.5 4.00275C3.46738 4.00275 4.43476 4.00275 5.40214 4.00275C6.09798 4.00275 6.79476 3.99336 7.4905 4.00928C7.79047 4.01615 8.09891 4.02118 8.39251 4.09099C8.51989 4.12127 8.80651 4.23626 8.5886 4.09099"
                :stroke="getTab === 'playlist' ? '#FFFFFF' : '#0057B7'" stroke-width="2" stroke-linecap="round"/>
            <path d="M2.5 10.1631H7.47089" :stroke="getTab === 'playlist' ? '#FFFFFF' : '#0057B7'" stroke-width="2"
                  stroke-linecap="round"/>
            <path d="M2.52941 16.1631H2.5" :stroke="getTab === 'playlist' ? '#FFFFFF' : '#0057B7'" stroke-width="2"
                  stroke-linecap="round"/>
          </svg>
          <span :style="{color: getTab === 'playlist' ? '#FFFFFF' : '#0057B7'}">Playlists</span>
        </li>
      </ul>
      <div class="separator my-5"></div>
      <div class="content">
        <div class="song-tab" v-if="getTab === 'songs'">
          <div v-if="flag">
            <ul class="song-tab-wrapper" v-if="getSongs && getSongs.length">
              <SongItemTemplateComponent
                  v-for="(song, index) in getSongs"
                  :key="song.id"
                  :song="song"
                  :identifier="'my-songs-component'"
                  :index="index"
                  :showMenu="true"
                  @songDeleted="getSongsRequest($route.query.userUuid)"
                  @toggleMenu="toggleMenuHandler"
                  :ref="`SongItemTemplateComponent${index}`"
              />
            </ul>
            <h1 class="content-v-else" v-else>{{ `${isOwner ? "You don't" : "Artist doesn't"} have any songs` }}</h1>
          </div>
          <PreLoader v-else/>
        </div>
        <div class="playlist-tab" v-if="getTab === 'playlist'">
          <div class="playlist-tab-wrapper" v-if="getPlayList && getPlayList.length">
            <PlayListItemComponent
                v-for="(playList, index) in getPlayList"
                :key="index"
                :playList="playList"
                :forPage="'my-songs'"
                :userUuid="$route.query.userUuid"
            />
          </div>
          <h1 class="content-v-else" v-else>{{ `${isOwner ? "You don't" : "Artist doesn't"} have any play list` }}</h1>
        </div>
      </div>
    </div>
    <v-pagination
        v-if="getTab === 'playlist' && getPlayList && getPlayList.length"
        v-model="page"
        :length="count"
        :total-visible="7"
        :color="'#0057B7'"
        prev-icon="<"
        next-icon=">"
        @input="onPagination"
        class="my-5"
    ></v-pagination>
    <UploadSongPopup ref="UploadSongPopup" @closePopupAfterUpload="closePopupAfterUpload"/>
    <ButtonBack  v-if="!isOwner"/>
  </div>
</template>

<script>
import SongItemTemplateComponent from "@/components/SongItemTemplateComponent";
import {mapActions, mapGetters} from "vuex";
import PlayListItemComponent from "@/components/PlayListItemComponent";
import ProfileNavBar from "@/components/profile/ProfileNavBar";
import UploadSongPopup from "@/components/popups/UploadSongPopup";
import PreLoader from "@/components/elements/Preloader";
import ButtonBack from "@/components/elements/ButtonBack";

export default {
  name: "SongAndPlaylistView",
  components: {ButtonBack, PreLoader, UploadSongPopup, PlayListItemComponent, SongItemTemplateComponent, ProfileNavBar},
  data() {
    return {
      page: 1,
      count: null,
      pageSize: 8,
      userNickName: this.$route.params.nickname,
      flag: false,
      isOwner: false
    }
  },
  computed: {
    ...mapGetters({
      getProducts: 'profile/getProducts',
      getSongs: 'products/getSongs',
      getPlayList: 'products/getPlayLists'
    }),
    getTab() {
      return this.$route.query.tab
    },
    isSongs() {
      return this.getSongs.length
    }
  },
  methods: {
    ...mapActions({
      getSongsRequest: 'products/getSongsRequest',
      getAllPlayListRequest: 'products/getAllPlayListRequest'
    }),
    toggleMenuHandler() {

    },
    onPagination() {
      this.$router.push(this.linkGen(this.page)).catch(() => {
      })
    },
    linkGen(pageNum) {
      let userNick = this.$route.params.nickname
      const uuid = this.$route.query.userUuid
      return {
        path: `/profile/${userNick}/songs`,
        query: {tab: 'playlist', page: pageNum, userUuid: uuid}
      }
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }

      return params;
    },
    async playListRequest() {
      const uuid = this.$route.query.userUuid
      const payload = {
        uuid: uuid,
        params: this.getRequestParams(this.page, this.pageSize)
      }
      let res = await this.getAllPlayListRequest(payload)
      this.count = res.totalPages
    },
    closePopupAfterUpload() {
      this.flag = false
      setTimeout(() => {
        this.flag = true
      }, 100)
    },
    openMenu() {

    }
  },
  watch: {
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.page = +newVal
          this.playListRequest()
          if (this.count < newVal) {
            this.page = 1
          }
        } else {
          this.page = 1
        }
      }
    }
  },
  mounted() {
    this.page = +this.$route.query.page
    const uuid = this.$route.query.userUuid
    const user = JSON.parse(localStorage.getItem('user'))
    document.title = 'My Songs'
    this.getSongsRequest(uuid)
    this.flag = true
    this.playListRequest()
    this.isOwner = this.$route.params.nickname === user?.nickName
    this.$refs.top.scrollIntoView({behavior: "smooth"})
  }
}
</script>

<style lang="scss" scoped>
.song-wrapper {
  position: relative;
}

.song-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.song-header .btn-upload {
  padding: 13px 40px;
  height: 48px;
}

.btn-upload img, .btn-upload svg {
  margin-right: 15px;
}

.song-search {
  position: relative;
  width: 100%;
  border: 1px solid #D9D9D9;
  border-radius: 12px;
  margin-top: 40px;
}

.song-search input {
  width: 100%;
  padding: 13px 30px;
  color: var(--primary-blue);
}

.search-icon {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.song-content {
  margin-top: 40px;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  padding: 20px;
  background: #FFFFFF;
}

.content-tabs {
  display: flex;
}

.content {
  margin-top: 40px;
}

.tab-item {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  border-radius: 10px;
  cursor: pointer;
}

.tab-item svg {
  margin-right: 12px;
}

.playlist-tab-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.song-tab-wrapper {
  max-height: 400px;
  overflow-y: auto;
}

@media only screen and (max-width: 600px) {
  .playlist-tab-wrapper {
    justify-content: space-between;
  }

  .song-header .btn-upload {
    padding: 13px 20px;
  }

  .song-header {
    display: none;
  }

  .song-search {
    width: calc(100% - 30px);
    margin: 20px auto;
  }

  .song-content {
    margin-top: 20px;
    padding: 20px 15px 0;
  }
  .content {
    margin-top: 0;
  }
}
</style>