<template>
    <div class="login-wrapper">
      <h1 class="login-title">Sign In</h1>
      <p class="login-sign-up">Don't have an account ? <router-link to="/sign-up" class="sign-up">Sign up</router-link></p>
      <form class="profile-form-edit w-full" @submit.prevent="onSubmitAction">
        <div class="login-form__email">
          <input type="text" name="email" id="login-email" class="input login-email" placeholder="Email" v-model="email">
        </div>
        <div class="login-form__password">
          <input :type="togglePassword ? 'text' : 'password'" name="password" id="login-password" class="input login-password" placeholder="Password" v-model="password">
          <div class="toggle-password" @click="togglePassword = !togglePassword">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1426_116035)">
                <path d="M1.6665 10.4166C1.6665 10.4166 5.99336 4.82958 10.4165 4.99997C14.4815 5.15657 19.1666 9.99998 19.1666 9.99998C19.1666 9.99998 15.8332 15.4166 9.99984 15.4166C4.1665 15.4166 1.6665 10.4166 1.6665 10.4166Z" stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.4167 12.0833C11.7974 12.0833 12.9167 10.964 12.9167 9.58333C12.9167 8.20262 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 9.03595 12.0833 10.4167 12.0833Z" stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_1426_116035">
                  <rect width="20" height="20" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="svg-cross-line" v-if="togglePassword"></div>
        </div>
        <div class="d-flex justify-space-between align-center mt-5">
          <div class="d-flex remember-checkbox">
<!--            <v-checkbox label="Remember me" color="#0057B7" v-model="rememberMeCheckbox"></v-checkbox>-->
          </div>
          <router-link :to="{name: 'reset password'}" class="forgot-password">
            <div class="forgot-password__text">Forgot password?</div>
            <div class="forgot-password__underline"></div>
          </router-link>
        </div>
        <button class="btn-yellow btn-no-hover button-sign-in" type="submit">Sign In</button>
      </form>
    </div>
</template>

<script>
import {mapActions, mapMutations} from "vuex";

export default {
  name: "LoginView",
  data() {
    return {
      email: '',
      password: '',
      togglePassword: false,
      rememberMeCheckbox: false
    }
  },
  methods: {
    ...mapActions({
      loginAction: 'authentication/login',
      getTime: 'authentication/getServerTime'
    }),
    ...mapMutations({
      setErrorMessage: 'error/SET_ERROR_MESSAGE',
      setDialog: 'error/SET_DIALOG'
    }),
    async onSubmitAction() {
      try {
        const data = {
          email: this.email,
          password: this.password,
        }
        await this.loginAction(data)
      } catch (e) {
        this.setErrorMessage(e.response.data.errors[0].message)
        this.setDialog(true)
      }

    }
  },
  mounted() {
    document.title = 'Sign In'
    this.getTime()
  }
}
</script>

<style scoped>
.login-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 150px 40px 0;
}

.login-title {
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 75px;
  color: var(--primary-blue);
  margin: 0;
}

.login-sign-up {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #000000;
  margin-top: 40px;
}

.login-sign-up .sign-up {
  color: var(--primary-blue);
}

.profile-form-edit {
  margin-top: 40px;
}

input {
  width: 100%;
  padding: 15px 20px;
}

.login-form__email {
  margin-bottom: 25px;
}

.button-sign-in {
  width: 100%;
  margin-top: 20px;
  justify-content: center;
}

.login-form__password {
  position: relative;
}

.toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  line-height: 0;
  cursor: pointer;
}

.svg-cross-line {
  position: absolute;
  top: 15px;
  right: 18px;
  height: 21px;
  width: 2px;
  background: #0057B7;
  transform: rotate(56deg);
}

.remember-checkbox {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #0057B7;
}

.forgot-password {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #006EE9;
  cursor: pointer;
}

.forgot-password__text {
  margin-bottom: 5px;
}

.forgot-password__underline {
  width: 50px;
  height: 2px;
  background: #FFD60A;
}

@media only screen and (max-width: 600px) {
  .login-wrapper {
    padding: 90px 15px 0;
  }
}
</style>