<template>
  <div data-app style="z-index: 100000">
    <v-dialog v-model="dialog">
      <div class="create-content-wrapper" style="max-height: calc(100vh - 100px); overflow-y: auto">
        <div class="content-header">
          <button class="ml-auto" @click="closePopup">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 5.5C11.4627 8.31416 9.11674 10.9681 5 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 5.5C6.86016 8.21022 8.65254 10.1669 15 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        <CreatePostTemplate ref="CreatePostTemplate" @closePopup="closePopup" :disablePreloader="true" @startUpload="uploadFlag = true" @serverError="uploadFlag = false"/>
        <div class="preloader-wrap d-flex justify-center align-center" v-if="uploadFlag">
          <PreLoader/>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import CreatePostTemplate from "@/components/post/CreatePostTemplate";
import PreLoader from "@/components/elements/Preloader.vue";

export default {
  name: "CreatePostPopup",
  components: {PreLoader, CreatePostTemplate},
  data() {
    return {
      dialog: false,
      uploadFlag: false
    }
  },
  methods: {
    openPopup() {
      this.dialog = true
    },
    closePopup() {
      this.uploadFlag = false
      this.$refs.CreatePostTemplate.fullReset()
      this.dialog = false
    },
  },
}
</script>

<style scoped>
.create-content-wrapper {
  position: relative;
  max-width: 846px;
  width: 100%;
  background: #FFFFFF;
  margin: 0 auto;
  padding: 40px;
  border-radius: 10px;
  max-height: 90%;
  overflow-y: auto;
}

.content-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .create-content-wrapper {
    padding: 10px;
  }
}
</style>