<template>
  <div data-app>
    <v-dialog
        v-model="dialog"
        max-width="846"
        persistent
    >
      <div class="edit-price-popup-wrapper" style="max-height: 90%; overflow-y: auto">
        <div class="edit-price-header">
          <h1 class="edit-price-header__title">Edit price</h1>
          <button @click="closePopup">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 5.5C11.4627 8.31416 9.11674 10.9681 5 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 5.5C6.86016 8.21022 8.65254 10.1669 15 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        <form class="edit-price-email-wrapper mt-5" @submit.prevent="submitAction">
          <div class="price-for-subscribe" style="position:relative;">
            <p class="input-title">Price for subscribtion</p>
            <input type="number" name="" id="price-for-subscribe" class="input w-full mt-4" placeholder="₴ x.xx" v-model="subscribePrice.price">
            <span class="error" v-for="error of v$.subscribePrice.price.$errors" :key="error.$uid">
              {{ error.$message }}
            </span>
          </div>
          <div class="separator my-5"></div>
          <div class="price-for-video" style="position:relative;">
            <p class="input-title">Price for video congratulation</p>
            <input type="number" min="1" name="" id="price-for-video" class="input w-full mt-4" placeholder="₴ x.xx" v-model="videoCongratulationPrice.price">
            <span class="error" v-for="error of v$.videoCongratulationPrice.price.$errors" :key="error.$uid">
              {{ error.$message }}
            </span>
          </div>
          <div class="your-rules mt-5" style="position:relative;">
            <p class="input-title">Your rules</p>
            <textarea name="" id="edit-price-rules" rows="3" class="input w-full mt-4"
                      v-model="videoCongratulationPrice.rule" placeholder="Text"></textarea>
            <span class="error" v-if="errorMessage">{{ errorMessage }}</span>

          </div>
          <button class="btn-yellow w-full mt-5" v-if="!isSent" type="submit">Save</button>
          <div class="btn-yellow disabled btn-no-hover w-full mt-5" v-else>Save</div>
        </form>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {useVuelidate} from '@vuelidate/core'
import {minValue, required} from '@vuelidate/validators'

export default {
  name: "EditPricePopup",
  components: {},
  setup() {
    return {v$: useVuelidate({$lazy: true, $autoDirty: false})}
  },
  data() {
    return {
      dialog: false,
      subscribePrice: {
        price: null
      },
      videoCongratulationPrice: {
        price: null,
        rule: null
      },
      priceForSubscribe: null,
      priceForVideo: null,
      rules: '',
      isSent: false,
      errorMessage: '',

    }
  },
  validations() {
    return {
      subscribePrice: {
        price: {
          minValue: minValue(1),
          required: required
        }
      },
      videoCongratulationPrice: {
        price: {
          minValue: minValue(1),
          required: required
        }
      }
    }
  },
  methods: {
    ...mapActions({
      getServicePrices: 'prices/getServicePricesAction',
      changeServicePrices: 'prices/changeServicePrices'
    }),
    openPopup() {
      this.dialog = true
      this.isSent = false
    },
    closePopup() {
      this.dialog = false
    },
    async getServicePricesRequest() {
      const uuid = this.$route.query.userUuid
      let prices = await this.getServicePrices(uuid)
      this.subscribePrice.price = prices.subscribePrice.price
      this.videoCongratulationPrice.price = prices.videoCongratulationPrice.price
      this.videoCongratulationPrice.rule = prices.videoCongratulationPrice.rule
    },
    async submitAction() {
      const isFormCorrect = await this.v$.$validate()
      if(!isFormCorrect) {
        return false
      } else {
        let uuid = this.$route.query.userUuid
        let body = {
          subscribePrice: this.subscribePrice,
          videoCongratulationPrice: this.videoCongratulationPrice
        }

        let payload = {
          userUuid: uuid,
          body
        }
        try {
          this.errorMessage = ''
          await this.changeServicePrices(payload)
          this.isSent = true
          this.closePopup()
        } catch (error) {
          this.errorMessage = error.response.data.errors[0].message
        }
      }


    },
    setToFormData(event) {
      this.formData.set(event.target.name, event.target.value)
    }
  },
  watch: {
    'videoCongratulationPrice.rule'(newVal) {
      if(newVal === '') {
        this.videoCongratulationPrice.rule = null
      }
    }
  },
  created() {
    this.getServicePricesRequest()
  }
}
</script>

<style scoped>
.edit-price-popup-wrapper {
  position: relative;
  max-width: 846px;
  width: 100%;
  background: #FFFFFF;
  padding: 40px;
  margin: 0 auto;
  border-radius: 10px;
}

.edit-price-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.edit-price-header__title {
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 33px;
  color: var(--primary-blue);
}


.error {
  position: absolute;
  bottom: -20px;
  left: 0;
}

@media only screen and (max-width: 600px) {
  .edit-price-popup-wrapper {
    padding: 20px 15px;
  }
}
</style>