<template>
  <div class="button-wrap">
    <button class="btn-blue button-element br-10" @mouseover="isHover = true" @mouseout="isHover = false" @click.prevent="clickEvent">
      <slot></slot>
    </button>
  </div>
</template>

<script>
export default {
  name: "ButtonBlue",
  data() {
    return {
      isHover: false
    }
  },
  methods: {
    clickEvent() {
      this.$emit('clickEvent')
    }
  }
}
</script>

<style scoped>
.button-wrap {
  position: relative;
  cursor: pointer;
}

.button-element {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
}

@media only screen and (max-width: 600px) {
  .button-element {
    font-size: 14px;
  }
}
</style>