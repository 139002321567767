<template>
  <div>
    <div class="mobile-header mb-10">
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"
           @click="$router.back()">
        <rect width="30" height="30" rx="15" fill="#0057B7"/>
        <path d="M13.413 9L9.5 15M9.5 15L13.413 21M9.5 15L21.5 15" stroke="#FFD60A" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <div class="playlist-wrapper" ref="top">
      <ButtonBack/>
      <div class="playlist-header">
        <div class="header-cover">
          <img loading="lazy" src="../../assets/img/legends-playlist-cover.png" alt="Ukrmusic" class="playlist-cover">
        </div>
        <div class="header-content">

          <h2 class="playlist-name">The Legends Playlist</h2>
          <div class="d-flex align-center">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
              <path
                  d="M6.5356 13.9745C6.5356 14.0208 6.06962 16.9115 5.21813 16.9905C3.66596 17.1346 0.78191 15.6122 1.01308 14.1496C1.21255 12.8876 2.73862 12.4408 4.04225 12.4408C5.10909 12.5218 5.724 13.3187 6.5356 13.9745ZM6.5356 13.9745C7.04075 11.914 6.86762 12.6707 7.22438 10.5298C7.53484 8.6666 7.46844 6.79767 7.77391 4.93798C7.84099 4.52964 7.68525 3.91758 8.21709 3.74555C9.6104 3.29488 10.9223 2.68309 12.3297 2.24207C13.7102 1.80951 15.2452 1.48691 16.5665 1.00521C16.9795 0.854625 16.7969 4.01097 16.7969 5.1972C16.7969 7.36972 16.8576 9.52765 16.9796 11.6961M16.9796 11.6961C17.0186 12.39 17.0106 12.5608 16.8767 13.085C16.3957 14.9683 13.949 15.8692 12.959 15.692C11.6507 15.4577 10.4897 14.5244 10.6102 13.3442C10.7648 11.831 11.998 11.2544 13.739 11.1297C14.7189 11.0595 16.9405 11.0022 16.9796 11.6961Z"
                  stroke="#0057B7" stroke-width="2" stroke-linecap="round"/>
            </svg>
            <span class="playlist-songs-amount">{{ songList.length }}</span>
          </div>
        </div>
      </div>
      <div class="search-input mt-5 mt-sm-10">
        <input type="search" name="search" id="hall-of-fame-search" class="input" placeholder="Search"
               v-model="searchText">
        <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M11.9167 21.176C16.7031 21.176 20.5833 16.2125 20.5833 11.426C20.5833 6.63952 16.7031 2.75932 11.9167 2.75932C7.1302 2.75932 3.25 7.72285 3.25 12.5093C3.25 17.2958 7.1302 21.176 11.9167 21.176Z"
              stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M22.7496 23.3426L18.0371 18.6301" stroke="#0057B7" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>

      </div>
      <div class="playlist-songs-list-wrapper mt-5 mt-sm-10">
        <div v-if="songList && songList.length">
          <SongItemTemplateComponent
              v-for="(song, index) in songList"
              :key="song.songName"
              :song="song"
              :identifier="'song-component'"
              :index="index"
              :showDuration="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonBack from "@/components/elements/ButtonBack";
import {mapActions} from "vuex";
import SongItemTemplateComponent from "@/components/SongItemTemplateComponent";

export default {
  name: "HallOfFamePlaylistPage",
  components: {SongItemTemplateComponent, ButtonBack},
  data() {
    return {
      playlist: null,
      songList: [],
      searchText: ''
    }
  },
  methods: {
    ...mapActions({
      getLegendPlaylist: 'hallOfFame/getLegendPlaylist'
    }),
    async getPlaylistRequest() {
      const res = await this.getLegendPlaylist()
      this.songList = res.elements
    }
  },
  created() {
    this.getPlaylistRequest()
  }
}
</script>

<style scoped>
.playlist-wrapper {
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  padding: 40px;
}

.header-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.playlist-name {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  color: #0057B7;
  margin-bottom: 20px;
}

.playlist-header {
  display: flex;
}

.playlist-cover {
  width: 128px;
  height: 128px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 40px;
}

.playlist-songs-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #0057B7;
}

.search-input {
  position: relative;
}

.search-input input {
  padding: 15px;
  width: 100%;
}

.search-input svg {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.playlist-songs-list-wrapper {
  max-height: 475px;
  overflow-y: auto;
}
</style>