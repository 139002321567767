<template>
  <div class="not-found-wrapper">
    <h1>Page not found</h1>
  </div>
</template>

<script>
export default {
  name: "NotFoundComponent"
}
</script>

<style scoped>
.not-found-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 150px 40px 0;
}
</style>