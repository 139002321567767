import instance from "../api/instance";


export const products = {
    namespaced: true,
    state: {
        songs: [],
        playLists: null,
        events: null,
        event: null
    },
    getters: {
        getSongs(state) {
            return state.songs
        },
        getPlayLists(state) {
            return state.playLists
        },
        getEvents(state) {
            return state.events
        },
        getEvent(state) {
            return state.event
        }
    },
    mutations: {
        SET_SONGS(state, payload) {
            state.songs = payload
        },
        SET_PLAYLISTS(state, payload) {
            state.playLists = payload
        },
        SET_EVENT_LIST(state, payload) {
            state.events = payload
        },
        SET_EVENT(state, payload) {
            state.event = payload
        }
    },
    actions: {
        async getSongsRequest(context, ownerUuid) {
            const songs = (await instance.get(`/api/v1/products/${ownerUuid}/songs`)).data.body.elements
            context.commit('SET_SONGS', songs)
            return songs
        },
        async getAllPlayListRequest(context, payload) {
            let params = payload.params
            let uuid = payload.uuid
            const playlists = (await instance.get(`/api/v1/products/${uuid}/albums`, {params})).data.body
            context.commit('SET_PLAYLISTS', playlists.elements)
            return playlists
        },
        async getOnePlayListRequest(context, payload) {
            let playlistId = payload.playlistId
            let uuid = payload.uuid
            const playlist = (await instance.get(`/api/v1/products/${uuid}/albums/${playlistId}`)).data.body
            return playlist
        },
        async createPlayList(context, payload) {
            let body = payload.body
            let uuid = payload.uuid
            await instance.post(`/api/v1/products/${uuid}/albums`, body)
        },
        async getEventListRequest(context, payload) {
            let params = payload.params
            let uuid = payload.uuid
            const events = (await instance.get(`/api/v1/products/${uuid}/events`, {params})).data.body
            context.commit('SET_EVENT_LIST', events.elements)
            return events
        },
        async createEvent(context, payload) {
            let uuid = payload.uuid
            let body = payload.body
            await instance.post(`/api/v1/products/${uuid}/events`, body)
        },
        async getEventRequest(context, payload) {
            let eventId = payload.eventId
            let uuid = payload.uuid
            const event = (await instance.get(`/api/v1/products/${uuid}/events/${eventId}`)).data.body
            context.commit('SET_EVENT', event)
        },

    }
}