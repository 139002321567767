<template>
  <div data-app style="z-index: 100000">
    <v-dialog v-model="dialog" persistent>
      <div class="sign-in-popup-wrapper" style="max-height: calc(100vh - 100px); overflow-y: auto">
        <div class="content-header">
          <button class="ml-auto" @click="closePopup">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 5.5C11.4627 8.31416 9.11674 10.9681 5 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 5.5C6.86016 8.21022 8.65254 10.1669 15 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        <div class="content-body">
          <img src="../../assets/img/sign-in-img.png">
          <div class="body-title">
            <h2>Sign in or create account to view content</h2>
            <button class="btn-yellow" @click="buttonAction">Sign in</button>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "SignInPopup",
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      dialog: 'authentication/getSignInPopupDialog'
    }),
  },
  methods: {
    ...mapMutations({
      closePopup: 'authentication/closeSignInPopup',
      openPopup: 'authentication/openSignInPopup'
    }),
    buttonAction() {
      this.closePopup()
      this.$router.push({path: '/sign-in'})
    }
  },
  watch: {
    '$route.path': {
      deep: true,
      handler(newVal) {
        if(newVal) {
          this.closePopup()
        }
      }
    }
  }
}
</script>

<style scoped>
.sign-in-popup-wrapper {
  position: relative;
  max-width: 1250px;
  width: 100%;
  background: #FFFFFF;
  padding: 80px;
  margin: 0 auto;
  border-radius: 10px;
}

.content-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.content-body {
  display: flex;
}

.body-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.body-title h2 {
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 70px;
  color: #0057B7;
  margin-bottom: 50px;
}

img {
  margin-right: 80px;
}

@media only screen and (max-width: 600px) {
  .sign-in-popup-wrapper {
    padding: 15px;
  }

  .content-body {
    flex-direction: column;
    align-items: center;
  }

  img {
    margin-right: 0;
    width: 165px;
  }

  .body-title h2 {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
</style>