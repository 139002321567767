<template>
  <div class="profile-events-wrapper">
    <div class="mobile-header mb-8">
      <div class="d-flex align-center justify-space-between w-full">
        <h2 class="page-title">Events</h2>
        <button class="btn-yellow btn-create-event" @click="$refs.createContentPopup.openPopup()" v-if="isOwner">
          <img src="../assets/svg/icons/plus.svg" alt="Ukrmusic">
          <span>Create event</span>
        </button>
      </div>
    </div>
    <ProfileNavBar :userUuid="$route.query.userUuid"/>
    <div class="events-header desktop">
      <h1 class="page-title">Events</h1>
      <button class="btn-yellow btn-create-event" @click="$refs.createContentPopup.openPopup()" v-if="isOwner">
        <img src="../assets/svg/icons/plus.svg" alt="Ukrmusic">
        <span>Create event</span>
      </button>
    </div>
    <div class="event-content" v-if="getEvents && getEvents.length">
      <EventItemComponent
          v-for="(event, index) in getEvents"
          :key="index"
          :event="event"
      />
    </div>
    <div class="event-content" v-else>
      <h1 class="content-v-else mt-0">{{ `${isOwner ? "You don't" : "Artist doesn't"} have any events` }}</h1>
    </div>
    <v-pagination
        v-if="getEvents && getEvents.length"
        v-model="page"
        :length="count"
        :total-visible="7"
        :color="'#0057B7'"
        prev-icon="<"
        next-icon=">"
        @input="onPagination"
        class="pagination"
    ></v-pagination>
    <CreateContentPopup ref="createContentPopup" @updateEvents="eventRequest"/>
    <ButtonBack v-if="!isOwner"/>
  </div>
</template>

<script>
import ProfileNavBar from "@/components/profile/ProfileNavBar";
import EventItemComponent from "@/components/EventItemComponent";
import {mapGetters, mapActions} from "vuex";
import CreateContentPopup from "@/components/popups/CreateContentPopup";
import user from "@/mixins/user";
import ButtonBack from "@/components/elements/ButtonBack";

export default {
  name: "ProfileEventsView",
  mixins: [user],
  components: {ButtonBack, CreateContentPopup, EventItemComponent, ProfileNavBar},
  data() {
    return {
      page: 1,
      count: null,
      pageSize: 6,
      isOwner: false
    }
  },
  computed: {
    ...mapGetters({
      getEvents: 'products/getEvents'
    }),
  },
  methods: {
    ...mapActions({
      getEventsRequest: 'products/getEventListRequest'
    }),
    async eventRequest() {
      const uuid = this.$route.query.userUuid
      const payload = {
        uuid: uuid,
        params: this.getRequestParams(this.page, this.pageSize)
      }
      let res = await this.getEventsRequest(payload)
      this.count = res.totalPages
    },
    onPagination() {
      this.$router.push(this.linkGen(this.page)).catch(() => {
      })
    },
    linkGen(pageNum) {
      let userNick = this.$route.params.nickname
      const uuid = this.$route.query.userUuid
      return {
        path: `/profile/${userNick}/events`,
        query: {page: pageNum, userUuid: uuid}
      }
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }

      return params;
    },
  },
  watch: {
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.page = +newVal
          this.eventRequest()
          if (this.count < newVal) {
            this.page = 1
          }
        } else {
          this.page = 1
        }
      }
    }
  },
  mounted() {
    document.title = 'Events'
    let isLogged = JSON.parse(localStorage.getItem('isLogged'))
    if(isLogged) {
      this.isOwner = this.isOwnerHandler()
    } else {
      this.isOwner = false
    }
    this.page = +this.$route.query.page
    this.eventRequest()
  }
}
</script>

<style scoped>
.profile-events-wrapper {
  position: relative;
}

.events-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.events-header .btn-create-event {
  padding: 13px 40px;
  height: 48px;
}

.btn-create-event img {
  margin-right: 15px;
}

.event-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  padding: 20px;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  margin-top: 40px;
  background: #FFFFFF;
}

.pagination {
  margin-top: 50px;
  margin-bottom: 40px;
}

@media only screen and (max-width: 600px) {
  .desktop {
    display: none;
  }
}
</style>