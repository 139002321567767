<template>
  <div data-app>
    <v-dialog
        v-model="dialog"
        max-width="846"
        persistent
    >
      <div class="add-subscriber-popup-wrapper" style="max-height: 90%; overflow-y: auto">
        <div class="add-subscriber-header">
          <h1 class="add-subscriber-header__title">Add subscriber</h1>
          <button @click="closePopup">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 5.5C11.4627 8.31416 9.11674 10.9681 5 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 5.5C6.86016 8.21022 8.65254 10.1669 15 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        <form v-if="!isSent" class="add-subscriber-email-wrapper mt-5" @submit.prevent="submitAction">
          <p class="email-title">Email</p>
          <input class="input w-full mt-4" type="email" name="email" id="add-subscriber-email" placeholder="Email"
                 v-model="email">
          <span class="error" v-if="errorMessage">{{ errorMessage }}</span>
          <button class="btn-yellow w-full mt-5" v-if="email" type="submit">Send</button>
          <div class="btn-yellow disabled btn-no-hover w-full mt-5" v-else>Send</div>
        </form>
        <div v-else>
          <p>Invite sended. You will get notification when user accept propose</p>
          <button class="btn-yellow mt-5 w-full" @click="closePopup">Done</button>
        </div>
      </div>

    </v-dialog>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "AddSubscriberPopup",
  components: {},
  data() {
    return {
      dialog: false,
      email: '',
      formData: new FormData(),
      isSent: false,
      errorMessage: ''
    }
  },
  methods: {
    ...mapActions({
      addSubscriber: 'subscribers/addSubscribersAction'
    }),
    openPopup() {
      this.dialog = true
    },
    closePopup() {
      this.dialog = false
      this.resetForm()
    },
    async submitAction() {
      let uuid = this.$route.query.userUuid
      this.formData.set('email', this.email)
      let payload = {
        uuid,
        body: this.formData
      }
      try {
        this.errorMessage = ''
        await this.addSubscriber(payload)
        this.isSent = true
        this.email = ''
      } catch (error) {
        this.errorMessage = error.response.data.errors[0].message
      }

    },
    resetForm() {
      this.errorMessage = ''
      this.formData = new FormData()
      this.email = ''
    }
  }
}
</script>

<style scoped>
.add-subscriber-popup-wrapper {
  position: relative;
  max-width: 846px;
  width: 100%;
  background: #FFFFFF;
  padding: 40px;
  margin: 0 auto;
  border-radius: 10px;
}

.add-subscriber-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.add-subscriber-header__title {
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 33px;
  color: var(--primary-blue);
}

.add-subscriber__make-post {
  border: 1px solid #D9D9D9;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.email-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: var(--primary-blue);
}
</style>