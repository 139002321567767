<template>
  <div data-app>
    <v-dialog
        v-model="dialog"
        max-width="290"
    >
      <div class="awdawdw">
        <h1>Hello World</h1>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "TestPopup",
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    openPopup() {
      this.dialog = true
    }
  }
}
</script>

<style scoped>

</style>