<template>
  <div class="content-playlist-wrapper">
    <div class="content-playlist">
        <PlayListItemComponent
            v-for="playList in secondaryPlaylistSliced"
            :key="playList.id"
            :playList="playList"
            :forPage="'profile'"
            :userUuid="userUuid"
        />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PlayListItemComponent from "@/components/PlayListItemComponent";

export default {
  name: "ProfileContentPlaylistComponent",
  components: {PlayListItemComponent},
  props: {
    playLists: Array,
    userUuid: {
      type: String,
      required: false
    },
    isOwner: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      current: 'song/getCurrent'
    }),
    secondaryPlaylistSliced() {
      return this.playLists.slice(0, 4)
    }
  },
  watch: {
    current(newVal) {
      if (newVal !== this.song.songName) {
        this.$refs[`audio-player-${this.identifier}-${this.index}`].refreshSong()
      }
    }
  },
}
</script>

<style scoped>
.add-playlist p {
  font-weight: 800;
  color: var(--primary-blue);
}

.content-playlist-wrapper {
  display: flex;
  margin-top: 40px;
  width: 100%;
}

.content-playlist {
  display: flex;
  width: 100%;
}

.header-img img, .secondary-playlist-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.playlist-total-songs-and-likes img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

@media only screen and (max-width: 600px) {
  .content-playlist {
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
</style>