<template>
  <div class="artist-item" @click="routeTo(item.nickName)">
    <div>
      <img loading="lazy" :src="item.avatar" alt="Ukrmusic" v-if="item && item.avatar"
           class="artist-item-image mr-5" @click="routeTo(item.nickName)">
      <img src="../assets/img/default-avatar.png" alt="Ukrmusic" class="artist-item-image mr-5" v-else
      >
    </div>
    <div class="artist-name">
      <div class="name">{{ item.publicName }}</div>
      <div class="nick-name">{{ item.nickName }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchPageArtistCardComponent",
  props: {
    item: {
      type: Object,
      default: () => {
      },
      required: true
    }
  },
  methods: {
    routeTo(nickName) {
      this.$router.push({path: `/profile/${nickName}`})
    }
  }
}
</script>

<style scoped>
.content-artist {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
}

.artist-item {
  padding: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  overflow: hidden;
  word-break: break-all;
}

.artist-item:hover {
  background: rgba(217, 217, 217, 0.5);
}

.artist-item-image {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

.artist-name {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-self: stretch;
}

.artist-name .name {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #0057B7;
}

.artist-name .nick-name {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #0057B7;
}
</style>