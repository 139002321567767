<template>
  <div className="flex justify-center mx-auto w-7 h-7">
    <img src="../../assets/svg/navbar/messages.svg" v-if="$route.path !== '/chat'"/>
    <img src="../../assets/svg/navbar/messages-active.svg" v-else/>
  </div>
</template>

<script>
export default {
  name: 'NavMessagesIcon',
  data() {
    return {

    }
  },
}
</script>

<style scoped>

</style>