<template>
  <div data-app style="z-index: 100000">
    <v-dialog v-model="dialog">
      <div class="create-content-wrapper" style="max-height: calc(100vh - 100px); overflow-y: auto">
        <div class="content-header">
          <button class="ml-auto" @click="closePopup">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 5.5C11.4627 8.31416 9.11674 10.9681 5 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 5.5C6.86016 8.21022 8.65254 10.1669 15 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        <ChatTemplate :opponent="opponent" :messages="messages" :isChatActive="chatStatus" ref="ChatTemplate"/>
        <div ref="bottom"></div>
      </div>
    </v-dialog>
  </div>
</template>

<script>

import ChatTemplate from "@/components/chat/ChatTemplate";
import {mapActions} from "vuex";
export default {
  name: "ChatPopup",
  components: {ChatTemplate},
  props: {
    opponent: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      dialog: false,
      messages: [],
      interval: null,
      chatStatus: null
    }
  },
  computed: {},
  methods: {
    ...mapActions({
      getOpponent: 'chat/getOpponent',
      getChatStatus: 'chat/getChatStatus'
    }),
    openPopup() {
      this.interval = setInterval(() => {
        this.getMessages()
      }, 500)
      this.getChatStatusRequest()
      this.dialog = true
      setTimeout(() => {
        this.$refs.bottom.scrollIntoView({behavior: "smooth"})
      }, 100)
    },
    closePopup() {
      clearInterval(this.interval)
      this.dialog = false
    },
    async getMessages() {
      this.messages = await this.getOpponent(this.opponent.uuid)
    },
    async getChatStatusRequest() {
      const params = {
        opponentUserUuid: this.opponent.uuid
      }
      this.chatStatus = await this.getChatStatus(params)
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.create-content-wrapper {
  position: relative;
  max-width: 1000px;
  width: 100%;
  background: #FFFFFF;
  padding: 40px;
  margin: 0 auto;
  border-radius: 10px;
}

.content-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .create-content-wrapper {
    padding: 0 10px;
  }
}
</style>