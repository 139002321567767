import instance from "../api/instance";


export const video = {
    namespaced: true,
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        async getVideoOrdersAction(context, params) {
            return (await instance.get(`/api/v1/video-congratulations`, {params})).data.body.elements

        },
        async uploadVideoCongratulationsAction(context, payload) {
            const offerId = payload.offerId
            const body = payload.body
            await instance.post(`/api/v1/video-congratulations/${offerId}`, body)
        },
        async declineVideoCongratulationsAction(context, offerId) {
            await instance.delete(`/api/v1/video-congratulations/${offerId}`)
        },
    }
}