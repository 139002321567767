<template>
  <div class="profile-wrapper" v-if="user" ref="top">
    <div class="mobile-header mb-2">
      <h2 class="page-title">Profile</h2>
    </div>
    <ProfileNavBar :userUuid="user.uuid" v-if="getConditions"/>
    <ProfileHeadComponent :isOwner="isOwner" :isJudge="isJudge" @orderVideo="$refs.OrderVideoCongratulationPopup.openPopup()"
                          @subscribed="onSubscribeHandler" :subscribePrice="user.soldServices.subscribePrice.price"
                          ref="ProfileHead" @openChat="$refs.ChatPopup.openPopup()"
    />
    <CreatePostTemplate v-if="isOwner && isArtist" :inputForId="'profile-page'"/>
    <ProfileContentComponent :userUuid="user.uuid" :isOwner="isOwner" v-if="getConditions"/>
    <div class="profile-wall-wrapper" v-if="getConditions">
      <div class="profile-wall-header d-flex justify-space-between align-center flex-wrap">
        <ul class="tab-wrapper d-flex justify-space-between w-full">
          <li :style="{background: tab === 'recommendations' ? 'linear-gradient(90.41deg, rgba(0, 110, 233, 0.6) -2.47%, rgba(30, 150, 252, 0.36) 103.59%)' : '', color: tab === 'recommendations' ? '#FFFFFF' : '#0057B7'}"
              @click="switchTab('recommendations')">Recommendations
          </li>
          <li :style="{background: tab === 'premium' ? 'linear-gradient(90.41deg, rgba(0, 110, 233, 0.6) -2.47%, rgba(30, 150, 252, 0.36) 103.59%)' : '', color: tab === 'premium' ? '#FFFFFF' : '#0057B7'}"
              @click="switchTab('premium')">Premium subscription
          </li>
        </ul>
      </div>

    </div>
    <div class="show-post-else" v-if="tab === 'premium' && !postList.length">{{ isOwner ? `In order to enable premium subscription function you have to create at least 5 posts.` : `In order to enable the premium subscription feature you need to subscribe to the artist` }}</div>
    <vue-loadmore
        :on-refresh="onRefresh"
        :on-loadmore="onLoad"
        :finished="finished"
        :loading-text="'load'"
        :refresh-text="' '"
        :finished-text="' '"
        :pulling-text="' '"
        :loosing-text="' '"
        :show-success-text="false"
        :load-offset="500"
        ref="vueLoadMore"
        v-if="getConditions"
    >
      <ShowPostTemplate v-for="(post, index) in postList" :key="index" :post="post" :index="index"
                        ref="ShowPostTemplate"/>
    </vue-loadmore>
    <OrderVideoCongratulationPopup ref="OrderVideoCongratulationPopup"
                                   :videoPrice="user.soldServices.videoCongratulationPrice" :userUuid="user.uuid"/>
    <ChatPopup ref="ChatPopup" :opponent="user"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ProfileHeadComponent from "@/components/profile/ProfileHeadComponent";
import ProfileContentComponent from "@/components/profile/ProfileContentComponent";
import ProfileNavBar from "@/components/profile/ProfileNavBar";
import CreatePostTemplate from "@/components/post/CreatePostTemplate";
import ShowPostTemplate from "@/components/post/ShowPostTemplate";
import OrderVideoCongratulationPopup from "@/components/popups/OrderVideoCongratulationPopup.vue";
import ChatPopup from "@/components/popups/ChatPopup";


export default {
  name: "ProfileView",
  components: {
    ChatPopup,
    OrderVideoCongratulationPopup,
    ShowPostTemplate, CreatePostTemplate, ProfileNavBar, ProfileContentComponent, ProfileHeadComponent
  },
  data() {
    return {
      user: null,
      postList: [],
      page: 1,
      pageSize: 20,
      count: 1,
      finished: false,
      tab: 'recommendations',
      localStorageUser: JSON.parse(localStorage.getItem('user'))
    }
  },
  computed: {
    ...mapGetters({
      getProfile: 'profile/getProfile'
    }),
    isOwner() {
      return this.user.levelSubscription === 'OWNER'
    },
    isJudge() {
      return this.user.authorities.includes('JUDGE_HALL_OF_FAME')
    },
    postType() {
      return this.tab === 'recommendations' ? 'PUBLIC' : 'PREMIUM'
    },
    isArtist() {
      return this.localStorageUser?.authorities.includes('ARTIST')
    },
    getConditions() {
      return this.isOwner ? this.isArtist : true
    }
  },
  async beforeUpdate() {
    // const nickName = this.$route.params.nickname
    // this.user = await this.getProfileAction(nickName)
    // await this.postListRequest()
  },
  methods: {
    ...mapActions({
      getProfileAction: 'profile/getProfile',
      getProducts: 'profile/getProducts',
      getArtistPostListAction: 'content/getArtistPostListAction'
    }),
    async postListRequest() {
      let dateNow = Math.floor(Date.now() / 1000)
      const payload = {
        params: {
          params: this.getRequestParams(this.page, this.pageSize, this.postType, dateNow)
        },
        uuid: this.user.uuid
      }
      let res = await this.getArtistPostListAction(payload)
      this.count = res.totalPages
      this.postList = [...this.postList, ...res.elements]
    },
    async onSubscribeHandler() {
      const nickName = this.$route.params.nickname
      this.user = await this.getProfileAction(nickName)
    },
    switchTab(tabName) {
      this.tab = tabName
      this.onRefresh()
    },
    getRequestParams(page, pageSize, postType, initialTimeStamp) {
      let params = {};

      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }
      if (postType) {
        params["postType"] = postType
      }
      if (initialTimeStamp) {
        params["initialTimeStamp"] = initialTimeStamp
      }
      return params;
    },
    onRefresh() {
      this.postList = [];
      this.page = 0;
      this.finished = false;
      this.fetch();

      // done();
    },

    onLoad(done) {
      if (this.page < this.count) {
        this.$refs.vueLoadMore.checkSroll()
        this.fetch();
      } else {
        // all data loaded
        this.finished = true;
      }
      done();
    },

    fetch() {
      this.page++;
      this.postListRequest()
    },
    scrollToTop() {
      setTimeout(() => {
        this.$refs.top.scrollIntoView({behavior: "smooth"})
      }, 200)
    }
  },
  watch: {
    async '$route.params.nickname'(newVal) {
      if (newVal) {
        const nickName = this.$route.params.nickname
        this.user = await this.getProfileAction(nickName)
        this.onRefresh()
      }
    }
  },
  async created() {
    const nickName = this.$route.params.nickname
    this.user = await this.getProfileAction(nickName)
    await this.postListRequest()
  },
  mounted() {
    document.title = 'Profile'
    this.scrollToTop()
  }
}
</script>

<style scoped>
.profile-wall-header {
  margin-top: 30px;
  padding: 20px 40px;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px 10px 0 0;
}


.post-search input {
  width: 100%;
  padding: 13px 15px 13px 50px;
  color: var(--primary-blue);
}


.tab-wrapper li {
  width: calc(50% - 10px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 16px 0;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
}

.show-post-else {
  width: 100%;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #0057B7;
  background: #FFFFFF;
  border-radius: 0 0 10px 10px;
  border: 1px solid #D9D9D9;
}

@media only screen and (max-width: 600px) {
  .profile-wall-header {
    padding: 20px 15px;
  }

  .show-post-else {
    padding: 0 10px;
    text-align: center;
  }
}
</style>