<template>
  <div class="align-self-start">
    <div class="mobile-header mb-8">
      <div class="d-flex align-center justify-space-between w-full">
        <h2 class="page-title video-title w-full">Subscribers</h2>

        <button class="btn-yellow w-full" @click="$refs.EditPricePopup.openPopup()">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.0837 10.984C20.0837 15.9148 15.3099 20.0833 10.1937 20.0833C5.2815 20.0833 1.91699 16.1518 1.91699 11.0159C1.91699 6.08513 6.69077 1.91663 11.807 1.91663C16.7191 1.91663 20.0837 5.84809 20.0837 10.984Z" stroke="white" stroke-width="2"/>
            <path d="M13.8809 8.09072C13.7909 7.71212 13.3577 7.49351 13.0467 7.32257C12.2601 6.89023 11.4014 6.57239 10.4947 6.57239C9.40892 6.57239 8.77654 7.21716 8.34187 8.17158C8.15486 8.58221 7.92228 9.16923 8.12211 9.62702C8.41719 10.303 9.30704 10.6239 9.92957 10.8399C11.1124 11.2502 12.3256 11.3789 13.5669 11.4059C13.8994 11.4131 14.7781 11.3444 14.5178 11.936C13.9803 13.1575 12.3938 13.8327 11.1899 14.1101C10.4821 14.2733 8.40771 13.5712 7.77441 13.1748" stroke="white" stroke-width="2" stroke-linecap="round"/>
            <path d="M10.8145 4.95667V16.1151" stroke="white" stroke-width="2" stroke-linecap="round"/>
          </svg>
          <span>Edit prices</span>
        </button>
<!--        <button class="btn-yellow">-->
<!--          <span>Generate promo-code</span>-->
<!--        </button>-->

      </div>
    </div>
    <ProfileNavBar :userUuid="$route.query.userUuid"/>
    <div class="subscribers-top">
      <h1 class="page-title">Subscribers</h1>
      <div class="subscribers-top-buttons d-flex">
        <ButtonBlue class="mr-5" @clickEvent="$refs.EditPricePopup.openPopup()">
          <div class="uah-sign-wrap">₴</div>
          <span>Edit prices</span>
        </ButtonBlue>
        <button class="btn-yellow">
          <span>Generate promo-code</span>
        </button>
      </div>
    </div>
    <div class="subscribers-search w-full mt-10 px-4">
      <input type="search" id="subscribers-search" placeholder="Search" class="w-full input bg-white px-10">
      <div class="search-icon">
        <img src="../assets/svg/icons/search.svg" alt="Ukrmusic">
      </div>
    </div>
    <div class="subscribers-list-wrapper mt-10">
      <div v-if="subscriberList && subscriberList.length">
        <SubscriberItemComponent
            v-for="(sub, index) in subscriberList"
            :key="index"
            :sub="sub"
        />
      </div>
      <div class="content-v-else" v-else>
        You don't have any subscriber
      </div>
    </div>
    <AddSubscriberPopup ref="AddSubscriberPopup"/>
    <EditPricePopup ref="EditPricePopup"/>
  </div>
</template>

<script>
import ProfileNavBar from "@/components/profile/ProfileNavBar.vue";
import ButtonBlue from "@/components/elements/ButtonBlue.vue";
import {mapActions} from "vuex";
import SubscriberItemComponent from "@/components/SubscriberItemComponent.vue";
import AddSubscriberPopup from "@/components/popups/AddSubscriberPopup.vue";
import EditPricePopup from "@/components/popups/EditPricePopup.vue";

export default {
  name: "ProfileSubscribersView",
  components: {EditPricePopup, AddSubscriberPopup, SubscriberItemComponent, ButtonBlue, ProfileNavBar},
  data() {
    return {
      subscriberList: null
    }
  },
  methods: {
    ...mapActions({
      getSubsList: 'subscribers/getSubscribersAction'
    }),
    async getSubsListRequest() {
      let uuid = this.$route.query.userUuid
      this.subscriberList = await this.getSubsList(uuid)
    }
  },
  created() {
    this.getSubsListRequest()
  }
}
</script>

<style scoped>
.subscribers-search {
  position: relative;
}

.subscribers-search input {
  color: var(--primary-blue-inputs);
}

.search-icon {
  height: 24px;
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
}

.subscribers-list-wrapper {
  padding: 20px 45px 20px 20px;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  max-height: 550px;
  overflow-y: auto;
}

.uah-sign-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border: 2px solid #0057B7;
  border-radius: 50%;
  margin-right: 10px;
}

.subscribers-top {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .subscribers-list-wrapper {
    padding: 20px 15px;
  }

  .subscribers-top {
    display: none;
  }
}
</style>