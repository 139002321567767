<template>
  <v-app>
    <div :class="isChatPage ? 'chat-container-large' : 'container-large'">
      <NavBar @openCreatePostPopup="openCreatePostPopup" class="navbar"/>
      <div :class="isChatPage ? 'chat-container' : 'container'" id="container">
        <router-view/>
      </div>
      <div class="wrapper align-center">
        <a href="/terms-and-conditions" class="link">Terms and conditions</a>
        <span class="mx-2 divider">|</span>
        <a href="/privacy-policy" class="link">Privacy policy</a>
        <span class="mx-2 divider">|</span>
        <a href="mailto:nikita.dakhovskyy@djooky.com" class="link">nikita.dakhovskyy@djooky.com</a>
        <span class="mx-2 divider">|</span>
        <a href="tel:+380930001419" class="link">+380930001419</a>
      </div>
      <NavBarMobile @openCreatePostPopup="openCreatePostPopup" class="navbar-mobile"/>
    </div>
<!--    <div class="login-logout d-flex">-->
<!--      <button class="btn-yellow mr-2" @click="$router.push({name: 'login'})">Sign In</button>-->
<!--      <button class="btn-yellow" @click="logout">Sign out</button>-->
<!--    </div>-->
    <CreatePostPopup ref="CreatePostPopup"/>
    <PaymentComponent />
    <SignInPopup />
    <ErrorPopup />
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar";
import {mapActions, mapGetters, mapMutations} from "vuex";
import CreatePostPopup from "@/components/popups/CreatePostPopup";
import ErrorPopup from "@/components/popups/ErrorPopup";
import NavBarMobile from "@/components/NavBarMobile";
import SignInPopup from "@/components/popups/SignInPopup";
import PaymentComponent from "@/components/PaymentComponent";

export default {
  name: 'App',
  components: {PaymentComponent, SignInPopup, ErrorPopup, CreatePostPopup, NavBar, NavBarMobile},
  computed: {
    ...mapGetters({
      isLogged: 'authentication/isLogged',
      user: 'authentication/getUser'
    }),
    isChatPage() {
      return this.$route.name === 'chat'
    }
  },
  methods: {
    ...mapActions({
      logout: 'authentication/logout'
    }),
    ...mapMutations({
      loginSuccess: 'authentication/loginSuccess'
    }),
    openCreatePostPopup() {
      this.$refs.CreatePostPopup.openPopup()
    }
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem('user'))
    if(user) {
      this.loginSuccess(user)
    }
  }
}
</script>

<style scoped>
.wrapper {
  padding: 5px;
  background: #FFFFFF;
  border-radius: 10px;
  align-self: self-start;
  position: fixed;
  top: 97%;
  right: 0;
  display: flex;
}

.divider {
  color: #1976d2;
}

.link {
  font-size: 10px;
}

.link:hover {
  text-decoration: underline;
}

.navbar-mobile {
  display: none;
  background: #FFFFFF;
  align-self: self-start;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .navbar {
    display: none;
  }

  .navbar-mobile {
    display: block;
  }

  .wrapper {
    display: none;
  }

  .chat-container {
    padding-bottom: 35px;
  }
}
</style>