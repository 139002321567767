<template>
  <div>
    <PrivacyPolicyUkr />
  </div>
</template>

<script>
import PrivacyPolicyUkr from "@/components/static/PrivacyPolicyUkr";
export default {
  name: "PrivacyPolicyTemplate",
  components: {PrivacyPolicyUkr},
  mounted() {
    document.title = 'privacy policy'
  }
}
</script>

<style scoped>

</style>