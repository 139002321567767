<template>
  <div class="button-back" :style="{display: device === 'desktop' ? 'block' : 'none'}">
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"
         @click="$router.back()">
      <rect width="30" height="30" rx="15" fill="#0057B7"/>
      <path d="M13.413 9L9.5 15M9.5 15L13.413 21M9.5 15L21.5 15" stroke="#FFD60A" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</template>

<script>
import user from "@/mixins/user";

export default {
  name: "ButtonBack",
  mixins: [user],
  data() {
    return {
      isOwner: false,
      device: 'desktop'
    }
  },
  methods: {
    deviceJandler() {
      const width = window.innerWidth
      this.device = width <= 1200 ? 'laptop' : 'desktop'
    }
  },
  mounted() {
    let isLogged = JSON.parse(localStorage.getItem('isLogged'))
    if (isLogged) {
      this.isOwner = this.isOwnerHandler()
    } else {
      this.isOwner = false
    }
    window.addEventListener('resize', this.deviceJandler)
  }
}
</script>

<style scoped>
.button-back {
  position: absolute;
  top: 0;
  left: -60px;
  cursor: pointer;
}
</style>