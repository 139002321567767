<template>
  <li class="mt-5">
    <div class="border-wrapper" :class="{root: root}">
      <div>
        <div class="d-flex">
          <div>
            <img loading="lazy" :src="user.avatar" alt="Ukrmusic" v-if="user && user.avatar"
                 class="item-image mr-5">
            <img src="../assets/img/default-avatar.png" alt="Ukrmusic" class="item-image" v-else>
          </div>
          <div>
            <div class="d-flex">
              <div class="nickName mr-2">{{ user.nickName }}</div>
              <div class="date open-sans">{{ publishedAt }}</div>
            </div>
            <p class="comment mt-2">{{ comment }}</p>

          </div>
        </div>
      </div>
      <ul class="replies" v-if="(tailIds && tailIds.length) || showReplies">

        <div v-for="item in repliesData" :key="item.id">
          <recursive-comment
              v-bind="{
            user: item.userCommenter,
            publishedAt: item.publishedAt,
            comment: item.message,
            tailIds: item.tailIds,
            id: item.id,
            postId: item.postId,
            root: false,
            commentsFor: commentsFor
          }"
          />
        </div>

      </ul>
      <div class="footer-wrap mt-3" :class="{root: root}" v-if="root">
        <div class="show-more-button d-flex">
          <div class="connections-line"></div>
          <button class="btn-yellow mr-3" @click="showMore" v-if="tailIds.length && !checkPages">Show more</button>
          <button class="btn-blue-filled py-1 px-2" @click="openReplyInput">reply</button>

        </div>

      </div>
      <div class="connection-line-wrap" :class="{root: root, reply: isReply}" v-if="root"></div>
      <div class="connection-line-with-parent" :class="{root: root}"></div>
      <div style="margin-left: 50px; position: relative" v-show="isReply">
        <div class="reply-wrapper d-flex mt-3">
          <div>
            <img loading="lazy" :src="myAvatar" alt="Ukrmusic" v-if="myAvatar"
                 class="item-image mr-5">
            <img src="../assets/img/default-avatar.png" alt="Ukrmusic" class="item-image" v-else>

          </div>
          <textarea name="" rows="2" class="input w-full mr-2"
                    v-model="reply" placeholder="Text" :ref="`replyTextArea${id}`"></textarea>
          <p class="error" v-for="error of v$.reply.$errors" :key="error.$uid">
            {{ error.$message }}
          </p>
          <button class="send-message" @click="addComment">
            <v-icon size="25" color="#0057B7">mdi-send-circle-outline</v-icon>
          </button>
        </div>
        <div class="connections-line reply"></div>
      </div>

    </div>
  </li>
</template>

<script>
// import FormTextarea from "@/components/elements/FormTextarea";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {useVuelidate} from '@vuelidate/core'
import {maxLength} from '@vuelidate/validators'

export default {
  name: "recursive-comment",
  components: {},
  setup() {
    return {v$: useVuelidate({$lazy: true, $autoDirty: true})}
  },
  props: {
    comment: {
      type: String,
      required: true,
    },
    tailIds: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
      required: true
    },
    user: {
      type: Object,
      default: () => {
      }
    },
    root: {
      type: Boolean,
      required: true
    },
    publishedAt: {
      type: String,
      required: true
    },
    postId: {
      type: Number,
      required: true
    },
    commentsFor: {
      type: String,
      default: 'POST'
    }
  },
  data() {
    return {
      showReplies: false,
      hideViewReplies: false,
      messageId: 0,
      repliesData: [],
      isReply: false,
      reply: '',
      myAvatar: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).avatar : '',
      page: 1,
      pageSize: 2,
      count: 1,
      renderFlag: true
    }
  },
  validations() {
    return {
      reply: {
        maxLength: maxLength(50)
      },
    }
  },
  computed: {
    ...mapGetters({
      isLogged: 'authentication/isLogged'
    }),
    // repliesDataComputed: {
    //   get() {
    //     return this.repliesData
    //   },
    //   set(newVal) {
    //     this.repliesData = [...this.repliesData, ...newVal.elements]
    //   }
    // }
    checkPages() {
      return this.page > this.count
    }
  },
  methods: {
    ...mapActions({
      getCommentReplies: 'comments/getCommentsReplies',
      getLegendCommentReplies: 'hallOfFame/getLegendCommentsReplies',
      addCommentReply: 'comments/addCommentReply',
      addLegendCommentReply: 'hallOfFame/addLegendCommentsReplies'
    }),
    ...mapMutations({
      openSignInPopup: 'authentication/openSignInPopup'
    }),
    async addComment() {
      if (this.isLogged) {
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) {
          return false
        } else {
          const form = new FormData()
          form.set('comment', this.reply)
          const payload = {
            body: form,
            postId: this.commentsFor === 'POST' ? this.postId : this.$route.params.uuid,
            commentId: this.id
          }
          if (this.reply) {
            let res
            if(this.commentsFor === 'POST') {
              res = await this.addCommentReply(payload)
            } else if(this.commentsFor === 'LEGEND') {
              res = await this.addLegendCommentReply(payload)
            }
            this.repliesData.unshift(res)
            this.showReplies = true
            this.$emit('replied')
            this.reply = ''
            this.isReply = false
          }
        }
      } else {
        this.openSignInPopup()
      }


    },
    openReplyInput() {
      if (!this.isReply) {
        this.isReply = true
        this.$nextTick(() => {
          const textArea = this.$refs[`replyTextArea${this.id}`]
          textArea.focus()
        });
      } else {
        this.isReply = false
      }
    },
    async repliesRequest() {
      let dateNow = Math.floor(Date.now() / 1000)
      const payload = {
        params: {
          params: this.getRequestParams(this.page, this.pageSize, dateNow)
        },
        postId: this.commentsFor === 'POST' ? this.postId : this.$route.params.uuid,
        commentId: this.id
      }
      let res
      if(this.commentsFor === 'POST') {
        res = await this.getCommentReplies(payload)
      } else if(this.commentsFor === 'LEGEND') {
        res = await this.getLegendCommentReplies(payload)
      }
      this.count = res.totalPages
      this.showReplies = true
      this.repliesData = [...this.repliesData, ...res.elements]
    },

    getRequestParams(page, pageSize, initialTimeStamp) {
      let params = {};

      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }
      if (initialTimeStamp) {
        params["initialTimeStamp"] = initialTimeStamp
      }
      return params;
    },
    showMore() {
      this.repliesRequest()
      this.page++
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.border-wrapper {
  position: relative;
  padding-left: 40px;
}

.border-wrapper.root {
  padding: 0;
}

.item-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.nickName {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-blue);
}

.date {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: var(--primary-blue);
}

.btn-yellow {
  padding: 5px 10px;
  border-radius: 10px;
}

.show-more-button {
  position: relative;
  margin-left: 50px;
}

.connection-line-wrap {
  position: absolute;
  left: 55px;
  top: 35px;
  width: 1px;
  height: calc(100% - 56px);
  background: var(--primary-blue);
  /*border-left: 1px solid #D9D9D9;*/
  /*border-bottom: 1px solid #D9D9D9;*/
  /*border-radius: 0 0 0 10px;*/
}

.connection-line-wrap.root {
  left: 15px;
}

.connection-line-wrap.reply {
  height: calc(100% - 86px);
}

.connection-line-with-parent {
  position: absolute;
  top: 7px;
  left: 15px;
  width: 20px;
  height: 10px;
  border-bottom: 1px solid var(--primary-blue);
  border-left: 1px solid var(--primary-blue);
  border-radius: 0 0 0 10px;
}

.connection-line-with-parent.root {
  display: none;
}

.footer-wrap {
  position: relative;
}

.connections-line {
  position: absolute;
  top: 5px;
  left: -35px;
  width: 30px;
  height: 12px;
  border-bottom: 1px solid var(--primary-blue);
  border-left: 1px solid var(--primary-blue);
  border-radius: 0 0 0 10px;
}

.connections-line.reply {
  height: 45px;
  top: -30px;
}

.reply-wrapper {
  position: relative;
}

.send-message {
  position: absolute;
  bottom: 3px;
  right: 10px;
}

.error {
  position: absolute;
  bottom: -22px;
  left: 50px;
}

.comment {
  word-break: break-all;
}
</style>