<template>
  <div class="mt-5">
    <SongItemTemplateComponent
        :song="song"
        :identifier="'show-post-song'"
        :index="0"
    />
  </div>
</template>

<script>
import SongItemTemplateComponent from "@/components/SongItemTemplateComponent";

export default {
  name: "ShowPostSongComponent",
  components: {SongItemTemplateComponent},
  props: {
    song: {
      type: Object,
      required: true
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>