import instance from "../api/instance";


export const chat = {
    namespaced: true,
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        async getAllChats() {
            return (await instance.get(`/api/v1/chats`)).data.body.elements
        },
        async getOpponent(ctx, opponentUserUuid) {
            return (await instance.get(`/api/v1/chats/messages/${opponentUserUuid}`)).data.body.elements
        },
        async sendMessage(ctx, body) {
            await instance.post(`/api/v1/chats/messages`, body)
        },
        async getChatStatus(ctx, params) {
            return (await instance.get(`/api/v1/chats/status`, {params})).data.body.value
        },
    }
}