import instance from "../api/instance";


export const song = {
    namespaced: true,
    state: {
        genres: null,
        current: '',
        genresList: [
            {
                name: 'EDM/Electronic',
                value: 'EDM_ELECTRONIC'
            },
            {
                name: 'Hip Hop/R&B/Soul',
                value: 'HIP_HOP_R_AND_B_SOUL'
            },
            {
                name: 'Pop',
                value: 'POP'
            },
            {
                name: 'Rock/Metal',
                value: 'ROCK_METAL'
            },
            {
                name: 'Classical',
                value: 'CLASSICAL'
            },
            {
                name: 'Country',
                value: 'COUNTRY'
            },
            {
                name: 'Jazz/Blues',
                value: 'JAZZ_BLUES'
            },
            {
                name: 'Indie',
                value: 'INDIE'
            },
            {
                name: 'Latin',
                value: 'LATIN'
            },
            {
                name: 'Alternative',
                value: 'ALTERNATIVE'
            },
            {
                name: 'Reggae',
                value: 'REGGAE'
            },
            {
                name: 'K-pop',
                value: 'K_POP'
            },
            {
                name: 'Afrobeat',
                value: 'AFROBEAT'
            },
        ]
    },
    getters: {
        getGenres(state) {
            return state.genres
        },
        getCurrent(state) {
            return state.current
        },
        getGenresList(state) {
            return state.genresList
        }
    },
    mutations: {
        SET_CURRENT(state, payload) {
            state.current = payload

        },
        SET_GENRES(state, payload) {
            state.genres = payload
        }
    },
    actions: {
        async getGenresAction(context) {
            let genres = (await instance.get('/api/v1/genres')).data.body.elements
            context.commit('SET_GENRES', genres)
        },
        async uploadSong(context, payload) {
            let uuid = payload.uuid
            let body = payload.body
            await instance.post(`/api/v1/products/${uuid}/songs`, body)
        },
        async deleteSong(ctx, payload) {
            const uuid = payload.uuid
            const songId = payload.songId
            await instance.delete(`api/v1/products/${uuid}/songs/${songId}`)
        }
    }
}