<template>
  <div>
    <div class="mobile-header mb-2">
      <div class="d-flex align-center justify-space-between w-full">
        <h2 class="page-title">Search</h2>
      </div>
    </div>
    <div class="search-page-header">
      <h2 class="page-title">Search</h2>
    </div>
    <div class="search-page-wrapper">
      <div class="search-page__search">
        <div class="search-input">
          <input type="search" name="search" id="search" class="input" placeholder="Search"
                 v-model="searchText" @input="debounceRequest">
          <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.9167 21.176C16.7031 21.176 20.5833 16.2125 20.5833 11.426C20.5833 6.63952 16.7031 2.75932 11.9167 2.75932C7.1302 2.75932 3.25 7.72285 3.25 12.5093C3.25 17.2958 7.1302 21.176 11.9167 21.176Z"
                stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22.7496 23.3426L18.0371 18.6301" stroke="#0057B7" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>

        </div>
        <div class="filter" @click="$refs.SearchPageFilterPopup.openPopup()">
          <span>Filter</span>
          <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.25 7.60422C3.25 5.52662 4.93423 3.84239 7.01183 3.84239C7.01183 3.84239 15.1667 3.30111 19.0496 3.84239C21.0737 4.12456 22.2946 4.81908 22.75 6.55111C23.1507 8.07514 22.2708 9.66456 21.4398 10.3677C21.4398 10.3677 18.7933 12.0313 17.6261 13.5946C16.5613 15.021 15.9355 15.4772 15.7083 17.2427C15.6613 17.6083 15.6603 18.1946 15.6715 18.6981C15.6857 19.3331 15.6125 19.9717 15.3576 20.5535C15.1368 21.0578 14.8283 21.6654 14.4814 22.068C13.8186 22.8374 13.3869 23.4785 12.3784 23.5975C11.2345 23.7325 9.75 23.4109 9.75 22.2591C9.75 20.6341 10.1366 19.378 9.75 17.6002C9.43075 16.1321 9.17897 14.9459 8.35623 13.9824C8.13085 13.7185 7.89237 13.4655 7.68834 13.1848C7.29516 12.6437 6.58733 11.681 5.95833 10.8847C5.02592 9.70434 3.25 8.78732 3.25 7.60422Z"
                stroke="#0057B7" stroke-width="2"/>
          </svg>

        </div>
      </div>
      <div class="search-page__filter-by" v-if="content">
        <div class="filter-by">Filter by:</div>
        <div class="filtered-by-item" v-if="filteredBy && content">
          <span>{{
              `${this.filteredBy === 'SONG' ? 'Song' : this.filteredBy === 'ARTIST' ? 'Artist' : 'Event'}`
            }}</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
               @click="fullResetFilter">
            <path d="M18 6C13.7552 9.37699 10.9401 12.5617 6 18" stroke="white" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M6 6C8.23219 9.25226 10.383 11.6003 18 18" stroke="white" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </div>
        <div class="filtered-by-item" v-if="genresAmount">
          <span>{{ genresAmount > 1 ? `+${genresAmount} Filters` : `+${genresAmount} Filter` }}</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
               @click="removeSongFilters">
            <path d="M18 6C13.7552 9.37699 10.9401 12.5617 6 18" stroke="white" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M6 6C8.23219 9.25226 10.383 11.6003 18 18" stroke="white" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
    </div>
    <div class="search-page__content" v-if="loadFlag">
      <div class="content-wrapper" v-if="content">
        <div v-if="content.length">
          <div class="content-song" v-if="filteredBy === 'SONG'">
            <SongItemTemplateComponent
                v-for="(song, index) in content"
                :key="song.id"
                :song="song"
                :identifier="'song-component'"
                :index="index"
            />
          </div>
          <div class="content-artist" v-if="filteredBy === 'ARTIST'">
            <SearchPageArtistCardComponent v-for="item in content" :key="item.uuid" :item="item"/>

          </div>
          <div class="content-event" v-if="filteredBy === 'EVENT'">
            <EventItemComponent
                v-for="(event, index) in content"
                :key="index"
                :event="event"
            />
          </div>
        </div>
        <div v-else class="no-matches-found">No matches found</div>
      </div>
      <div class="d-flex justify-center content-else" v-else>Start your journey</div>
    </div>
    <div class="search-page__content" v-else>
      <PreLoader/>
    </div>
    <v-pagination
        v-if="content && content.length"
        v-model="page"
        :length="count"
        :total-visible="7"
        :color="'#0057B7'"
        prev-icon="<"
        next-icon=">"
        @input="onPagination"
        class="pagination mt-5 mt-sm-10"
    ></v-pagination>
    <SearchPageFilterPopup ref="SearchPageFilterPopup" @submitFilter="submitFilterHandler"/>
  </div>
</template>

<script>
import SearchPageFilterPopup from "@/components/popups/SearchPageFilterPopup";
import {mapActions} from "vuex";
import PreLoader from "@/components/elements/Preloader";
import SongItemTemplateComponent from "@/components/SongItemTemplateComponent";
import EventItemComponent from "@/components/EventItemComponent";
import debounce from "@/mixins/debouncer";
import SearchPageArtistCardComponent from "@/components/SearchPageArtistCardComponent";

export default {
  name: "SearchView",
  components: {
    SearchPageArtistCardComponent,
    EventItemComponent, SongItemTemplateComponent, PreLoader, SearchPageFilterPopup
  },
  mixins: [debounce],
  data() {
    return {
      isSortOpen: false,
      searchText: '',
      sortBy: 'recent',
      orderBy: 'RECENT',
      filteredBy: 'ARTIST',
      genresAmount: 0,
      page: 1,
      count: null,
      content: null,
      filteredData: {
        body: {
          textSearch: '',
        },
        type: 'ARTIST'
      },
      loadFlag: true,
      debounceRequest: null,
    }
  },
  computed: {
    pageSizeHandler() {
      return this.filteredBy === 'SONG' ? 10 : this.filteredBy === 'ARTIST' ? 15 : 9
    }
  },
  methods: {
    ...mapActions({
      searchAction: 'search/searchAction'
    }),
    async submitFilterHandler(data) {
      this.fullResetFilter()
      this.page = 1
      if (this.$route.query.page !== '1') {
        await this.$router.push({path: '/search', query: {page: '1'}})
      }
      this.filteredData = data
      this.filteredBy = data.type
      if (this.filteredBy === 'SONG') {
        this.genresAmount = data.body.genres.length
      } else {
        this.searchText = data.body.textSearch
      }
      await this.getContentRequest()
    },
    async getContentRequest() {
      this.loadFlag = false
      this.filteredData.params = this.getRequestParams(this.page, this.pageSizeHandler)
      const res = await this.searchAction(this.filteredData)
      this.count = res.totalPages
      this.content = res.elements
      this.loadFlag = true
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }

      params["sort"] = ""

      return params;
    },
    linkGen(pageNum) {
      return {
        path: `/search`,
        query: {page: pageNum}
      }
    },
    onPagination() {
      this.$router.push(this.linkGen(this.page)).catch(() => {
      })
    },
    fullResetFilter() {
      this.genresAmount = 0
      this.filteredBy = 'ARTIST'
      this.filteredData = {
        body: {
          textSearch: '',
        },
        type: 'ARTIST'
      }
      this.searchText = ''
      this.content = null
    },
    debounceSearch() {
      this.filteredData.body.textSearch = this.searchText
      this.getContentRequest()
    },
    routeTo(nickName) {
      this.$router.push({path: `/profile/${nickName}`})
    },
    removeSongFilters() {
      this.genresAmount = 0
      this.getContentRequest()
    }
  },
  watch: {
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.page = +newVal
          this.getContentRequest()
          if (this.count < newVal) {
            this.page = 1
          }
        } else {
          this.page = 1
        }
      }
    },
    orderBy(newVal) {
      if (newVal) {
        this.getLegendsRequest()
      }
    }
  },
  mounted() {
    this.debounceRequest = this.debounce(() => {
      this.debounceSearch()
    }, 500)
    this.debounceSearch()
  }
}
</script>

<style scoped>
.search-page-wrapper {
  margin-top: 30px;
  padding: 20px 40px;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px 10px 0 0;
}

.search-page__search {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-input {
  position: relative;
  margin-right: 20px;
  flex: 1;
}

.search-input input {
  padding: 15px;
  width: 100%;
}

.search-input svg {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.filter {
  display: flex;
  align-items: center;
  margin-right: 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #0057B7;
  cursor: pointer;
}

.filter svg {
  margin-left: 6px;
}

.sort-by {
  position: relative;
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #0057B7;
  cursor: pointer;
}

.sort-by svg {
  position: relative;
  top: 2px;
  margin-left: 8px;
  cursor: pointer;
}

.sort-options {
  position: absolute;
  bottom: -135px;
  left: 0;
  background: #FFFFFF;
  padding: 15px 10px;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  width: 140px;
}

.sort-options ul li {
  margin-bottom: 15px;
  cursor: pointer;
}

.sort-options ul li:last-child {
  margin-bottom: 0;
}

.search-page__filter-by {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.filter-by {
  margin-right: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #0057B7;
}

.filtered-by-item {
  display: flex;
  align-items: center;
  padding: 7px 10px;
  background: #0057B7;
  opacity: 0.5;
  border-radius: 50px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  margin-right: 10px;
}

.filtered-by-item span {
  margin-right: 8px;
}

.filtered-by-item svg {
  cursor: pointer;
}

.search-page__content {
  background: #FFFFFF;
  border-bottom: 1px solid #D9D9D9;
  border-left: 1px solid #D9D9D9;
  border-right: 1px solid #D9D9D9;
  border-radius: 0 0 10px 10px;
  padding: 25px 40px;
}

.content-event {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 20px;
}

.content-artist {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
}

.content-else, .no-matches-found  {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #0057B7;
  opacity: 0.3;
}

.no-matches-found {
  text-align: center;
}

@media only screen and (max-width: 900px) {
  .content-event, .content-artist {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 600px) {
  .search-page-header {
    display: none;
  }

  .search-page-wrapper {
    padding: 20px 15px;
  }

  .search-page__search {
    flex-wrap: wrap;
    width: 100%;
  }

  .search-input {
    width: 200px;
  }

  .content-event, .content-artist {
    grid-template-columns: repeat(1, 1fr);
  }

  .search-page__content {
    padding: 20px;
  }
}

</style>