<template>
  <div class="event-item">
    <div class="event-cover" style="cursor: pointer" @click="openEvent">
      <img :src="eventCoverThumbnail" alt="Ukrmusic" class="cover-image br-10" v-if="eventCoverThumbnail" @error="eventCoverThumbnail = false">
      <img src="../assets/img/default-cover.png" alt="Ukrmusic" class="cover-image br-10" v-else>
    </div>
    <div class="event-info">
      <h2 class="event-title">{{ event.eventTitle }}</h2>
      <div class="event-location">
        <p>  {{ splitedDateAndTime[0] }}</p> <div class="separator"></div> <p>{{ splitedDateAndTime[1] }}</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "EventItemComponent",
  props: {
    event: Object,
    routePage: {
      type: Boolean,
      default: false
    },
    userUuid: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      eventCoverThumbnail: this.event.eventCoverThumbnail
    }
  },
  computed: {
    splitedDateAndTime() {
      return this.event.eventStartAt.split(' ')
    }
  },
  methods: {
    openEvent() {
      let nickName = this.$route.params.nickname
      let eventId = this.event.id
      let page = this.routePage ? 1 : this.$route.query.page
      let userUuid = this.userUuid ? this.userUuid : this.$route.query.userUuid
      this.$router.push({path: `/profile/${nickName}/events/${eventId}`, query: {page: page, userUuid: userUuid}}).catch(() => {})
      this.$emit('scrollToTop')
    },
    updateCover() {
      this.eventCoverThumbnail = this.event.eventCoverThumbnail
    }
  },
  updated() {
    this.updateCover()
  }
}
</script>

<style scoped>

.event-cover {
  height: 185px;
}

.event-cover img {
  width: 100%;
  height: 100%;
}

.event-title {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 21px;
  color: var(--primary-blue);
  margin-top: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.event-location {
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  margin-top: 20px;
  color: var(--primary-blue);
}

.separator {
  height: 18px;
  width: 2px;
  background: #FFD60A;
  margin: 0 10px;
}

@media only screen and (max-width: 600px) {
  .event-item {
    width: 100%;
    margin: 20px 0;
  }

  .event-cover {
    width: 100%;
  }

  .event-title, .event-location {
    margin-top: 8px;
  }
}
</style>