<template>
  <div class="show-post-wrapper"
       :style="{'border-radius': index === 0 ? '0 0 10px 10px' : '10px', background: flag ? 'white' : 'white'}"
       :ref="`showPost${index}`">
    <ShowPostHeaderComponent :owner="post.eventOwner" :created-at="post.createAt" :isPremium="isPremium"/>
    <ShowPostMessageComponent v-if="message" :message="message"/>
    <ShowPostImageComponent v-if="image" :image="image"/>
    <ShowPostSongComponent v-if="song" :song="song"/>
    <ShowPostVideoComponent v-if="video" :video="video"/>
    <ShowPostPollComponent v-if="vote" :vote="vote"/>
    <div class="show-post-footer mt-5 pt-5 d-flex">
      <div class="d-flex mr-10 py-1 px-2">
        <div class="likes d-flex footer-item align-center" @click="likePost">
          <svg width="22" height="22" viewBox="0 0 22 22" :fill="isVoted ? '#0057B7' : 'none'"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.4065 15.2009C15.514 17.4153 13.0172 21 11.1883 21C9.35945 21 5.91484 16.6837 3.8747 13.2678C2.43663 10.86 2.54266 11.3679 1.67201 8.75758C-0.154759 3.28063 1.92583 1.1443 5.7036 1.00402C10.8188 0.814075 11.1883 7.42127 11.1883 6.82555C11.1883 6.22983 11.584 1.00402 16.3111 1.00402C21.0381 1.00402 21.46 5.95232 20.7004 8.75758C19.9408 11.5628 18.7365 13.6447 17.4065 15.2009Z"
                stroke="#0057B7" stroke-width="2" stroke-linecap="round"/>
          </svg>
          <v-slide-y-transition :leave-absolute="true" :hide-on-leave="true">
            <p v-if="!isLiked">{{ likesHandler }}</p>
          </v-slide-y-transition>
          <v-slide-y-reverse-transition :leave-absolute="true" :hide-on-leave="true">
            <p v-if="isLiked">{{ likesHandler }}</p>
          </v-slide-y-reverse-transition>
        </div>
      </div>
      <div class="d-flex mr-10 py-1 px-2" style="cursor: pointer" @click="showComments" :class="{'show-comments': isShowComments}">
        <div class="comments d-flex footer-item align-center">
          <svg width="24" height="24" viewBox="0 0 24 24" :fill="isShowComments ? '#FFD60A' : 'none'" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23 10.7778C23.0043 12.391 22.6166 13.9823 21.8685 15.4222C20.9815 17.1477 20.2874 18.9298 18.6 19.9444C16.9125 20.959 14.9269 22.3881 12.9428 22.3889C11.2836 22.3931 9.64676 21.4051 8.1657 20.6778L1 23L3.51428 17.5C3.51428 15.7967 2.29673 13.967 2.30106 12.3538C2.30182 10.4249 2.85497 8.53419 3.89853 6.8936C4.94209 5.25302 6.39094 2.96239 8.1657 2.10003C9.64676 1.37273 11.2836 0.995829 12.9428 1.00003H13.5714C16.1917 1.14058 18.0381 2.21585 19.8937 4.01997C21.7494 5.82409 22.8554 8.23027 23 10.7778Z"
                :stroke="isShowComments ? '#FFFFFF' : '#0057B7'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <p>{{ commentsHandler }}</p>
        </div>
      </div>
      <div class="d-flex mr-10 py-1 px-2">
        <button class="donates d-flex footer-item align-center" @click="$refs.DonatePopup.openPopup()">
          <span class="uah-sign-wrap">₴</span>
          <span>{{ 'Donate' }}</span>
        </button>
      </div>
    </div>
    <ShowCommentsTemplate v-if="isShowComments" :postId="post.id" @replied="onRepliedHandler"/>
    <DonatePopup ref="DonatePopup" @donateSubmitted="donateHandler"/>
  </div>
</template>

<script>
import ShowPostHeaderComponent from "@/components/post/ShowPostHeaderComponent";
import ShowPostMessageComponent from "@/components/post/ShowPostMessageComponent";
import ShowPostImageComponent from "@/components/post/ShowPostImageComponent";
import ShowPostSongComponent from "@/components/post/ShowPostSongComponent";
import ShowPostPollComponent from "@/components/post/ShowPostPollComponent";
import ShowPostVideoComponent from "@/components/post/ShowPostVideoComponent";
import debounce from "@/mixins/debouncer";
import {mapActions, mapGetters, mapMutations} from "vuex";
import ShowCommentsTemplate from "@/components/ShowCommentsTemplate";
import DonatePopup from "@/components/popups/DonatePopup";

export default {
  name: "ShowPostTemplate",
  components: {
    DonatePopup,
    ShowCommentsTemplate,
    ShowPostPollComponent,
    ShowPostSongComponent, ShowPostImageComponent, ShowPostMessageComponent, ShowPostHeaderComponent, ShowPostVideoComponent
  },
  mixins: [debounce],
  props: {
    index: Number,
    post: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      message: null,
      vote: null,
      song: null,
      image: null,
      video: null,
      flag: false,
      scrollListener: null,
      likes: null,
      comments: 0,
      isLiked: false,
      isDisliked: false,
      isVoted: false,
      isShowComments: false,
      debounceRequest: null,

    }
  },
  computed: {
    ...mapGetters({
      isLogged: 'authentication/isLogged'
    }),
    likesHandler: {
      get() {
        return this.likes
      },
      set(newVal) {
        this.likes = newVal
      }
    },
    commentsHandler: {
      get() {
        return this.comments
      },
      set(newVal) {
        this.comments = newVal
      }
    },
    isPremium() {
      return this.post.postType === 'PREMIUM'
    }
  },
  methods: {
    ...mapActions({
      likePostAction: 'content/postLikeAction',
      deleteLikePostAction: 'content/postDeleteLikeAction',
      subscribeRequest: 'payment/purchase'
    }),
    ...mapMutations({
      setErrorMessage: 'error/SET_ERROR_MESSAGE',
      setDialog: 'error/SET_DIALOG'
    }),
    async donateHandler(data) {
      let payload = {
        paymentGoal: 'DONATE',
        money: {
          amount: data,
          currency: 'UAH'
        },
        artistUuid: this.post.eventOwner.uuid,
      }
      try {
        // this.subscribeFlag = false
        this.$refs.DonatePopup.closePopup()
        window.location.href = (await this.subscribeRequest(payload)).checkoutUrl
        // this.$emit('subscribed')
      } catch (e) {
        this.$refs.DonatePopup.closePopup()
        this.setErrorMessage(e.response.data.errors[0].message)
        this.setDialog(true)
      }
    },
    async likePost() {
      if(this.isLogged) {
        if (!this.isVoted) {
          this.isLiked = true
          this.likesHandler = this.likes + 1
          this.isVoted = true
          await this.likePostAction(this.post.id)

        } else {
          this.isLiked = false
          this.likesHandler = this.likes - 1
          this.isVoted = false
          await this.deleteLikePostAction(this.post.id)
        }
      } else {
        this.$emit('signIn')
      }

    },
    dislikePost() {
      this.likesHandler = 1
      this.isDisliked = true
      this.isVoted = false
    },
    showComments() {
      this.isShowComments = !this.isShowComments
    },
    isInViewport() {
      const element = this.$refs[`showPost${this.index}`]
      const rect = element.getBoundingClientRect();

      if (
          rect.top + 200 >= 0 &&
          rect.left >= 0 &&
          rect.bottom - 200 <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      ) {

        this.flag = true

      } else {
        this.flag = false
      }

    },
    onRepliedHandler() {
      this.commentsHandler = this.comments + 1
    }
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.scrollListener)
  },
  mounted() {
    this.likes = this.post.countLike
    this.comments = this.post.countComments
    this.isVoted = this.post.isUserLiked
    this.post.contents.forEach(i => {
      if (i.type === 'MESSAGE') {
        this.message = i
      }
      if (i.type === 'VOTE') {
        this.vote = i
      }
      if (i.type === 'SONG') {
        this.song = i
      }
      if (i.type === 'IMAGE') {
        this.image = i
      }
      if (i.type === 'VIDEO') {
        this.video = i
      }
    })
    this.isInViewport()
    this.debounceRequest = this.debounce(() => {
      this.isInViewport()
    })
    this.scrollListener = () => {
      this.debounceRequest()
    }
    document.addEventListener('scroll', this.scrollListener)
  }
}
</script>

<style scoped>
.show-post-wrapper {
  padding: 20px 40px;
  background: #FFFFFF;
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  margin-bottom: 30px;
}

.show-post-footer {
  border-top: 1px solid #D9D9D9;
}

.footer-item {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-blue);
}

.footer-item svg {
  margin-right: 12px;
}

.likes {
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.show-comments {
  background: #006EE9;
  border-radius: 10px;
}

.show-comments p {
  color: #FFFFFF;
}

.uah-sign-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border: 2px solid #0057B7;
  border-radius: 50%;
  margin-right: 10px;
}

@media only screen and (max-width: 600px) {
  .show-post-wrapper {
    padding: 20px 15px;
    margin-bottom: 15px;
  }
}
</style>