import instance from "../api/instance";


export const prices = {
    namespaced: true,
    state: {
        price: null
    },
    getters: {

    },
    mutations: {

    },
    actions: {
        async getServicePricesAction(ctx, userUuid) {
            return (await instance.get(`/api/v1/service-prices/${userUuid}`)).data.body
        },
        async changeServicePrices(context, {userUuid, body}) {
            await instance.patch(`/api/v1/service-prices/${userUuid}`, body)
        }
    }
}