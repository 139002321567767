<template>
  <div class="w-full songs-item">
    <button class="play-button" @click.prevent="toggleMusic">
      <svg width="40" height="42" viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="togglePlayerFlag">
        <path d="M5.24489 7.5C9.2977 2.65915 11.936 0.595703 21.468 0.595703C31 0.595703 33.7641 4.10685 37 9.5C40 14.5 39.9352 18.5656 39.688 24.5C39.4778 29.5444 36.7646 35.0416 32.5 38.2565C22.1351 46.0701 3.62313 39.4435 1.00051 26.3304C-0.269249 19.9816 0.945866 12.6349 5.24489 7.5Z" fill="#0057B7"/>
        <path d="M25.9993 20.0007C25.9993 18.5014 17.0026 12.4998 17 13.0002C16.9975 13.5007 17.2247 16.5946 17.2234 20.5C17.223 21.8666 16.9981 25.9992 17 26.4999C17.0019 27.0007 25.9993 21.5 25.9993 20.0007Z" stroke="white" stroke-width="2" stroke-linecap="round"/>
      </svg>
      <svg width="40" height="42" viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
        <path d="M5.24489 7.5C9.2977 2.65915 11.936 0.595703 21.468 0.595703C31 0.595703 33.7641 4.10685 37 9.5C40 14.5 39.9352 18.5656 39.688 24.5C39.4778 29.5444 36.7646 35.0416 32.5 38.2565C22.1351 46.0701 3.62313 39.4435 1.00051 26.3304C-0.269249 19.9816 0.945866 12.6349 5.24489 7.5Z" fill="#0057B7"/>
        <path d="M17 14.0005C16.9975 14.5009 17.2247 17.5949 17.2234 21.5002C17.223 22.8668 16.9981 26.9994 17 27.5002" stroke="white" stroke-width="2" stroke-linecap="round"/>
        <path d="M24 14C23.9975 14.5004 24.2248 17.5947 24.2235 21.5C24.223 22.8666 24.2216 26.9993 24.2235 27.5" stroke="white" stroke-width="2" stroke-linecap="round"/>
      </svg>


    </button>
    <div class="song-info">
      <p class="song-name">{{ song.songName }}</p>
      <p class="author-name">{{ song.author?.nickName || song.authorName }}</p>
    </div>
    <div class="song-rewind" v-if="showDuration">{{ songDur }}</div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: "SongItemComponent",
  props: {
    song: Object,
    showDuration: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      player: new Audio(),
      togglePlayerFlag: true,
      songDuration: '00:00',
    }
  },
  computed: {
    ...mapGetters({
      getCurrentSong: 'song/getCurrent'
    }),
    songDur() {
      return this.convertTo(this.song.lengthOfSong || 0)
    }
  },
  methods: {
    ...mapMutations({
      setSongToCurrent: 'song/SET_CURRENT'
    }),
    check() {

    },
    toggleMusic() {
      if (this.togglePlayerFlag) {
        // this.$emit('currentSong', this.song.id)
        this.setSongToCurrent(this.song.songName)
        this.player.play()
        this.togglePlayerFlag = false
      } else {
        this.player.pause()
        this.togglePlayerFlag = true
      }
    },
    convertTo(val) {
      let res
      let time = val
      if (time <= 60) {
        if (time < 10) {
          res = `00:0` + Math.trunc(val)
        } else {
          res = `00:` + Math.trunc(val)
        }
      } else {
        let minutes = Math.floor(time / 60);
        if (minutes < 10) {
          minutes = `0${Math.floor(time / 60)}`
        }
        let seconds = Math.trunc(time - minutes * 60);
        if (seconds < 10) {
          seconds = `0${seconds}`
        }
        res = `${minutes}:${seconds}`
      }
      return res
    },
    refreshSong() {
      this.player.pause()
      this.player.currentTime = 0
      this.togglePlayerFlag = true
    },
  },
  created() {
    this.player.src = this.song.fileUrl || this.song.songUrl
  },
  mounted() {
    // this.songDuration = this.convertTo(this.song.lengthOfSong)
  }
}
</script>

<style scoped>
.songs-item {
  display: flex;
  align-items: center;
  width: 100%;
}

.play-button {
  margin-right: 20px;
  display: flex;
  align-items: center;
}
.song-name {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-blue);
  margin-bottom: 8px;
}

.author-name {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: var(--primary-blue);
}

.song-rewind {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-blue);
  margin-left: auto;
}
</style>