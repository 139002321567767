<template>
  <form ref="paymentForm" action="https://secure.platononline.com/payment/auth" method="post">
    <input type="hidden" name="payment" :value="payment"/>
    <input type="hidden" name="key" :value="key"/>
    <input type="hidden" name="url" :value="url"/>
    <input type="hidden" name="data"
           :value="data"/>
    <input type="hidden" name="req_token" :value="req_token"/>
    <input type="hidden" name="sign" :value="sign"/>
    <input type="hidden" name="email" :value="email"/>
    <input type="hidden" name="order" :value="order"/>
    <input type="hidden" name="error_url" :value="errorUrl"/>
  </form>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "PaymentComponent",
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      payment: 'payment/payment',
      key: 'payment/key',
      url: 'payment/url',
      data: 'payment/data',
      req_token:'payment/req_token',
      sign:'payment/sign',
      email: 'payment/email',
      order: 'payment/order',
      errorUrl: 'payment/errorUrl',
      onSubmit: 'payment/onSubmit'
    })
  },
  methods: {
    submit() {
      setTimeout(() => {
        this.$refs.paymentForm.submit();
      }, 500)
    }
  },
  watch: {
    onSubmit() {
      this.submit();
    }
  }
}
</script>

<style scoped>

</style>