<template>
  <div class="d-flex justify-space-between align-center mb-5" :id="`${identity}-video-congratulation-item-${index}`">
    <div class="d-flex">
      <div>
        <img loading="lazy" :src="item.user.avatar" alt="Ukrmusic" v-if="item && item.user.avatar"
             class="item-image">
        <img src="../assets/img/default-avatar.png" alt="Ukrmusic" class="item-image" v-else>
      </div>
      <div class="d-flex flex-column justify-space-between">
        <div class="nickName">{{ item.user.nickName }}</div>
        <div class="date open-sans">{{ item.orderedAt }}</div>
      </div>
      <div v-if="!isArtist" class="d-flex align-center status">
        <div class="done" v-if="item.status === 'COMPLETE'">Done!</div>
        <div class="sent" v-else-if="item.status === 'INIT'">Sent</div>
        <div class="declined" v-else>Declined</div>
      </div>
    </div>
    <button class="btn-yellow" @click="viewOfferHandler">View</button>
  </div>
</template>

<script>
export default {
  name: "VideoCongratulationOrderComponent",
  props: {
    item: {
      type: Object,
      default: () => {
      }
    },
    identity: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    isArtist: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      imageErrorFlag: true
    }
  },
  methods: {
    viewOfferHandler() {
      this.$emit('viewOffer', this.item)
    },
    setAltImg() {
      this.imageErrorFlag = false
    }
  }
}
</script>

<style scoped>
.item-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 20px;
}

.nickName {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-blue);
}

.date {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: var(--primary-blue);
}

.done, .sent, .declined {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #40C800;
}

.sent {
  color: #FFB800;
}

.declined {
  color: #FF0000;
}

@media only screen and (max-width: 600px){
  .item-image {
    width: 36px;
    height: 36px;
    margin-right: 8px;
  }

  .nickName {
    font-size: 14px;
  }

  .done, .sent, .declined {
    font-size: 14px;
    margin-left: 30px;
  }


}
</style>