<template>
  <div class="profile-nav-bar-wrapper" v-if="isOwner">
    <div class="profile-nav-bar">
      <ul class="nav-block">
        <li @click="routeTo('profile')" style="cursor: pointer">
          <div class="nav-item" :style="{background: routeIs === 'profile' ? '#FFFFFF' : 'none' }">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M18.3346 19.25V17.4167C18.3346 16.4442 17.9483 15.5116 17.2607 14.8239C16.5731 14.1363 15.6404 13.75 14.668 13.75H7.33464C6.36217 13.75 5.42954 14.1363 4.74191 14.8239C4.05428 15.5116 3.66797 16.4442 3.66797 17.4167V19.25"
                  :stroke="routeIs === 'profile' ? '#0057B7' : '#FFFFFF'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                  d="M10.9987 10.0833C13.0237 10.0833 14.6654 8.44171 14.6654 6.41667C14.6654 4.39162 13.0237 2.75 10.9987 2.75C8.97365 2.75 7.33203 4.39162 7.33203 6.41667C7.33203 8.44171 8.97365 10.0833 10.9987 10.0833Z"
                  :stroke="routeIs === 'profile' ? '#0057B7' : '#FFFFFF'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span :style="{
              color: routeIs === 'profile' ? '#0057B7' : '#FFFFFF',
              'font-weight': routeIs === 'profile' ? '800' : '600'
            }">Profile</span>
          </div>
        </li>
        <li @click="routeTo('songs')" style="cursor: pointer">
          <div class="nav-item" :style="{background: routeIs === 'profile my songs' ? '#FFFFFF' : 'none' }">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M8.02833 15.5963C8.02833 15.6484 7.5041 18.9004 6.54618 18.9893C4.79999 19.1514 1.55543 17.4387 1.8155 15.7933C2.0399 14.3736 3.75673 13.8709 5.22331 13.8709C6.42351 13.9621 7.11529 14.8585 8.02833 15.5963ZM8.02833 15.5963C8.59663 13.2782 8.40185 14.1295 8.8032 11.721C9.15248 9.62493 9.07778 7.52238 9.42143 5.43022C9.49689 4.97084 9.32169 4.28228 9.92 4.08875C11.4875 3.58173 12.9634 2.89348 14.5467 2.39732C16.0997 1.9107 17.8266 1.54777 19.3131 1.00586C19.7778 0.836453 19.5723 4.38734 19.5723 5.72185C19.5723 8.16594 19.6406 10.5936 19.7778 13.0331M19.7778 13.0331C19.8217 13.8138 19.8127 14.0059 19.6621 14.5956C19.1209 16.7144 16.3684 17.7279 15.2547 17.5285C13.7828 17.2649 12.4766 16.215 12.6123 14.8872C12.7862 13.1848 14.1735 12.5362 16.1322 12.3959C17.2346 12.3169 19.7339 12.2524 19.7778 13.0331Z"
                  :stroke="routeIs === 'profile my songs' ? '#0057B7' : '#FFFFFF'"  stroke-width="2" stroke-linecap="round"/>
            </svg>
            <span :style="{color: routeIs === 'profile my songs' ? '#0057B7' : '#FFFFFF'}">Songs</span>
          </div>
        </li>
        <li @click="routeTo('events')" style="cursor: pointer">
          <div class="nav-item" :style="{background: routeIs === 'profile events' ? '#FFFFFF' : 'none' }">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M8.02833 15.5963C8.02833 15.6484 7.5041 18.9004 6.54618 18.9893C4.79999 19.1514 1.55543 17.4387 1.8155 15.7933C2.0399 14.3736 3.75673 13.8709 5.22331 13.8709C6.42351 13.9621 7.11529 14.8585 8.02833 15.5963ZM8.02833 15.5963C8.59663 13.2782 8.40185 14.1295 8.8032 11.721C9.15248 9.62493 9.07778 7.52238 9.42143 5.43022C9.49689 4.97084 9.32169 4.28228 9.92 4.08875C11.4875 3.58173 12.9634 2.89348 14.5467 2.39732C16.0997 1.9107 17.8266 1.54777 19.3131 1.00586C19.7778 0.836453 19.5723 4.38734 19.5723 5.72185C19.5723 8.16594 19.6406 10.5936 19.7778 13.0331M19.7778 13.0331C19.8217 13.8138 19.8127 14.0059 19.6621 14.5956C19.1209 16.7144 16.3684 17.7279 15.2547 17.5285C13.7828 17.2649 12.4766 16.215 12.6123 14.8872C12.7862 13.1848 14.1735 12.5362 16.1322 12.3959C17.2346 12.3169 19.7339 12.2524 19.7778 13.0331Z"
                  :stroke="routeIs === 'profile events' ? '#0057B7' : '#FFFFFF'" stroke-width="2" stroke-linecap="round"/>
            </svg>
            <span :style="{color: routeIs === 'profile events' ? '#0057B7' : '#FFFFFF'}">Events</span>
          </div>
        </li>
        <li @click="routeTo('video')" style="cursor: pointer">
          <div class="nav-item" :style="{background: routeIs === 'video congratulations' ? '#FFFFFF' : 'none' }">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M8.02833 15.5963C8.02833 15.6484 7.5041 18.9004 6.54618 18.9893C4.79999 19.1514 1.55543 17.4387 1.8155 15.7933C2.0399 14.3736 3.75673 13.8709 5.22331 13.8709C6.42351 13.9621 7.11529 14.8585 8.02833 15.5963ZM8.02833 15.5963C8.59663 13.2782 8.40185 14.1295 8.8032 11.721C9.15248 9.62493 9.07778 7.52238 9.42143 5.43022C9.49689 4.97084 9.32169 4.28228 9.92 4.08875C11.4875 3.58173 12.9634 2.89348 14.5467 2.39732C16.0997 1.9107 17.8266 1.54777 19.3131 1.00586C19.7778 0.836453 19.5723 4.38734 19.5723 5.72185C19.5723 8.16594 19.6406 10.5936 19.7778 13.0331M19.7778 13.0331C19.8217 13.8138 19.8127 14.0059 19.6621 14.5956C19.1209 16.7144 16.3684 17.7279 15.2547 17.5285C13.7828 17.2649 12.4766 16.215 12.6123 14.8872C12.7862 13.1848 14.1735 12.5362 16.1322 12.3959C17.2346 12.3169 19.7339 12.2524 19.7778 13.0331Z"
                  :stroke="routeIs === 'video congratulations' ? '#0057B7' : '#FFFFFF'" stroke-width="2" stroke-linecap="round"/>
            </svg>
            <span :style="{color: routeIs === 'video congratulations' ? '#0057B7' : '#FFFFFF'}">Video Congratulations</span>
          </div>
        </li>
        <li>
          <router-link to="/profile" class="nav-item">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M8.02833 15.5963C8.02833 15.6484 7.5041 18.9004 6.54618 18.9893C4.79999 19.1514 1.55543 17.4387 1.8155 15.7933C2.0399 14.3736 3.75673 13.8709 5.22331 13.8709C6.42351 13.9621 7.11529 14.8585 8.02833 15.5963ZM8.02833 15.5963C8.59663 13.2782 8.40185 14.1295 8.8032 11.721C9.15248 9.62493 9.07778 7.52238 9.42143 5.43022C9.49689 4.97084 9.32169 4.28228 9.92 4.08875C11.4875 3.58173 12.9634 2.89348 14.5467 2.39732C16.0997 1.9107 17.8266 1.54777 19.3131 1.00586C19.7778 0.836453 19.5723 4.38734 19.5723 5.72185C19.5723 8.16594 19.6406 10.5936 19.7778 13.0331M19.7778 13.0331C19.8217 13.8138 19.8127 14.0059 19.6621 14.5956C19.1209 16.7144 16.3684 17.7279 15.2547 17.5285C13.7828 17.2649 12.4766 16.215 12.6123 14.8872C12.7862 13.1848 14.1735 12.5362 16.1322 12.3959C17.2346 12.3169 19.7339 12.2524 19.7778 13.0331Z"
                  stroke="#304782" stroke-width="2" stroke-linecap="round"/>
            </svg>
            <span>Earnings</span>
          </router-link>
        </li>
        <li @click="routeTo('subscribers')" style="cursor: pointer">
          <div class="nav-item" :style="{background: routeIs === 'subscribers' ? '#FFFFFF' : 'none' }">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M8.02833 15.5963C8.02833 15.6484 7.5041 18.9004 6.54618 18.9893C4.79999 19.1514 1.55543 17.4387 1.8155 15.7933C2.0399 14.3736 3.75673 13.8709 5.22331 13.8709C6.42351 13.9621 7.11529 14.8585 8.02833 15.5963ZM8.02833 15.5963C8.59663 13.2782 8.40185 14.1295 8.8032 11.721C9.15248 9.62493 9.07778 7.52238 9.42143 5.43022C9.49689 4.97084 9.32169 4.28228 9.92 4.08875C11.4875 3.58173 12.9634 2.89348 14.5467 2.39732C16.0997 1.9107 17.8266 1.54777 19.3131 1.00586C19.7778 0.836453 19.5723 4.38734 19.5723 5.72185C19.5723 8.16594 19.6406 10.5936 19.7778 13.0331M19.7778 13.0331C19.8217 13.8138 19.8127 14.0059 19.6621 14.5956C19.1209 16.7144 16.3684 17.7279 15.2547 17.5285C13.7828 17.2649 12.4766 16.215 12.6123 14.8872C12.7862 13.1848 14.1735 12.5362 16.1322 12.3959C17.2346 12.3169 19.7339 12.2524 19.7778 13.0331Z"
                  :stroke="routeIs === 'subscribers' ? '#0057B7' : '#FFFFFF'" stroke-width="2" stroke-linecap="round"/>
            </svg>
            <span :style="{color: routeIs === 'subscribers' ? '#0057B7' : '#FFFFFF'}">Subscribers</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import user from "@/mixins/user";

export default {
  name: "ProfileNavBar",
  mixins: [user],
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      isOwner: false
    }
  },
  props: {
    userUuid: {
      type: String,
      default: ''
    }
  },
  computed: {
    routeIs() {
      return this.$route.name
    }
  },
  methods: {
    routeTo(pageName) {
      let userNick = this.$route.params.nickname
      switch (pageName) {
        case "songs":
          this.$router.push({path: `/profile/${userNick}/songs`, query: {tab: 'songs', userUuid: this.userUuid}})
          break
        case "profile":
          this.$router.push({path: `/profile/${userNick}`, query: {tab: 'playlist', page: '1', userUuid: this.userUuid}})
          break
        case "events":
          this.$router.push({path: `/profile/${userNick}/events`, query: {page: '1', userUuid: this.userUuid}})
          break
        case "subscribers":
          this.$router.push({path: `/profile/${userNick}/subscribers`, query: {userUuid: this.userUuid}})
          break
        case "video":
          this.$router.push({path: `/profile/${userNick}/video`, query: {userUuid: this.userUuid}})
          break
      }
    },

  },
  watch: {
    '$route.params.nickname'() {
      let isLogged = JSON.parse(localStorage.getItem('isLogged'))
      if(isLogged) {
        this.isOwner = this.isOwnerHandler()
      } else {
        this.isOwner = false
      }
    }
  },
  mounted() {
    let isLogged = JSON.parse(localStorage.getItem('isLogged'))
    if(isLogged) {
      this.isOwner = this.isOwnerHandler()
    } else {
      this.isOwner = false
    }
  }
}
</script>

<style scoped>
.profile-nav-bar {
  position: relative;
  right: 100px;
  padding: 15px;
  width: calc(100% + 200px);
  background: linear-gradient(90.41deg, rgba(0, 110, 233, 0.6) -2.47%, rgba(30, 150, 252, 0.36) 103.59%);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(4.5px);
  border-radius: 20px;
}

.profile-nav-bar-wrapper {
  position: sticky;
  top: 0;
  margin-bottom: 20px;
  z-index: 100;
}

.nav-block {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 10px;
}

.nav-item svg {
  margin-right: 10px;
}

.nav-item span {
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
}

@media only screen and (max-width: 1200px) {
  .profile-nav-bar-wrapper {
    overflow: scroll;
  }

  .profile-nav-bar {
    right: 0;
  }
}

@media only screen and (max-width: 600px) {


  .profile-nav-bar {
    right: 0;
    width: calc(100% + 500px);
  }
}
</style>