<template>
  <div class="profile-content-wrapper br-10">
    <div class="content-header">
      <ul class="header-list">
        <li class="header-item" :style="{background: tab === 'songs' ? '#87C0FF' : '#FFFFFF'}" @click="tab = 'songs'">
          <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.25154 17.2181C8.25154 17.276 7.66905 20.8893 6.60469 20.9881C4.66448 21.1683 1.05942 19.2652 1.34838 17.437C1.59772 15.8595 3.5053 15.301 5.13484 15.301C6.4684 15.4023 7.23704 16.3984 8.25154 17.2181ZM8.25154 17.2181C8.88297 14.6425 8.66656 15.5884 9.1125 12.9122C9.50059 10.5833 9.41758 8.24709 9.79942 5.92247C9.88326 5.41205 9.68859 4.64698 10.3534 4.43194C12.095 3.86859 13.7349 3.10386 15.4942 2.55258C17.2197 2.01189 19.1385 1.60863 20.7901 1.00651C21.3065 0.818281 21.0782 4.76372 21.0782 6.2465C21.0782 8.96216 21.154 11.6596 21.3065 14.3701M21.3065 14.3701C21.3553 15.2376 21.3453 15.451 21.1779 16.1062C20.5766 18.4604 17.5183 19.5866 16.2808 19.365C14.6454 19.0721 13.1941 17.9055 13.3448 16.4302C13.5381 14.5387 15.0795 13.818 17.2558 13.6621C18.4807 13.5744 21.2577 13.5027 21.3065 14.3701Z"
                :stroke="tab === 'songs' ? '#FFFFFF' : '#0057B7'" stroke-width="2" stroke-linecap="round"/>
          </svg>
          <span :style="{color: tab === 'songs' ? '#FFFFFF' : '#0057B7'}">Songs</span>
        </li>
        <li class="header-item" :style="{background: tab === 'playlist' ? '#87C0FF' : '#FFFFFF'}"
            @click="tab = 'playlist'">
          <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.6896 15.6636C11.6896 15.707 11.2528 18.417 10.4545 18.4911C8.99934 18.6262 6.29554 17.1989 6.51226 15.8278C6.69927 14.6446 8.12995 14.2258 9.35211 14.2258C10.3523 14.3017 10.9288 15.0488 11.6896 15.6636ZM11.6896 15.6636C12.1632 13.7319 12.0009 14.4413 12.3354 12.4342C12.6264 10.6874 12.5642 8.93532 12.8505 7.19185C12.9134 6.80904 12.7674 6.23523 13.266 6.07396C14.5722 5.65145 15.8022 5.0779 17.1216 4.66444C18.4158 4.25892 19.8548 3.95648 21.0936 3.50488C21.4808 3.36371 21.3096 6.32279 21.3096 7.43487C21.3096 9.47162 21.3665 11.4947 21.4808 13.5276M21.4808 13.5276C21.5174 14.1782 21.5099 14.3382 21.3844 14.8296C20.9335 16.5953 18.6397 17.4399 17.7116 17.2737C16.485 17.0541 15.3966 16.1791 15.5096 15.0727C15.6545 13.654 16.8106 13.1135 18.4428 12.9966C19.3615 12.9308 21.4442 12.877 21.4808 13.5276Z"
                :stroke="tab === 'playlist' ? '#FFFFFF' : '#0057B7'" stroke-width="2" stroke-linecap="round"/>
            <path
                d="M2.5 4.00275C3.46738 4.00275 4.43476 4.00275 5.40214 4.00275C6.09798 4.00275 6.79476 3.99336 7.4905 4.00928C7.79047 4.01615 8.09891 4.02118 8.39251 4.09099C8.51989 4.12127 8.80651 4.23626 8.5886 4.09099"
                :stroke="tab === 'playlist' ? '#FFFFFF' : '#0057B7'" stroke-width="2" stroke-linecap="round"/>
            <path d="M2.5 10.1631H7.47089" :stroke="tab === 'playlist' ? '#FFFFFF' : '#0057B7'" stroke-width="2"
                  stroke-linecap="round"/>
            <path d="M2.52941 16.1631H2.5" :stroke="tab === 'playlist' ? '#FFFFFF' : '#0057B7'" stroke-width="2"
                  stroke-linecap="round"/>
          </svg>
          <span :style="{color: tab === 'playlist' ? '#FFFFFF' : '#0057B7'}">Playlists</span>
        </li>
        <li class="header-item" :style="{background: tab === 'events' ? '#87C0FF' : '#FFFFFF'}" @click="tab = 'events'">
          <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.5813 3.66699C12.5987 3.7796 9.79624 3.81145 4.74798 3.66699C3.73546 3.66699 2.91465 4.4878 2.91465 5.50033C2.91465 5.50033 2.45637 14.667 2.91465 18.3337C3.04022 19.3384 3.89601 20.1616 4.74798 20.167H17.5813C18.5938 20.167 19.4146 19.3462 19.4146 18.3337V5.50033C19.4146 4.4878 18.5938 3.66699 17.5813 3.66699Z"
                :stroke="tab === 'events' ? '#FFFFFF' : '#0057B7'" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"/>
            <path d="M14.832 1.83301C14.8855 4.09779 14.8863 4.87732 14.832 5.49967"
                  :stroke="tab === 'events' ? '#FFFFFF' : '#0057B7'" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path
                d="M7.49609 1.83301C7.49609 1.83301 7.95443 3.66634 7.95443 4.12467C7.95443 4.58301 7.49609 5.49967 7.49609 5.49967"
                :stroke="tab === 'events' ? '#FFFFFF' : '#0057B7'" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"/>
            <path d="M2.91406 9.16676C8.62088 9.20684 17.1224 8.7085 19.4141 9.16676"
                  :stroke="tab === 'events' ? '#FFFFFF' : '#0057B7'" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
          <span :style="{color: tab === 'events' ? '#FFFFFF' : '#0057B7'}">Events</span>
        </li>
      </ul>
    </div>
    <div class="separator my-6"></div>
    <div class="content-body">
      <div class="content-songs-wrapper" v-if="tab === 'songs'">
        <button class="btn-yellow d-flex justify-center align-center w-full mb-5" @click="$refs.UploadSongPopup.openPopup()" v-if="isOwner">
          <v-icon size="30" class="mr-2" color="#FFFFFF">mdi-plus</v-icon>
          <p>Add song</p>
        </button>
        <ul class="songs-list" v-if="getProducts && getProducts.songs.length">
          <SongItemTemplateComponent
              v-for="(song, index) in getProducts.songs"
              :key="song.id"
              :song="song"
              :identifier="'song-component'"
              :index="index"
          />
        </ul>
        <h1 class="content-v-else" v-else>{{ isOwner ? `You don't` : `Artist doesn't` }} have any songs</h1>
      </div>
      <div class="content-playlist-wrapper" v-if="tab === 'playlist'">
        <button class="btn-yellow d-flex justify-center align-center w-full mb-5"  @click="$router.push({path: '/create-playlist'})" v-if="isOwner">
          <v-icon size="30" class="mr-2" color="#FFFFFF">mdi-plus</v-icon>
          Add playlist
        </button>
        <div class="playlist-wrap" v-if="getProducts && getProducts.playLists.length">
          <ProfileContentPlaylistComponent :playLists="getProducts.playLists" :userUuid="userUuid"  :isOwner="isOwner"/>
<!--          <button class="btn-yellow" @click="$router.push({path: '/create-playlist'})">open</button>-->
        </div>
        <h1 class="content-v-else" v-else>{{ isOwner ? `You don't` : `Artist doesn't` }} have any playlists</h1>
      </div>
      <div v-if="tab === 'events'" >
        <button class="btn-yellow d-flex justify-center align-center w-full mb-5"  @click="$refs.CreateContentPopup.openPopup()" v-if="isOwner">
          <v-icon size="30" class="mr-2" color="#FFFFFF">mdi-plus</v-icon>
          Add event
        </button>
        <div v-if="getProducts && getProducts.events.length">
          <ProfileContentEventComponent :events="getProducts.events" :userUuid="userUuid"/>
        </div>
        <h1 class="content-v-else" v-else>{{ isOwner ? `You don't` : `Artist doesn't` }} have any events</h1>
      </div>
    </div>
    <div class="content-footer">
      <button class="btn-blue-filled w-full" @click="showAll">
        <span>Show all</span>
      </button>
    </div>
    <UploadSongPopup ref="UploadSongPopup" @closePopupAfterUpload="closePopupAfterUpload"/>
    <CreateContentPopup ref="CreateContentPopup" :userUuid="userUuid"/>
  </div>
</template>

<script>
import ProfileContentPlaylistComponent from "@/components/profile/ProfileContentPlaylistComponent";
import ProfileContentEventComponent from "@/components/profile/ProfileContentEventComponent";
import SongItemTemplateComponent from "@/components/SongItemTemplateComponent";
import {mapGetters} from "vuex";
import UploadSongPopup from "@/components/popups/UploadSongPopup.vue";
import CreateContentPopup from "@/components/popups/CreateContentPopup.vue";

export default {
  name: "ProfileContentComponent",
  components: {
    CreateContentPopup,
    UploadSongPopup, ProfileContentEventComponent, ProfileContentPlaylistComponent, SongItemTemplateComponent},
  props: {
    userUuid: String,
    isOwner: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      tab: 'songs'
    }
  },
  computed: {
    ...mapGetters({
      getProducts: 'profile/getProducts'
    }),
  },
  methods: {
    showAll() {
      let userNick = this.$route.params.nickname
      switch (this.tab) {
        case "songs":
          this.$router.push({path: `/profile/${userNick}/songs`, query: {tab: 'songs', userUuid: this.userUuid}})
          break
        case "playlist":
          this.$router.push({path: `/profile/${userNick}/songs`, query: {tab: 'playlist', page: '1', userUuid: this.userUuid}})
          break
        case "events":
          this.$router.push({path: `/profile/${userNick}/events`, query: {page: '1', userUuid: this.userUuid}})
          break
      }
    },
    closePopupAfterUpload() {

    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.profile-content-wrapper {
  margin-top: 40px;
  padding: 20px;
  background: #FFFFFF;
}


.header-list {
  display: flex;
  align-items: center;
  width: 100%;
}

.header-item {
  width: 33.333%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  border-radius: 10px;
  cursor: pointer;
}

.header-item svg {
  margin-right: 12px;
}

.content-footer {
  margin-top: 40px;
}

.add-song p {
  color: var(--primary-blue);
}

@media only screen and (max-width: 600px) {
  .content-footer {
    margin-top: 20px;
  }
}
</style>