var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"data-app":""}},[_c('v-dialog',{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"popup br-10"},[_c('div',{staticClass:"form-main-content"},[_c('div',{staticClass:"popup-header"},[_c('h3',{staticClass:"form-title"},[_vm._v(_vm._s(_vm.imgType === 'avatar' ? 'Changing avatar' : _vm.imgType === 'cover'? 'Changing cover' : 'Changing picture'))]),_c('div',{staticClass:"close-dialog",on:{"click":_vm.closePopup}},[_c('img',{attrs:{"src":require("../../assets/svg/icons/cross.svg"),"alt":"close-icon"}})])]),_c('div',{staticClass:"popup-content"},[_c('div',{staticClass:"popup-content-container"},[_c('cropper',{ref:"cropper",staticClass:"cropper",staticStyle:{"height":"370px"},attrs:{"src":_vm.image.src || _vm.defaultImage,"auto-zoom":true,"image-class":"cropper__image","stencil-props":{
      aspectRatio: _vm.aspectRatio,
      class: 'cropper-stencil',
      previewClass: 'cropper-stencil__preview',
      draggingClass: 'cropper-stencil--dragging',
      handlersClasses: {
        default: 'cropper-handler',
        eastNorth: 'cropper-handler--east-north',
        westNorth: 'cropper-handler--west-north',
        eastSouth: 'cropper-handler--east-south',
        westSouth: 'cropper-handler--west-south',
      },
      handlersWrappersClasses: {
        default: 'cropper-handler-wrapper',
        eastNorth: 'cropper-handler-wrapper--east-north',
        westNorth: 'cropper-handler-wrapper--west-north',
        eastSouth: 'cropper-handler-wrapper--east-south',
        westSouth: 'cropper-handler-wrapper--west-south',
      },
    }}})],1)]),_c('div',{staticClass:"crop-image-btn-wrap"},[(_vm.isUpload)?_c('ButtonBlue',{staticClass:"btn-change-cover item",on:{"clickEvent":function($event){return _vm.$refs.file.click()}}},[_c('span',[_vm._v("Upload")])]):_c('ButtonBlue',{staticClass:"btn-change-cover item",on:{"clickEvent":_vm.closePopup}},[_c('span',[_vm._v("Canel")])]),_c('button',{staticClass:"btn-yellow item",on:{"click":_vm.cropImage}},[_vm._v("Save")]),_c('input',{ref:"file",staticClass:"input-hide",attrs:{"type":"file","id":`upload-input-${_vm.imgType}`,"accept":"image/*"},domProps:{"value":_vm.uploadInput},on:{"change":function($event){return _vm.uploadImage($event)}}})],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }