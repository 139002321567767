<template>
  <div data-app style="z-index: 100000">
    <v-dialog v-model="dialog">
      <div class="create-content-wrapper" style="max-height: calc(100vh - 100px); overflow-y: auto">
        <div class="content-header">
          <button class="ml-auto" @click="closePopup">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 5.5C11.4627 8.31416 9.11674 10.9681 5 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 5.5C6.86016 8.21022 8.65254 10.1669 15 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        <div class="content-body mt-5" style="position:relative;">
          <input type="number" class="input w-full" v-model="amount" placeholder="0">
          <span class="error" v-for="error of v$.amount.$errors" :key="error.$uid">
              {{ error.$message }}
            </span>
        </div>
        <div class="d-flex justify-space-between mt-5">
          <button class="setAmountButton" @click="amount = 100">100</button>
          <button class="setAmountButton" @click="amount = 200">200</button>
          <button class="setAmountButton" @click="amount = 500">500</button>
        </div>
        <div class="d-flex mt-5">
          <button class="btn-blue button-element br-10 w-full flex mr-2" @click="closePopup">Close</button>
          <button class="btn-yellow w-full flex" @click="submitDonate">Donate</button>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {useVuelidate} from '@vuelidate/core'
import {minValue, maxValue, required} from '@vuelidate/validators'

export default {
  name: "DonatePopup",
  components: {},
  setup() {
    return {v$: useVuelidate({$lazy: true, $autoDirty: false})}
  },
  data() {
    return {
      amount: null,
      dialog: false
    }
  },
  validations() {
    return {
      amount: {
        minValue: minValue(1),
        maxValue: maxValue(999),
        required: required
      }
    }
  },
  computed: {},
  methods: {
    async submitDonate() {
      const isFormCorrect = await this.v$.$validate()
      if(!isFormCorrect) {
        return false
      } else {
        this.$emit('donateSubmitted', this.amount)
        this.v$.$reset()
      }
    },
    openPopup() {
      this.dialog = true
    },
    closePopup() {
      this.v$.$reset()
      this.amount = null
      this.dialog = false
    },

  },
}
</script>

<style scoped>
.create-content-wrapper {
  position: relative;
  max-width: 400px;
  width: 100%;
  background: #FFFFFF;
  padding: 40px;
  margin: 0 auto;
  border-radius: 10px;
}

.content-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.setAmountButton {
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 20px;
  background: #8d8d8d;
  opacity: 0.3;
  color: #FFFFFF;
}

.setAmountButton:hover {
  opacity: 1;
}

.error {
  position: absolute;
  bottom: -20px;
  left: 0;
}

@media only screen and (max-width: 600px){
  .error {
    bottom: -15px;
  }
}
</style>