<template>
  <nav class="navigation"
       v-if="currentPage !== 'login' && currentPage !== 'sign up' && currentPage !== 'privacy policy' && currentPage !== 'terms and conditions' && currentPage !== 'reset password'">
    <div class="nav-wrapper">
      <div class="text-center nav-logo text-primary">
        <img src="../assets/svg/Logo.svg" alt="ukrmusic"/>
        <p>ukrmusic</p>
      </div>
      <div>
        <div v-if="!isSmallHeight && checkForUserAndUserType" class="nav-add-wrapper">
          <img src="../assets/svg/navbar/add.svg" class="nav-add" v-show="!isAddHovered"
               @mouseover="isAddHovered = true" alt="Ukrmusic"/>
          <img src="../assets/svg/navbar/add-hover.svg" class="nav-add" v-show="isAddHovered"
               @mouseout="isAddHovered = false" @click="$emit('openCreatePostPopup')" alt="Ukrmusic"/>
        </div>
        <ul class="nav-list">
          <li class="nav-items">
            <router-link :to="{path: user ? '/search' : '/sign-in', query: {page: '1'}}">
              <NavSearchIcon/>
            </router-link>
          </li>
          <li class="nav-items">
            <router-link to="/">
              <NavHomeIcon/>
            </router-link>
          </li>
          <li class="nav-items">
            <router-link :to="{path: user ? '/video' : '/sign-in'}">
              <NavVideoIcon/>
            </router-link>
          </li>
          <li class="nav-items" v-if="user">
            <router-link :to="{path: `/profile/${user.nickName}`}">
              <NavProfileIcon/>
            </router-link>
          </li>
          <li class="nav-items" v-if="user">
            <router-link :to="{path: `/chat`}">
              <NavMessagesIcon/>
            </router-link>
          </li>
          <!--          <li class="nav-items">-->
          <!--            <router-link :to="{path: user ? '/notifications' : '/sign-in'}">-->
          <!--              <NavNotificationsIcon/>-->
          <!--            </router-link>-->
          <!--          </li>-->
          <li class="nav-items">
            <router-link :to="{path: '/hall-of-fame', query: {generalTab: 'SONG', contentTab: 'voting', page: '0'}}">
              <NavHallOfFameIcon/>
            </router-link>
          </li>
          <li class="nav-items" v-if="isLogged">
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                 @click="logout">
              <path
                  d="M9.5 21C9.5 21 7.03043 21.0002 6.5 21.0002C5.96957 21.0002 5.18926 20.5364 4.5 20.0002C4.06402 19.6611 3.5 19.5304 3.5 19V5.00002C3.5 4.46958 3.71701 4.05629 4.08579 3.5858C4.72352 2.77218 5.47779 2.65421 6.5 2.50002C7.67445 2.32285 9.5 3.00002 9.5 3.00002"
                  stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16.5 17L21.5 12L16.5 7" stroke="#0057B7" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M21.5 12H9.5" stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </li>
          <li class="nav-items" v-else>
            <button class="btn-yellow mr-2 w-full" @click="$router.push({name: 'login'})"
                    style="padding: 5px; margin-bottom: 5px" id="navbar-sign-in">Sign In
            </button>

          </li>
        </ul>
        <div>

        </div>
      </div>

    </div>
  </nav>
</template>

<script>
// import NavNotificationsIcon from "./navbar/NavNotificationsIcon";
import NavMessagesIcon from "./navbar/NavMessagesIcon";
import NavProfileIcon from "./navbar/NavProfileIcon";
import NavHomeIcon from "@/components/navbar/NavHomeIcon";
import NavVideoIcon from "@/components/navbar/NavVideoIcon.vue";
import {mapActions, mapGetters} from "vuex";
import NavHallOfFameIcon from "@/components/navbar/NavHallOfFameIcon";
import NavSearchIcon from "@/components/navbar/NavSearchIcon";

export default {
  name: 'NavBar',
  data() {
    return {
      isAddHovered: false,
      user: null,
      isSmallHeight: false
    }
  },
  components: {
    NavSearchIcon,
    NavHallOfFameIcon,
    NavMessagesIcon,
    NavProfileIcon,
    NavHomeIcon,
    NavVideoIcon
  },
  computed: {
    ...mapGetters({
      isLogged: 'authentication/isLogged'
    }),
    currentPage() {
      return this.$route.name
    },
    checkForUserAndUserType() {
      return this.user && this.isArtist()
    }
  },
  methods: {
    ...mapActions({
      logout: 'authentication/logout'
    }),
    isArtist() {
      const user = JSON.parse(localStorage.getItem('user'))
      return user?.authorities.includes('ARTIST')
    },
    deviceHandler() {
      const height = window.innerHeight
      this.isSmallHeight = height <= 800
    }
  },
  beforeUpdate() {
    this.user = JSON.parse(localStorage.getItem('user'))
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.deviceHandler)
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem('user'))
    window.addEventListener('resize', this.deviceHandler)
    this.deviceHandler()
  }
}
</script>

<style scoped>
.nav-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.navigation {
  background: white;
  width: 100px;
  border-radius: 20px;
  padding: 20px;
  position: sticky;
  top: 40px;
  left: 0;
  height: fit-content;
  margin-right: 10px;
}

.nav-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-logo {
  margin-bottom: 50px;
  font-weight: 700;
}

.nav-add {
  margin-bottom: 70px;
  cursor: pointer;
}

.nav-items {
  cursor: pointer;
  margin-bottom: 40px;
}

.nav-items:last-child {
  margin-bottom: 0;
}

.nav-add-wrapper {
  display: block;
}


@media only screen and (max-width: 600px) {
  .navigation {
    display: none;
  }
}

@media only screen and (min-height: 1300px) {
  .nav-add-wrapper {
    display: none;
  }
}
</style>