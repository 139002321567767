<template>
  <div class="list-item" :class="isHovered ? 'is-hovered' : ''" @mouseover="isHovered = true" @mouseout="isHovered = false" @click="openChat">
    <img v-if="item && item.opponent.avatar" :src="item.opponent.avatar" alt="Urkmusic" class="item-avatar">
    <img v-else src="../../assets/img/default-avatar.png" alt="Urkmusic" class="item-avatar">
    <div class="d-flex flex-column justify-space-between item-sender-info">
      <div class="nick-name">{{ item.opponent.nickName }}</div>
      <div class="last-message" v-if="item.lastMessage && this.getMessageType === 'TEXT'">{{ item.lastMessage ? item.lastMessage.context : ''}}</div>
      <div class="last-message image" v-if="item.lastMessage && this.getMessageType === 'IMAGE'">{{ 'Photo' }}</div>
    </div>
    <div class="d-flex ml-auto">
      <div class="time" v-if="this.item.lastMessage">{{ getTime }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatListItem",
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isHovered: false
    }
  },
  computed: {
    getMessageType() {
      return this.item.lastMessage.messageType
    },
    getTime() {
      let splitDate = this.item.lastMessage.createAt.split(' ')
      let timeWithoutSeconds = splitDate[1].split(':')
      return timeWithoutSeconds[0] + ':' + timeWithoutSeconds[1]
    }
  },
  methods: {
    openChat() {
      this.$emit('openChat', this.item)
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.list-item {
  padding: 15px;
  background: #FFFFFF;
  border-radius: 10px;
  display: flex;
  width: 100%;
  cursor: pointer;
  margin-bottom: 20px;
}

.list-item.is-hovered {
  background: #006EE9;
}

.item-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
  border: 1px solid #FFFFFF;
}

.item-sender-info {
  max-width: 150px;
}

.item-sender-info div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.nick-name {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #000000
}



.last-message {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #707070;
}

.time {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.is-hovered .nick-name, .is-hovered .last-message, .is-hovered .time{
  color: #FFFFFF;
}

.last-message.image {
  color: var(--primary-blue);
}

.is-hovered .last-message.image {
  color: #FFFFFF;
}
</style>