import instance from './instance'

import profileModule from './profile-api';



export default {
  profile: profileModule(instance),

}
