<template>
    <li :class="item.itYourMessage ? 'left' : 'right'" @click="getTime">
      <div v-if="item.itYourMessage" class="message-wrapper owner">
        <div class="message" v-if="item.messageType === 'TEXT'">{{ item.context }}</div>
        <div class="message" v-if="item.messageType === 'IMAGE'">
          <img :src="item.context" alt="Ukrmusic" class="message-image">
        </div>
        <div class="message time mt-auto">{{ getTime }}</div>
        <svg width="21" height="39" viewBox="0 0 21 39" fill="none" xmlns="http://www.w3.org/2000/svg"
             class="message-svg">
          <path d="M0 0C5.52285 0 10 4.47715 10 10V28H0V0Z" fill="#006EE9"/>
          <rect y="28" width="10" height="11" fill="#006EE9"/>
          <path
              d="M10 29C10 31.4658 11.3469 33.7347 13.5116 34.9154L17.5572 37.1221C18.4667 37.6182 18.1143 39 17.0783 39H10V29Z"
              fill="#006EE9"/>
        </svg>
      </div>
      <div v-else class="message-wrapper opponent">
        <div class="message time mt-auto">{{ getTime }}</div>
        <div class="message" v-if="item.messageType === 'TEXT'">{{ item.context }}</div>
        <div class="message" v-if="item.messageType === 'IMAGE'">
          <img :src="item.context" alt="Ukrmusic" class="message-image">
        </div>
        <svg width="21" height="39" viewBox="0 0 21 58" fill="none" xmlns="http://www.w3.org/2000/svg"
             class="message-svg">
          <path
              d="M11 48C11 50.4658 9.65313 52.7347 7.48842 53.9154L3.44281 56.1221C2.5333 56.6182 2.88565 58 3.92166 58H11V48Z"
              fill="#F5F5F5"/>
          <path d="M11 10C11 4.47715 15.4772 0 21 0V47H11V10Z" fill="#F5F5F5"/>
          <rect x="11" y="47" width="10" height="11" fill="#F5F5F5"/>
        </svg>

      </div>
    </li>
</template>

<script>
export default {
  name: "ChatMessageComponent",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    getTime() {
      let splitDate = this.item.createAt.split(' ')
      let withoutSeconds = splitDate[1].split(':')
      return withoutSeconds[0] + ':' + withoutSeconds[1]
    }
  },
  methods: {
    // getTime() {
    //   let splitDate = this.item.createAt.split(' ')
    //   let withoutSeconds = splitDate[1].split(':')
    //   return withoutSeconds[0] + ':' + withoutSeconds[1]
    // }
  },
  mounted() {

  }
}
</script>

<style scoped>
li {
  max-width: 80%;
  margin-top: 20px;
}

li.right {
  margin-right: auto;
}

li.left {
  margin-left: auto;
}

.message-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  border-radius: 10px;
  min-height: 39px;
}

.message-wrapper.owner {
  background: #006EE9;
  margin-right: 20px;
}

.message-wrapper.owner .message {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  margin-right: 10px;
}

.message-wrapper.owner .time {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
}

.message-wrapper.owner .message-svg {
  position: absolute;
  bottom: 0;
  right: -10px;
}

.message-wrapper.opponent {
  background: #F5F5F5;
  margin-left: 20px;
}

.message-wrapper.opponent .message {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-right: 10px;
}

.message-wrapper.opponent .time {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.message-wrapper.opponent .message-svg {
  position: absolute;
  bottom: 0;
  left: -12px;
}

.message-image {
  max-width: 300px;
  max-height: 200px;
  object-fit: contain;
  border-radius: 10px;
}

.message.time {
  font-size: 10px;
}

@media only screen and (max-width: 600px) {
  .message-image {
    max-width: 150px;
    max-height: 100px;
  }
}
</style>