<template>
  <div class="d-flex justify-center">
    <v-progress-circular
        indeterminate
        :color="'#0057B7'"
        v-show="color === 'blue'"
        :width="preloadWidth"
        :size="preloaderSize"
    ></v-progress-circular>
    <v-progress-circular
        indeterminate
        :color="colorOnlyBlue ? '#0057B7' : '#FFD700'"
        v-show="color === 'yellow'"
        :width="preloadWidth"
        :size="preloaderSize"
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "PreLoader",
  props: {
    preloadWidth: {
      type: Number,
      default: 10
    },
    preloaderSize: {
      type: Number,
      default: 80
    },
    colorOnlyBlue: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      color: 'blue',
      interval: null
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      this.color === 'blue' ? this.color = 'yellow' : this.color = 'blue'
    }, 1400)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style scoped>

</style>