<template>
  <div class="d-flex flex-column">
    <div>
      <form ref="paymentForm" action="https://secure.platononline.com/payment/auth" method="post">
        <input type="hidden" name="payment" value="CC"/>
        <input type="hidden" name="key" value="0FSH78H3FZ"/>
        <input type="hidden" name="url" value="https://ukrmusic.com"/>
        <input type="hidden" name="data"
               value="eyJhbW91bnQiOiIyMS4wMCIsImRlc2NyaXB0aW9uIjoiZm9yIGdlbmFkaXkgbmV4dCIsImN1cnJlbmN5IjoiVUFIIiwicmVjdXJyaW5nIjoiWSJ9"/>
        <input type="hidden" name="req_token" value="Y"/>
        <input type="hidden" name="sign" value="bc797e49eaf325d795a172334d0023a9"/>
      </form>
    </div>
  </div>
</template>

<script>


export default {
  name: "TestTest",
  data() {
    return {
      formData: new FormData(),
    }
  },
  methods: {
    async onSubmit() {
      const url = "https://secure.platononline.com/payment/auth";
      this.formData.append("payment", "CC");
      this.formData.append("key1", "0FSH78H3FZ");
      this.formData.append("url", "https://ukrmusic.com");
      this.formData.append("data", "eyJhbW91bnQiOiIyMS4wMCIsImRlc2NyaXB0aW9uIjoiZm9yIGdlbmFkaXkgbmV4dCIsImN1cnJlbmN5IjoiVUFIIiwicmVjdXJyaW5nIjoiWSJ9");
      this.formData.append("req_token", "Y");
      this.formData.append("sign", "99bb85d9393f95d59fb53a047d82942c");
      const response = await fetch(url, {
        method: "POST",
        body: this.formData
      });
      const json = await response.json();
      console.log(json);
    }
  },
  components: {},
  mounted() {
    this.$refs.paymentForm.submit()
  }
}
</script>

<style scoped>
.comments ul {
  padding-left: 16px;
  margin: 6px 0;
}

.wrap {
  display: flex;
  max-height: 800px;
  overflow: auto;
  width: 600px;
  height: 800px;
}
</style>