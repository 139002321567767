import {authHeader} from '@/helpers';
import LocalStorageService from "../services/LocalStorageService";
import instance from "../api/instance";
// import axios from "axios";
import router from "@/router";

export const userService = {
    login,
    logout,
    getAll
};

// LocalstorageService
const localStorageService = LocalStorageService.getService();

function login(username, password) {
    const requestOptions = { username, password };


    return instance.post(`/api/v1/login`, requestOptions)
        .then(res => {
            const { avatar, nickName, publicName, uuid, access_token, refresh_token, authorities} = res.data.body
            const user = {
                avatar,
                nickName,
                publicName,
                uuid,
                authorities
            }
            if (res.data.body) {
                localStorage.setItem('isLogged', 'true');
                localStorage.setItem('access_token', JSON.stringify(access_token));
                localStorage.setItem('refresh_token', JSON.stringify(refresh_token));
                localStorage.setItem('authorities', JSON.stringify(authorities));

                if(user) {
                    localStorage.setItem('user', JSON.stringify(user));
                }
            }
            return user;
        });
}

function logout() {
    const user = JSON.parse(localStorage.getItem('user'))
    const uuid = user.uuid
    return instance.post(`/api/v1/login/logout/${uuid}`)
        .then(() => {
            localStorageService.clearToken();
            router.push({name: 'login'})
        });



}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`http://172.31.18.236:8080/api/v2/login`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                // location.reload(true);
            }

            const error = (data && data.errors[0].message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}