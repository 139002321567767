<template>
  <div>
    <div class="mobile-header mb-10">
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"
           @click="$router.back()">
        <rect width="30" height="30" rx="15" fill="#0057B7"/>
        <path d="M13.413 9L9.5 15M9.5 15L13.413 21M9.5 15L21.5 15" stroke="#FFD60A" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  <div class="legend-wrapper" v-if="legend" ref="top">
    <ButtonBack/>
    <div class="legend-header">
      <div class="header-cover">
        <img loading="lazy" :src="legend.coverUrl" alt="Ukrmusic" v-if="legend && legend.coverUrl"
             class="legend-cover">
        <img src="../../assets/img/default-cover.png" alt="Ukrmusic" class="legend-cover" v-else>
      </div>
      <div class="header-content">
        <HallOfFameAudio :fileUrl="legend.songUrl" :ref="`audio-player-${identifier}-${index}`"
                         :uuid="legend.legendUuid" v-if="legend && getType === 'SONG'" class="mb-2 mb-sm-4"/>
        <h2 class="legend-name">{{ legend.name }}</h2>
        <h4 class="legend-author-name">{{ legend.authorName }}</h4>
        <div class="socials desktop" v-if="socials && socials.length">
          <div class="social-item" v-for="item in socials" :key="item.linkType">
            <img :src="require(`../../assets/svg/icons/${item.linkType.toLowerCase()}.svg`)" alt="Ukrmusic" @click="redirectTo(item.url)">
          </div>
        </div>
      </div>
      <div class="header-winner-cup" v-if="legend.status === 'SUCCESS'">
        <img src="../../assets/img/hall-of-fame-winner-cup.png" alt="Ukrmusic">
      </div>
    </div>
    <div class="socials mobile" v-if="socials && socials.length">
      <div class="social-item" v-for="item in socials" :key="item.linkType">
        <img :src="require(`../../assets/svg/icons/${item.linkType.toLowerCase()}.svg`)" alt="Ukrmusic" @click="redirectTo(item.url)">
      </div>
    </div>
    <div class="separator my-10"></div>
    <div class="legend-body">
      <div class="body-vote" v-if="legend && legend.status !== 'SUCCESS'">
        <div class="left">
          <div class="vote-progress">
            <div class="vote-progress-filled" :style="{width: getVoteBarWidth}"></div>
          </div>
          <div class="vote-amount">
            <div class="vote-amount-current">{{ `${legend.voteInfo.actualVote} Votes` }}</div>
            <div class="vote-amount-from">{{ `from ${legend.voteInfo.expectedMinimalVote}` }}</div>
          </div>
          <div class="vote-time-left">{{ calcDaysRemain }}</div>

        </div>
        <div class="right">
          <button class="btn-blue-filled vote-btn" v-if="legend.voteInfo.isVoted">Voted!</button>
          <button class="btn-yellow vote-btn" v-else @click="voteHandler">Vote</button>
        </div>
      </div>
      <div class="separator mt-10 mb-6" v-if="legend && legend.status !== 'SUCCESS'"></div>
      <div class="body-user">
        <div class="user-info" v-if="legend">
          <img loading="lazy" :src="legend.legendStarterUserOwner.avatar" alt="Ukrmusic" v-if="legend.legendStarterUserOwner.avatar"
               class="legend-avatar">
          <img src="../../assets/img/default-avatar.png" alt="Ukrmusic" class="legend-avatar" v-else>
          <div class="legend-info">
            <h3 class="legend-user-name">{{ legend.legendStarterUserOwner.publicName }}</h3>
          </div>
<!--          <div class="legend-user-date">{{ legend.createdAt.split(' ')[0] }}</div>-->
        </div>
        <div class="user-description">{{ legend.ownerLegendStarterOpinion }}</div>
        <div class="user-link" v-if="customLink">
          <a :href="customLink.url" @click.prevent="redirectTo(customLink.url)">{{ customLink.linkName }}</a>
        </div>
      </div>
      <div class="separator my-5" v-if="legend.legendType === 'SONG'"></div>
      <div class="body-info" v-if="legend.legendType === 'SONG'">
        <div class="info-item" v-if="legend.era">
          <div class="item-title">Era:</div>
          <div class="item-text">{{ eraHandler(legend.era) }}</div>
        </div>
        <div class="info-item" v-if="legend.genre">
          <div class="item-title">Genre:</div>
          <div class="item-text">{{ genreHandler(legend.genre) }}</div>
        </div>
        <div class="info-item" v-if="legend.textAuthor">
          <div class="item-title">Text author:</div>
          <div class="item-text">{{ legend.textAuthor }}</div>
        </div>
        <div class="info-item" v-if="legend.label">
          <div class="item-title">Label:</div>
          <div class="item-text">{{ legend.label }}</div>
        </div>
        <div class="info-item" v-if="legend.recordingStudio">
          <div class="item-title">Recording studio:</div>
          <div class="item-text">{{ legend.recordingStudio }}</div>
        </div>
        <div class="info-item" v-if="legend.composer">
          <div class="item-title">Composer:</div>
          <div class="item-text">{{ legend.composer }}</div>
        </div>
      </div>
      <div class="body-judges">
        <div class="separator my-5" v-if="legend.judges && legend.judges.length"></div>
        <div class="judges-info" v-if="legend.judges && legend.judges.length">
          <h3 class="item-title">Judges:</h3>
          <div class="d-flex">
            <h4 class="item-text mr-2 judges" v-for="(item, index) in legend.judges" :key="item.uuid" @click="$router.push({path: `/profile/${item.nickName}`})">{{ item.publicName }}<span v-if="index + 1 !== legend.judges.length">,</span></h4>
          </div>
        </div>
      </div>
    </div>
    <div class="separator my-5"></div>
    <div class="legend-footer">
      <div class="left">
        <div class="likes" @click="likePost">
          <svg width="24" height="24" viewBox="0 0 24 24" :fill="isVoted ? '#0057B7' : 'none'" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.4065 16.2009C16.514 18.4153 14.0172 22 12.1883 22C10.3595 22 6.91484 17.6837 4.8747 14.2678C3.43663 11.86 3.54266 12.3679 2.67201 9.75758C0.845241 4.28063 2.92583 2.1443 6.7036 2.00402C11.8188 1.81407 12.1883 8.42127 12.1883 7.82555C12.1883 7.22983 12.584 2.00402 17.3111 2.00402C22.0381 2.00402 22.46 6.95232 21.7004 9.75758C20.9408 12.5628 19.7365 14.6447 18.4065 16.2009Z"
                stroke="#0057B7" stroke-width="2" stroke-linecap="round"/>
          </svg>
          <v-slide-y-transition :leave-absolute="true" :hide-on-leave="true">
            <p v-if="!isLiked">{{ likesHandler }}</p>
          </v-slide-y-transition>
          <v-slide-y-reverse-transition :leave-absolute="true" :hide-on-leave="true">
            <p v-if="isLiked">{{ likesHandler }}</p>
          </v-slide-y-reverse-transition>
        </div>
        <div class="d-flex mr-10 py-1 px-2" style="cursor: pointer" @click="showComments" :class="{'show-comments': isShowComments}">
          <div class="comments d-flex footer-item align-center">
            <svg width="24" height="24" viewBox="0 0 24 24" :fill="isShowComments ? '#FFD60A' : 'none'" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M23 10.7778C23.0043 12.391 22.6166 13.9823 21.8685 15.4222C20.9815 17.1477 20.2874 18.9298 18.6 19.9444C16.9125 20.959 14.9269 22.3881 12.9428 22.3889C11.2836 22.3931 9.64676 21.4051 8.1657 20.6778L1 23L3.51428 17.5C3.51428 15.7967 2.29673 13.967 2.30106 12.3538C2.30182 10.4249 2.85497 8.53419 3.89853 6.8936C4.94209 5.25302 6.39094 2.96239 8.1657 2.10003C9.64676 1.37273 11.2836 0.995829 12.9428 1.00003H13.5714C16.1917 1.14058 18.0381 2.21585 19.8937 4.01997C21.7494 5.82409 22.8554 8.23027 23 10.7778Z"
                  :stroke="isShowComments ? '#FFFFFF' : '#0057B7'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <p>{{ commentsHandler }}</p>
          </div>
        </div>
      </div>
      <div class="right">

      </div>
    </div>
    <ShowCommentsTemplate v-if="isShowComments" :postId="legend.legendUuid" :commentsFor="'LEGEND'" @replied="onRepliedHandler"/>
  </div>
  </div>
</template>

<script>
import ButtonBack from "@/components/elements/ButtonBack";
import {mapActions, mapGetters, mapMutations} from "vuex";
import HallOfFameAudio from "@/components/hall-of-fame/HallOfFameAudio";
import ShowCommentsTemplate from "@/components/ShowCommentsTemplate";

export default {
  name: "HallOfFameArtistPage",
  components: {ShowCommentsTemplate, HallOfFameAudio, ButtonBack},
  data() {
    return {
      legend: null,
      identifier: 'legend',
      index: 0,
      isLiked: false,
      isVoted: false,
      likes: 0,
      comments: 0,
      isShowComments: false,
      socials: [],
      customLink: null
    }
  },
  computed: {
    ...mapGetters({
      current: 'song/getCurrent',
      isLogged: 'authentication/isLogged',
      genresList: 'song/getGenresList',
      erasList: 'hallOfFame/getEraList'
    }),
    getType() {
      return this.$route.query.type
    },
    likesHandler: {
      get() {
        return this.likes
      },
      set(newVal) {
        this.likes = newVal
      }
    },
    commentsHandler: {
      get() {
        return this.comments
      },
      set(newVal) {
        this.comments = newVal
      }
    },
    calcDaysRemain() {
      // const oneDay = 24 * 60 * 60 * 1000;
      let date1 = new Date()
      let date2 = new Date(this.legend.voteInfo.firstVoteStageOpenUntil)
      let difference = date2.getTime() - date1.getTime()
      let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
      return totalDays > 0 ? `${totalDays} days left` : 'On Reviewing'
    },
    getVoteBarWidth() {
      let result;
      if (this.legend.voteInfo.votePercent > 0 && this.legend.voteInfo.votePercent < 4) {
        result = '4%'
      } else if (this.legend.voteInfo.votePercent > 4 && this.legend.voteInfo.votePercent < 100) {
        result = this.legend.voteInfo.votePercent + '%'
      } else if (this.legend.voteInfo.votePercent >= 100) {
        result = '100%'
      } else if (this.legend.voteInfo.votePercent === 0) {
        result = '0'
      }
      return result
    }
  },
  methods: {
    ...mapActions({
      getLegend: 'hallOfFame/getLegend',
      likeLegendAction: 'hallOfFame/likeLegendAction',
      dislikeLegendAction: 'hallOfFame/dislikeLegendAction',
      voteForLegend: 'hallOfFame/voteForLegend'
    }),
    ...mapMutations({
      openSignInPopup: 'authentication/openSignInPopup'
    }),
    checkValidUrl(url) {
      const isStartWithHttp = url.startsWith('http://')
      const isStartWithHttps = url.startsWith('https://')
      return isStartWithHttp || isStartWithHttps
    },
    async getLegendRequest() {
      this.legend = await this.getLegend(this.$route.params.uuid)
      this.likes = this.legend.likeCount
      this.comments = this.legend.commentCount
      this.isLiked = this.legend.liked
      this.isVoted = this.legend.liked
      this.socials = this.legend.links.filter(i => i.linkType !== 'CUSTOM')
      this.customLink = this.legend.links.filter(i => i.linkType === 'CUSTOM')[0]

    },
    async likePost() {
      if(this.isLogged) {
        if (!this.isVoted) {
          this.isLiked = true
          this.likesHandler = this.likes + 1
          this.isVoted = true
          await this.likeLegendAction(this.$route.params.uuid)

        } else {
          this.isLiked = false
          this.likesHandler = this.likes - 1
          this.isVoted = false
          await this.dislikeLegendAction(this.$route.params.uuid)
        }
      } else {
        this.openSignInPopup()
      }

    },
    async voteHandler() {
      if(this.isLogged) {
        await this.voteForLegend(this.$route.params.uuid)
        await this.getLegendRequest()
      } else {
        this.openSignInPopup()
      }

    },
    eraHandler(era) {
      let result;
      this.erasList.forEach(i => {
        if(i.value === era) {
          result = i.name
        }
      })
      return result
    },
    genreHandler(genre) {
      let result;
      this.genresList.forEach(i => {
        if(i.value === genre) {
          result = i.name
        }
      })
      return result
    },
    showComments() {
      this.isShowComments = !this.isShowComments
    },
    redirectTo(link) {
      if(this.checkValidUrl(link)) {
        window.open(link)
      } else {
        window.open(`https://${link}`)
      }
    },
    onRepliedHandler() {
      this.commentsHandler = this.comments + 1
    }
  },
  watch: {
    current(newVal) {
      if (newVal !== this.legend.legendUuid) {
        this.$refs[`audio-player-${this.identifier}-${this.index}`].refreshSong()
      }
    }
  },
  created() {
    this.getLegendRequest()
  },
  mounted() {
    setTimeout(() => {
      this.$refs.top.scrollIntoView({behavior: "smooth"})
    }, 200)
  }
}
</script>

<style scoped>
.desktop {
  display: flex;
}

.mobile {
  display: none;
}

.legend-wrapper {
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  padding: 40px;
}

.legend-header {
  display: flex;
}

.legend-cover {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  margin-right: 24px;
}

.header-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.legend-name {
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 38px;
  color: #0057B7;
  margin-bottom: 16px;
}

.legend-author-name {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #0057B7;
  margin-bottom: 16px;
}

.socials {
  align-items: center;
}

.social-item {
  margin-right: 20px;
  cursor: pointer;
}

.body-vote {
  display: flex;
}

.body-vote .left {
  flex: 1;
  margin-right: 40px;
}

.vote-progress {
  width: 100%;
  height: 16px;
  border-radius: 10px;
  background: #FFD700;
}

.vote-progress-filled {
  height: 100%;
  width: 30%;
  background: #0057B7;
  border-radius: 10px;
}

.vote-amount {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.vote-amount-current {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  color: #0057B7;
  margin-right: 8px;
}

.vote-amount-from {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #0057B7;
}

.vote-time-left {
  margin-top: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #0057B7;
}

.vote-btn {
  padding: 13px 91px;
}

.user-info {
  display: flex;
  align-items: center;
}

.legend-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 20px;
}

.legend-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.legend-user-name {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #0057B7;
}

.legend-user-date {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #0057B7;
  margin-left: auto;
}

.user-description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 20px;
  color: #000000;
  word-break: break-all;
}

.user-link {

  margin-top: 20px;
}

.user-link a {
  text-decoration-line: underline;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #0057B7;
}

.legend-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.legend-footer .left {
  display: flex;
}

.likes, .comments {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #0057B7;
  cursor: pointer;
}

.likes svg, .comments svg {
  margin-right: 12px;
}

.likes {
  margin-right: 50px;
}

.show-comments {
  background: #006EE9;
  border-radius: 10px;
}

.show-comments p {
  color: #FFFFFF;
}

.body-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.info-item {
  margin-bottom: 20px;
}

.item-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #0057B7;
  margin-bottom: 8px;
}

.item-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.item-text.judges {
  color: #0057B7;
  cursor: pointer;
}

.header-winner-cup {
  margin-left: auto;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: flex;
    margin-top: 20px;
  }

  .body-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .legend-wrapper {
    padding: 20px;
  }

  .body-vote {
    flex-direction: column;
  }

  .body-vote .left {
    margin-right: 0;
  }

  .body-vote .right {
    margin-top: 35px;
  }

  .body-vote .right button{
    width: 100%;
  }

  .legend-cover {
    width: 128px;
    height: 128px;
  }

  .legend-name {
    font-size: 20px;
    line-height: 23px;
  }

  .legend-author-name {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0;
  }

  .header-winner-cup img {
    width: 40px;
    height: 45px;
  }

}


</style>