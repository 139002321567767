<template>
  <div class="card-wrapper">
    <img :src="item.coverUrl" loading="lazy" alt="Ukrmusic" v-if="item && item.coverUrl"
         @click="$router.push({path: `/hall-of-fame/${item.legendUuid}`, query: {type}})">
    <img src="../../assets/img/default-cover.png" alt="Ukrmusic" loading="lazy" v-else
         @click="$router.push({path: `/hall-of-fame/${item.legendUuid}`, query: {type}})">
    <div class="name-wrapper d-flex align-center mt-4">
      <HallOfFameAudio :fileUrl="item.songUrl" :ref="`audio-player-${identifier}-${index}`" :uuid="item.legendUuid"
                       v-if="getCase === 'case2'"/>
      <div :style="{marginLeft: getCase === 'case2' ? 10 + 'px' : 0}">
        <div class="name">{{ item.name }}</div>
        <div class="mt-3 description" v-if="getCase === 'case1' || getCase === 'case2'">{{ item.authorName }}</div>
        <div class="mt-3 description" v-else>{{ eraHandler(item.era) }}</div>
      </div>
    </div>
    <div class="vote-progress-wrapper" v-if="getCase === 'case1' || getCase === 'case3'">
      <div class="mt-4 vote-progress">
        <div class="vote-progress__filled" :style="{width: getVoteBarWidth}"></div>
      </div>
      <div class="vote-progress__vp">
        <div>{{ `${actualVoteComputedHandler}VP` }}
          <!--          <span class="voted-amount">{{ `+${100}` }}</span>-->
        </div>
        <div>{{ `${item.voteInfo.expectedMinimalVote}VP` }}</div>
      </div>
    </div>
    <div v-if="getCase === 'case1' || getCase === 'case3'">
      <button class="btn-yellow w-full mt-5"
              @click="voteHandler"
              v-if="!voteComputedHandler">
        Vote
      </button>
      <button class="btn-blue-filled w-full mt-5"
              @click="$router.push({path: `/hall-of-fame/${item.legendUuid}`, query: {type}})" v-else>
        Voted!
      </button>
    </div>
    <div v-else>
      <button class="btn-yellow w-full mt-5"
              @click="$router.push({path: `/hall-of-fame/${item.legendUuid}`, query: {type}})">
        View
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import HallOfFameAudio from "@/components/hall-of-fame/HallOfFameAudio";

export default {
  name: "HallOfFameCardComponent",
  components: {
    HallOfFameAudio

  },
  props: {
    item: {
      type: Object,
      default: () => {
      }
    },
    getCase: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    identifier: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isVoted: false,
      actualVote: 0,
      votePercent: 0
    }
  },
  computed: {
    ...mapGetters({
      current: 'song/getCurrent',
      isLogged: 'authentication/isLogged',
      erasList: 'hallOfFame/getEraList'
    }),
    getVoteBarWidth() {
      let result;
      // this.item.voteInfo.votePercent > 100 ? 100 + '%' : item.voteInfo.votePercent + '%'
      if (this.votePercentComputedHandler > 0 && this.votePercentComputedHandler < 4) {
        result = '4%'
      } else if (this.votePercentComputedHandler > 4 && this.votePercentComputedHandler < 100) {
        result = this.votePercentComputedHandler + '%'
      } else if (this.votePercentComputedHandler >= 100) {
        result = '100%'
      } else if (this.votePercentComputedHandler === 0) {
        result = '0'
      }
      return result
    },
    voteComputedHandler: {
      get() {
        return this.isVoted
      },
      set(value) {
        this.isVoted = value
      }
    },
    actualVoteComputedHandler: {
      get() {
        return this.actualVote
      },
      set(value) {
        this.actualVote = this.actualVote + value
      }
    },
    votePercentComputedHandler: {
      get() {
        return this.votePercent
      },
      set(value) {
        this.votePercent = this.votePercent + value
      }
    },
  },
  methods: {
    ...mapActions({
      voteForLegend: 'hallOfFame/voteForLegend'
    }),
    eraHandler(era) {
      let result;
      this.erasList.forEach(i => {
        if(i.value === era) {
          result = i.name
        }
      })
      return result
    },
    async voteHandler() {
      if (this.isLogged) {
        await this.voteForLegend(this.item.legendUuid)
        this.voteComputedHandler = true
        this.actualVoteComputedHandler = 1
        if (this.votePercentComputedHandler === 0) {
          this.votePercentComputedHandler = 1
        }
      } else {
        this.$emit('openSignInPopup')
      }
    },
  },
  watch: {
    current(newVal) {
      if (newVal !== this.item.legendUuid) {
        this.$refs[`audio-player-${this.identifier}-${this.index}`].refreshSong()
      }
    }
  },
  mounted() {
    this.isVoted = this.item.voteInfo.isVoted
    this.actualVote = this.item.voteInfo.actualVote
    this.votePercent = this.item.voteInfo.votePercent
  }
}
</script>

<style scoped>
.card-wrapper {
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
  padding: 20px;
}

img {
  width: 100%;
  height: 234px;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
}

.name {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: var(--primary-blue);
  max-width: 240px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.description {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--primary-blue);
}

.vote-progress {
  width: 100%;
  height: 8px;
  background: #FFD700;
  border-radius: 10px;
}

.vote-progress__filled {
  height: 100%;
  background: #0057B7;
  border-radius: 10px;
}

.vote-progress__vp {
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #0057B7;
  margin-top: 8px;
}

@media only screen and (max-width: 600px) {
  .name {
    max-width: unset;
    overflow: unset;
  }
}

</style>