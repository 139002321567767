<template>
  <div data-app>
    <v-dialog v-model="dialog">
      <div class="popup br-10">
        <div class="form-main-content">
          <div class="popup-header">
            <h3 class="form-title">{{ imgType === 'avatar' ? 'Changing avatar' : imgType === 'cover'?  'Changing cover' : 'Changing picture' }}</h3>
            <div class="close-dialog" @click="closePopup">
              <img src="../../assets/svg/icons/cross.svg" alt="close-icon">
            </div>
          </div>

          <div class="popup-content">
            <div class="popup-content-container">
              <!--            :src="image.src ? image.src : imageSrc ? imageSrc : defaultImage"-->
              <cropper
                  style="height: 370px;"
                  ref="cropper"
                  :src="image.src || defaultImage"
                  :auto-zoom="true"
                  class="cropper"
                  image-class="cropper__image"
                  :stencil-props="{
        aspectRatio,
        class: 'cropper-stencil',
        previewClass: 'cropper-stencil__preview',
        draggingClass: 'cropper-stencil--dragging',
        handlersClasses: {
          default: 'cropper-handler',
          eastNorth: 'cropper-handler--east-north',
          westNorth: 'cropper-handler--west-north',
          eastSouth: 'cropper-handler--east-south',
          westSouth: 'cropper-handler--west-south',
        },
        handlersWrappersClasses: {
          default: 'cropper-handler-wrapper',
          eastNorth: 'cropper-handler-wrapper--east-north',
          westNorth: 'cropper-handler-wrapper--west-north',
          eastSouth: 'cropper-handler-wrapper--east-south',
          westSouth: 'cropper-handler-wrapper--west-south',
        },
      }"
              />
            </div>
          </div>
          <div class="crop-image-btn-wrap">
            <ButtonBlue class="btn-change-cover item" @clickEvent="$refs.file.click()" v-if="isUpload">
              <span>Upload</span>
            </ButtonBlue>
            <ButtonBlue class="btn-change-cover item" @clickEvent="closePopup" v-else>
              <span>Canel</span>
            </ButtonBlue>
            <button class="btn-yellow item" @click="cropImage">Save</button>
            <input
                type="file"
                ref="file"
                :id="`upload-input-${imgType}`"
                @change="uploadImage($event)"
                accept="image/*"
                class="input-hide"
                :value="uploadInput"
            />
          </div>

        </div>
      </div>

    </v-dialog>
  </div>
</template>

<script>

import {Cropper} from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import ButtonBlue from "@/components/elements/ButtonBlue";

export default {
  name: "CropImagePopup",
  data() {
    return {
      dialog: false,
      image: {
        src: '',
      },
      // avatarImage: null,
      uploadedImg: null,
      uploadInput: null
    }
  },
  props: {
    // imageSrc: String,
    defaultImage: String,
    index: Number,
    aspectRatio: Number,
    borderRadius: {
      type: String,
      default: '0',
    },
    imgType: String,
    isUpload: {
      type: Boolean,
      default: true
    }

  },
  methods: {
    cropImage() {
      const result = this.$refs.cropper.getResult()
      // this.avatarImage = result.canvas.toDataURL('image/*')
      // this.$emit('cropImage', this.avatarImage)

      this.uploadedImg = result.canvas.toDataURL('image/*')
      this.$emit('cropImage', this.uploadedImg, this.index)
      this.dialog = false
    },
    uploadImage(event) {
      const {files} = event.target
      if (files && files[0]) {
        if (this.defaultImage) {
          URL.revokeObjectURL(this.defaultImage)
        }
        // if (this.imageSrc) {
        //   URL.revokeObjectURL(this.imageSrc)
        // }
        const blob = URL.createObjectURL(files[0])
        this.image = {
          src: blob,
          type: files[0].type,
        }
      }
    },
    uploadLegendImage(file) {
      if (file) {
        if (this.defaultImage) {
          URL.revokeObjectURL(this.defaultImage)
        }
        // if (this.imageSrc) {
        //   URL.revokeObjectURL(this.imageSrc)
        // }
        const blob = URL.createObjectURL(file)
        this.image = {
          src: blob,
          type: file.type,
        }
      }
    },
    openPopups() {
      this.dialog = true
    },
    closePopup() {
      this.image.src = ''
      this.$emit('closePopup')
      this.$refs.cropper.reset()
      this.dialog = false
    },
    confirmAction() {
      this.dialog = false
    },
  },
  watch: {
    dialog() {
      if (this.dialog) {
        setTimeout(() => {
          let item = document.getElementsByClassName('cropper-stencil__preview')
          item[0].style.borderRadius = this.borderRadius
        })
      } else {
        this.uploadedImg = null
        this.image = {
          src: '',
          type: ''
        }
      }

    },
  },
  components: {
    Cropper,
    ButtonBlue
  },
};
</script>

<style>
.close-dialog {
  cursor: pointer;
}

.cropper {
  min-height: 300px;
  width: 100%;
}

.btn input {
  display: none;
}

.cropper__image {
  opacity: 1;
}

.cropper-stencil__preview:after, .cropper-stencil__preview:before {
  content: "";
  opacity: 0;
  transition: opacity 0.25s;
  position: absolute;
  pointer-events: none;
  z-index: 1;
}

.cropper-stencil__preview:after {
  border-left: solid 1px white;
  border-right: solid 1px white;
  width: 33%;
  height: 100%;
  transform: translateX(-50%);
  left: 50%;
  top: 0;
}

.cropper-stencil__preview:before {
  border-top: solid 1px white;
  border-bottom: solid 1px white;
  height: 33%;
  width: 100%;
  transform: translateY(-50%);
  top: 50%;
  left: 0;
}

.cropper-stencil--dragging .cropper-stencil__preview:after, .cropper-stencil--dragging .cropper-stencil__preview:before {
  opacity: 0.4;
}

.cropper-line {
  border-color: rgba(255, 255, 255, 0.8);
}

.cropper-handler {
  display: block;
  opacity: 0.7;
  position: relative;
  flex-shrink: 0;
  transition: opacity 0.5s;
  border: none;
  background: white;
  top: auto;
  left: auto;
  height: 4px;
  width: 4px;
}

.cropper-handler--west-north, .cropper-handler--east-south, .cropper-handler--west-south, .cropper-handler--east-north {
  display: block;
  height: 16px;
  width: 16px;
  background: none;
}

.cropper-handler--west-north {
  border-left: solid 2px white;
  border-top: solid 2px white;
  top: 7px;
  left: 7px;
}

.cropper-handler--east-south {
  border-right: solid 2px white;
  border-bottom: solid 2px white;
  top: -7px;
  left: -7px;
}

.cropper-handler--west-south {
  border-left: solid 2px white;
  border-bottom: solid 2px white;
  top: -7px;
  left: 7px;
}

.cropper-handler--east-north {
  border-right: solid 2px white;
  border-top: solid 2px white;
  top: 7px;
  left: -7px;
}

.cropper-handler--hover {
  opacity: 1;
}


.popup {
  width: 480px !important;
  margin: 0 auto;
  background: #FFFFFF;
  padding: 20px;
}

.popup-content {
  margin: 40px 0;
}

.input-hide {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
}

.crop-image-btn-wrap {
  display: flex;
  justify-content: space-between;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  font-weight: 800;
  font-size: 28px;
  line-height: 33px;
  color: var(--primary-blue);
}

.item {
  width: calc(50% - 10px);
}

@media only screen and (max-width: 600px) {
  .popup {
    width: unset !important;
  }
}
</style>