import instance from "../api/instance";


export const registration = {
    namespaced: true,
    state: {
        price: null
    },
    getters: {

    },
    mutations: {

    },
    actions: {
        async signUpAction(ctx, data) {
            await instance.post(`/api/v1/sign-up`, data)
        },
    }
}