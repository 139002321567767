<template>
  <div data-app>
    <v-dialog
        v-model="dialog"
        max-width="846"
        persistent
    >
      <div class="order-video-popup-wrapper" style="max-height: 90%; overflow-y: auto">
        <div class="order-video-header">
          <h1 class="order-video-header__title">Video congratulation</h1>
          <button @click="closePopup">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 5.5C11.4627 8.31416 9.11674 10.9681 5 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 5.5C6.86016 8.21022 8.65254 10.1669 15 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        <div class="order-video-info mt-10">
          <div class="d-flex align-center">
            <p class="input-title mr-2">Price:</p>
            <span class="price">{{ `₴${videoPrice.price}` }}</span>
          </div>
          <div class="d-flex align-center mt-5">
            <p class="input-title mr-2">Artist rules:</p>
            <span class="rules">{{ `${videoPrice.rule ? videoPrice.rule : ''}` }}</span>
          </div>
        </div>
        <div class="separator my-5"></div>
        <form class="order-video-email-wrapper mt-5" @submit.prevent="submitAction" style="position:relative;">
          <p class="input-title">Your desire</p>
          <textarea name="" id="playlist-description" rows="3" class="input w-full mt-4"
                    v-model="desire" placeholder="Text"></textarea>
          <span class="error" v-if="serverErrorMessage">{{ serverErrorMessage }}</span>
          <button type="submit" class="btn-yellow mt-10 w-full">Buy</button>
        </form>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "OrderVideoCongratulationPopup",
  components: {},
  props: {
    videoPrice: {
      type: Object,
      required: true
    },
    userUuid: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialog: false,
      desire: '',
      serverErrorMessage: ''
    }
  },
  methods: {
    ...mapActions({
      orderVideo: 'payment/purchase'
    }),
    openPopup() {
      this.dialog = true
    },
    closePopup() {
      this.dialog = false
      this.resetForm()
    },
    async submitAction() {
      let payload = {
        paymentGoal: 'VID_CONGRATULATION',
        money: {
          amount: this.videoPrice.price,
          currency: 'UAH'
        },
        artistUuid: this.userUuid,
        userDesire: this.desire
      }
      try {
        window.location.href = (await this.orderVideo(payload)).checkoutUrl
        this.resetForm()
        this.closePopup()
      } catch (e) {
        this.serverErrorMessage = e.response.data.errors[0].message
      }

    },
    resetForm() {
      this.desire = ''
      this.serverErrorMessage = ''
    }
  }
}
</script>

<style scoped>
.order-video-popup-wrapper {
  position: relative;
  max-width: 846px;
  width: 100%;
  background: #FFFFFF;
  padding: 40px;
  margin: 0 auto;
  border-radius: 10px;
}

.order-video-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.order-video-header__title {
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 33px;
  color: var(--primary-blue);
}



.price {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #40C800;
}

.rules {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: var(--primary-blue);
}

.error {
  position: absolute;
  bottom: 60px;
  left: 0;
}
</style>