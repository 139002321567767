<template>
  <div>
    <TermsAndConditionsUkr />
  </div>
</template>

<script>
import TermsAndConditionsUkr from "@/components/static/TermsAndConditionsUkr";
export default {
  name: "TermsAndConditionsTemplate",
  components: {TermsAndConditionsUkr},
  mounted() {
    document.title = 'Terms and conditions'
  }
}
</script>

<style scoped>

</style>