<template>
  <div>
    <div class="drag-drop-upload" @dragover="handleDragOver" @dragleave="handleDragOver" @drop="handleFileSelect"
         @click="clickInput" :class="{'dragging' : dragging}" :style="{borderColor: borderColor}">

      <label for="file-input" class="file-input" :id="`file-input-container-${inputId}`"
             :style="{background: dragging ? '#e2e8f0' : ''}">
        <slot name="upload-container">
          <div class="upload-wrap">
            <div class="upload-header">
              <svg width="44" height="43" viewBox="0 0 44 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M38.125 26.875V34.0417C38.125 34.992 37.7475 35.9035 37.0755 36.5755C36.4035 37.2475 35.492 37.625 34.5417 37.625C21.3226 37.4309 14.9275 37.3765 9.45833 37.625C8.50797 37.625 7.59654 37.2475 6.92453 36.5755C6.25253 35.9035 5.875 34.992 5.875 34.0417V26.875"
                    stroke="#0057B7" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M30.9596 14.3333C28.8534 9.92398 27.1052 7.82954 22.0013 5.375C18.4506 8.05655 16.4792 9.86479 13.043 14.3333"
                    stroke="#0057B7" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M21.9984 5.375C20.6872 21.9525 21.0015 24.6925 21.9984 26.875" stroke="#0057B7"
                      stroke-width="3"
                      stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <p v-text="title"></p>
            </div>
            <p class="upload-body">Or Drag and Drop it here</p>
            <p class="upload-footer" v-text="text"></p>
          </div>
        </slot>
        <!--        <slot name="dragging-container" v-if="dragging">-->
        <!--          Drop files here to upload-->
        <!--        </slot>-->
        <input :id="`file-input-${inputId}`" type="file" :accept="supportedFile" @change="handleFileInput" ref="fileInput">
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "DragDropFileUpload",
  props: {
    supportedFile: {
      type: String,
      default: 'image/*'
    },
    error: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    inputId: {
      type: String,
      required: true
    }
  },
  computed: {
    borderColor() {
      return this.error ? 'red' : '#718096'
    }
  },
  data() {
    return {
      dragging: false,
      results: null,
    };
  },
  methods: {
    handleFileInput(event) {
      this.processFileInput(event.target.files);
      event.target.value = ''
    },
    handleDragOver(event) {
      event.stopPropagation()
      event.preventDefault()
      this.dragging = event.type === 'dragover'
      event.dataTransfer.dropEffect = 'copy'
    },
    handleFileSelect(event) {
      event.stopPropagation();
      event.preventDefault();
      this.dragging = false;
      this.processFileInput(event.dataTransfer.files);
    },
    processFileInput(files) {
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        if (file.type.match('image.*')) {
          let reader = new FileReader();
          reader.onloadend = function () {
            if (this.results === null)
              this.results = [];
            const results = {
              filePreview: reader.result,
              file: file
            };
            this.results.push(results);
            this.$emit('handleInput', this.results);
          }.bind(this);
          reader.readAsDataURL(file);
        } else {
          let reader = new FileReader();
          reader.onloadend = function () {
            if (this.results === null)
              this.results = [];
            const results = {
              file: file
            };
            this.results.push(results);
            this.$emit('handleInput', this.results);
          }.bind(this);
          reader.readAsDataURL(file);
        }
      }
    },
    clearResults() {
      this.results = null;
    },
    clickInput() {
      this.$refs.fileInput.click();
    },
    deleteCover() {
      this.results = null
    }
  }
}
</script>

<style lang="scss" scoped>
.drag-drop-upload {
  width: 100%;
  height: 100%;
  border: 2px dashed #718096;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

  &.dragging {
    background-color: #e2e8f0;
    border-color: #667eea;
  }

  > .file-input {
    height: 100%;
    pointer-events: none;
    margin-bottom: 0;
    font-size: 1.5rem;
    color: #718096;

    > input {
      display: none;
    }
  }
}

.file-results {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  .image-preview {
    height: 200px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;

    > .delete {
      position: absolute;
      background: #bd2130;
      color: white;
      padding: 6px 10px;
      bottom: 0;
      right: 0;

      &:hover {
        background: #c82333;
      }
    }
  }
}

.upload-wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 33px 20px;
  cursor: pointer;
}

.upload-header {
  display: flex;
  align-items: center;
}

.upload-header svg {
  margin-right: 25px;
}

.upload-header p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-blue-dark);
}

.upload-body {
  margin-top: 20px;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: var(--primary-blue-dark);
}

.upload-footer {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  opacity: 0.4;
  margin-top: 20px;
  text-align: center;
}

.drag-drop-upload.preview {
  padding: 0;
  height: 200px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.preview-container {
  width: 100%;
  height: 100%;
}

.btn-blue.delete {
  width: 200px;
  border-radius: 10px;
  margin-top: 10px;
}
</style>