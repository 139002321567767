export default {
    methods: {
        convertTo(val) {
            let res
            let time = val
            if (time <= 60) {
                if (time < 10) {
                    res = `00:0` + Math.trunc(val)
                } else {
                    res = `00:` + Math.trunc(val)
                }
            } else {
                let minutes = Math.floor(time / 60);
                if (minutes < 10) {
                    minutes = `0${Math.floor(time / 60)}`
                }
                let seconds = Math.trunc(time - minutes * 60);
                if (seconds < 10) {
                    seconds = `0${seconds}`
                }
                res = `${minutes}:${seconds}`
            }
            return res
        },
    }
}