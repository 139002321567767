<template>
  <div class="flex justify-center mx-auto w-7 h-7">
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="$route.name === 'video congratulations'">
      <path
          d="M22.4633 12.5902C21.802 18.819 16.6773 21.7607 10.2303 22.2805C3.78325 22.8003 3.45225 15.7049 1.13813 12.5902C-1.17599 9.47544 3.122 2.72734 8.90778 0.650314C14.6936 -1.42671 23.1247 6.36136 22.4633 12.5902Z"
          fill="#FFD700"/>
      <g clip-path="url(#clip0_3634_8786)">
        <path
            d="M15.7846 14.831C16.6798 15.0101 17.5688 15.5576 18.3901 15.9401C18.9738 16.2119 19.9522 16.4269 20.4611 16.8086C20.4919 16.8316 20.4696 16.5195 20.4679 16.4959L20.4678 16.4946C20.4155 15.7093 20.3575 14.9396 20.3543 14.1496C20.3474 12.4673 20.2558 11.0627 20.1739 9.08555C20.1606 8.76434 20.139 8.68615 20.139 8.26308C20.139 7.84 20.0983 7.382 19.8198 7.21492C19.6423 7.10842 18.9123 7.69833 18.8578 7.73602C17.6396 8.57711 16.857 9.3563 15.7846 10.3785M15.5441 17.2363C15.3404 18.3337 6.03938 17.5095 3.19044 17.2308C2.73282 17.186 2.39919 16.7836 2.42843 16.3247C2.55035 14.4115 2.77434 9.57754 2.19581 6.29312C1.94679 4.87938 15.3428 5.30224 15.5441 6.29312C15.7454 7.28401 15.7846 15.9401 15.5441 17.2363Z"
            stroke="#0057B7" stroke-width="1.69231" stroke-linecap="round"/>
      </g>
      <defs>
        <clipPath id="clip0_3634_8786">
          <rect width="20.3077" height="20.3077" fill="white" transform="translate(1.34619 1.15381)"/>
        </clipPath>
      </defs>
    </svg>

    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
      <g clip-path="url(#clip0_2101_7575)">
        <path
            d="M17.5635 16.6641C18.6215 16.8757 19.6721 17.5227 20.6428 17.9747C21.3326 18.296 22.4889 18.55 23.0904 19.0011C23.1267 19.0284 23.1004 18.6595 23.0984 18.6316L23.0983 18.6301C23.0364 17.702 22.9678 16.7923 22.964 15.8587C22.956 13.8706 22.8476 12.2106 22.7509 9.87394C22.7351 9.49433 22.7097 9.40193 22.7097 8.90193C22.7097 8.40193 22.6615 7.86066 22.3324 7.6632C22.1226 7.53734 21.26 8.2345 21.1954 8.27905C19.7558 9.27306 18.8309 10.1939 17.5635 11.4019M17.2793 19.5066C17.0386 20.8036 6.04645 19.8295 2.67952 19.5001C2.1387 19.4472 1.74441 18.9716 1.77897 18.4293C1.92305 16.1682 2.18777 10.4554 1.50405 6.5738C1.20975 4.90301 17.0414 5.40276 17.2793 6.5738C17.5172 7.74484 17.5635 17.9747 17.2793 19.5066Z"
            stroke="#0057B7" stroke-width="2" stroke-linecap="round"/>
      </g>
      <defs>
        <clipPath id="clip0_2101_7575">
          <rect width="24" height="24" fill="white" transform="translate(0.5 0.5)"/>
        </clipPath>
      </defs>
    </svg>


  </div>
</template>

<script>
export default {
  name: "NavVideoIcon",
  data() {
    return {
      isChatPageActive: false
    }
  },
  computed: {
    routePath() {
      let user = JSON.parse(localStorage.getItem('user'))
      return `/${user.nickName}/video`
    }
  }
}
</script>

<style scoped>

</style>