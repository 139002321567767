var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"mt-5"},[_c('div',{staticClass:"border-wrapper",class:{root: _vm.root}},[_c('div',[_c('div',{staticClass:"d-flex"},[_c('div',[(_vm.user && _vm.user.avatar)?_c('img',{staticClass:"item-image mr-5",attrs:{"loading":"lazy","src":_vm.user.avatar,"alt":"Ukrmusic"}}):_c('img',{staticClass:"item-image",attrs:{"src":require("../assets/img/default-avatar.png"),"alt":"Ukrmusic"}})]),_c('div',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"nickName mr-2"},[_vm._v(_vm._s(_vm.user.nickName))]),_c('div',{staticClass:"date open-sans"},[_vm._v(_vm._s(_vm.publishedAt))])]),_c('p',{staticClass:"comment mt-2"},[_vm._v(_vm._s(_vm.comment))])])])]),((_vm.tailIds && _vm.tailIds.length) || _vm.showReplies)?_c('ul',{staticClass:"replies"},_vm._l((_vm.repliesData),function(item){return _c('div',{key:item.id},[_c('recursive-comment',_vm._b({},'recursive-comment',{
          user: item.userCommenter,
          publishedAt: item.publishedAt,
          comment: item.message,
          tailIds: item.tailIds,
          id: item.id,
          postId: item.postId,
          root: false,
          commentsFor: _vm.commentsFor
        },false))],1)}),0):_vm._e(),(_vm.root)?_c('div',{staticClass:"footer-wrap mt-3",class:{root: _vm.root}},[_c('div',{staticClass:"show-more-button d-flex"},[_c('div',{staticClass:"connections-line"}),(_vm.tailIds.length && !_vm.checkPages)?_c('button',{staticClass:"btn-yellow mr-3",on:{"click":_vm.showMore}},[_vm._v("Show more")]):_vm._e(),_c('button',{staticClass:"btn-blue-filled py-1 px-2",on:{"click":_vm.openReplyInput}},[_vm._v("reply")])])]):_vm._e(),(_vm.root)?_c('div',{staticClass:"connection-line-wrap",class:{root: _vm.root, reply: _vm.isReply}}):_vm._e(),_c('div',{staticClass:"connection-line-with-parent",class:{root: _vm.root}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isReply),expression:"isReply"}],staticStyle:{"margin-left":"50px","position":"relative"}},[_c('div',{staticClass:"reply-wrapper d-flex mt-3"},[_c('div',[(_vm.myAvatar)?_c('img',{staticClass:"item-image mr-5",attrs:{"loading":"lazy","src":_vm.myAvatar,"alt":"Ukrmusic"}}):_c('img',{staticClass:"item-image",attrs:{"src":require("../assets/img/default-avatar.png"),"alt":"Ukrmusic"}})]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.reply),expression:"reply"}],ref:`replyTextArea${_vm.id}`,staticClass:"input w-full mr-2",attrs:{"name":"","rows":"2","placeholder":"Text"},domProps:{"value":(_vm.reply)},on:{"input":function($event){if($event.target.composing)return;_vm.reply=$event.target.value}}}),_vm._l((_vm.v$.reply.$errors),function(error){return _c('p',{key:error.$uid,staticClass:"error"},[_vm._v(" "+_vm._s(error.$message)+" ")])}),_c('button',{staticClass:"send-message",on:{"click":_vm.addComment}},[_c('v-icon',{attrs:{"size":"25","color":"#0057B7"}},[_vm._v("mdi-send-circle-outline")])],1)],2),_c('div',{staticClass:"connections-line reply"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }