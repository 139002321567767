import instance from "../api/instance";


export const subscribers = {
    namespaced: true,
    state: {
        subscribers: null
    },
    getters: {
        getSubscribers(state) {
            return state.subscribers
        },
    },
    mutations: {
        SET_SUBSCRIBERS(state, payload) {
            state.subscribers = payload
        }
    },
    actions: {
        async getSubscribersAction(context, uuid) {
            return (await instance.get(`/api/v1/subscribers/${uuid}`)).data.body.subscribeInfos
        },
        async addSubscribersAction(context, payload) {
            const uuid = payload.uuid
            const body = payload.body
            await instance.post(`/api/v1/subscribers/${uuid}`, body)
        },
        async unsubscribeAction(ctx, payload) {
            const ownerUuid = payload.ownerUuid
            const subscriberUuid = payload.subscriberUuid
            await instance.delete(`/api/v1/subscribers/${ownerUuid}/${subscriberUuid}`)
        }
    }
}