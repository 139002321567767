<template>
  <div class="mt-5 image" :style="{'background-image': `url(${image.imageUrl})`}">

  </div>
</template>

<script>
export default {
  name: "ShowPostImageComponent",
  props: {
    image: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.image {
  width: 100%;
  height: 450px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
}

@media only screen and (max-width: 600px) {
  .image {
    height: 270px;
  }
}
</style>