<template>
  <div class="chat-page-wrapper align-self-start" ref="top">
    <div class="mobile-header mb-8" v-if="!isMobileChaSelected">
      <div class="d-flex align-center">
        <h2 class="page-title">Chats</h2>
      </div>
    </div>
    <div class="d-flex w-full" v-if="device === 'desktop'">
      <div class="item-list-wrapper" v-if="chatList.length">
        <ChatListItem v-for="item in chatList" :key="item.id" :item="item" @openChat="openChat"/>
      </div>
      <div class="item-list-wrapper else" v-else>You don't have any chats yet</div>
      <div class="flex" v-if="isChatSelected">
        <ChatTemplate :messages="messages" v-if="showChat" :opponent="opponent" :isChatActive="isChatActive"
                      @getData="getMessages"/>
        <div class="mx-auto" v-else>
          <!--        <PreLoader/>-->
        </div>
      </div>
      <div class="non-selected-chat-block" v-else>
        <img src="../assets/img/empty-chat.png" alt="Ukrmusic">
      </div>
    </div>
    <div class="d-flex w-full" v-if="device === 'mobile'">
      <div class="item-list-wrapper" v-if="chatList.length && !isMobileChaSelected">
        <ChatListItem v-for="item in chatList" :key="item.id" :item="item" @openChat="openChat"/>
      </div>
      <div class="item-list-wrapper else" v-if="!chatList.length">You don't have any chats yet</div>
      <div class="w-full" v-if="isMobileChaSelected">
        <div class="flex">
          <ChatTemplate :messages="messages" v-if="showChat" :opponent="opponent" :isChatActive="isChatActive"
                        @getData="getMessages" @back="showChat = false; isMobileChaSelected = false"/>
          <div class="mx-auto" v-else>
            <!--        <PreLoader/>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChatListItem from "@/components/chat/ChatListItem";
import {mapActions} from "vuex";
import ChatTemplate from "@/components/chat/ChatTemplate";
// import PreLoader from "@/components/elements/Preloader";

export default {
  name: "ChatView",
  components: {ChatTemplate, ChatListItem},
  data() {
    return {
      chatList: [],
      showChat: false,
      opponent: null,
      messages: [],
      opponentUuid: '',
      isChatSelected: false,
      isChatActive: null,
      subscribePrice: null,
      isMobileChaSelected: false,
      device: 'desktop'
    }
  },
  methods: {
    ...mapActions({
      getAllChats: 'chat/getAllChats',
      getOpponent: 'chat/getOpponent'
    }),
    async getAllChatsRequest() {
      this.chatList = await this.getAllChats()
    },
    async openChat(item) {
      if (this.device === 'desktop') {
        this.isChatSelected = true
        this.showChat = false
        // console.log(item.opponent.uuid)
        this.opponentUuid = item.opponent.uuid
        this.opponent = item.opponent
        this.isChatActive = item.chatActive
        // this.subscribePrice = this.opponent.soldServices ? this.opponent.soldServices.subscribePrice.price : 5
        await this.getMessages()
        setTimeout(() => {
          this.showChat = true
        }, 0)
      }

      if (this.device === 'mobile') {
        this.isMobileChaSelected = true
        this.showChat = false
        // console.log(item.opponent.uuid)
        this.opponentUuid = item.opponent.uuid
        this.opponent = item.opponent
        this.isChatActive = item.chatActive
        // this.subscribePrice = this.opponent.soldServices ? this.opponent.soldServices.subscribePrice.price : 5
        await this.getMessages()
        setTimeout(() => {
          this.showChat = true
        }, 0)
      }
    },
    async getMessages() {
      this.messages = await this.getOpponent(this.opponentUuid)
    },
    deviceHandler() {
      const width = window.innerWidth
      this.device = width <= 800 ? 'mobile' : 'desktop'
    }
  },
  created() {
    this.getAllChatsRequest()
  },
  beforeDestroy() {
    removeEventListener('resize', this.deviceHandler)
  },
  mounted() {
    document.title = 'chat'
    window.addEventListener('resize', this.deviceHandler)
    this.deviceHandler()
  }
}
</script>

<style scoped>
.item-list-wrapper {
  max-height: 750px;
  overflow-y: auto;
  width: 300px;
  margin-right: 40px;
}

.item-list-wrapper.else {
  background: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #0057B7;
}

.non-selected-chat-block {
  height: 750px;
  width: 100%;
  flex: 1;
  background: #FFFFFF;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.desktop {
  display: block;
}

.mobile {
  display: none;
}

@media only screen and (max-width: 800px) {
  .item-list-wrapper {
    max-height: unset;
    overflow-y: unset;
    width: 100%;
    margin-right: 0;
    padding: 15px;
  }

  .mobile {
    display: block;
  }
}
</style>