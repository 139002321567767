<template>
  <div class="video-congratz-wrapper">
    <div class="mobile-header mb-8">
      <div class="d-flex align-center justify-space-between w-full">
        <h2 class="page-title video-title">Video congratulations</h2>
        <ButtonBlue class="mr-5" @clickEvent="$refs.EditPricePopup.openPopup()">
          <div class="uah-sign-wrap">₴</div>
          <span>Edit prices</span>
        </ButtonBlue>
      </div>
    </div>
    <ProfileNavBar :userUuid="$route.query.userUuid"/>
    <div class="desktop">
      <div class="d-flex justify-space-between align-center">
        <h1 class="page-title">Video congratulations</h1>
        <ButtonBlue class="mr-5" @clickEvent="$refs.EditPricePopup.openPopup()">
          <div class="uah-sign-wrap">₴</div>
          <span>Edit prices</span>
        </ButtonBlue>
      </div>
    </div>
    <div class="video-search w-full input bg-white">
      <input type="search" id="video-search" placeholder="Search" class="w-full">
      <div class="search-icon">
        <img src="../assets/svg/icons/search.svg" alt="Ukrmusic">
      </div>
    </div>
    <div class="video-order-list" v-if="videoOrders && videoOrders.length">
      <VideoCongratulationOrderComponent
          v-for="(item, index) in videoOrders"
          :item="item"
          :key="index"
          :index="index"
          :isArtist="true"
          identity="artist-video"
          @viewOffer="viewOfferHandler"
      />
    </div>
    <div class="video-order-list-else" v-else>
      <p class="open-sans mb-5">Here you can see orders from your subscribers for video congratulations. You can set the
        price and rules for your congratulations yourself, as well as accept and reject offers</p>
      <p class="open-sans">At the moment, you don’t have offers</p>
    </div>
    <EditPricePopup ref="EditPricePopup"/>
    <VideoOfferForArtistPopup ref="VideoOfferForArtistPopup" :item="dataForOffer"/>
  </div>
</template>

<script>
import ButtonBlue from "@/components/elements/ButtonBlue.vue";
import EditPricePopup from "@/components/popups/EditPricePopup.vue";
import ProfileNavBar from "@/components/profile/ProfileNavBar.vue";
import VideoCongratulationOrderComponent from "@/components/VideoCongratulationOrderComponent.vue";
import {mapActions} from "vuex";
import VideoOfferForArtistPopup from "@/components/popups/VideoOfferForArtistPopup.vue";

export default {
  name: "ProfileVideoCongratzView",
  components: {VideoOfferForArtistPopup, VideoCongratulationOrderComponent, ProfileNavBar, EditPricePopup, ButtonBlue},
  data() {
    return {
      videoOrders: null,
      dataForOffer: null
    }
  },
  methods: {
    ...mapActions({
      getVideoOrders: 'video/getVideoOrdersAction'
    }),
    async getVideoOrdersRequest() {
      let payload = {
        isArtistFlow: true
      }
      let res = await this.getVideoOrders(payload)
      this.videoOrders = res.reverse()
    },
    viewOfferHandler(data) {
      this.dataForOffer = data
      this.$refs.VideoOfferForArtistPopup.openPopup()
    }
  },
  created() {
    this.getVideoOrdersRequest()
  }
}
</script>

<style scoped>
.video-search {
  position: relative;
  padding: 15px 47px;
  margin-top: 40px;
}

.video-search input {
  color: var(--primary-blue-inputs);
}

.search-icon {
  height: 24px;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.video-order-list {
  background: #FFFFFF;
  padding: 20px;
  max-height: 550px;
  overflow-y: auto;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  margin-top: 40px;
}

.uah-sign-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border: 2px solid #0057B7;
  border-radius: 50%;
  margin-right: 10px;
}

.video-order-list-else {
  width: 100%;
  height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  padding: 0 120px;
  background: #FFFFFF;
  text-align: center;
}

.video-order-list-else p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #0057B7;
}

.video-title {
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 600px) {
  .video-order-list-else {
    padding: 20px 15px;
    height: 100%;
  }

  .video-congratz-wrapper {
    padding: 10px;
  }

  .video-search, .video-order-list, .video-order-list-else {
    margin-top: 20px;
  }

  .desktop {
    display: none;
  }
}
</style>