<template>
  <div data-app>
    <v-dialog
        v-model="dialog"
        max-width="846"
        scrollable
        persistent
    >
      <div class="upload-song-popup-wrapper" style="max-height: 90%; overflow-y: auto">
        <div class="upload-song-header">
          <h1 class="upload-song-header__title">Upload song</h1>
          <button @click="closePopup">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 5.5C11.4627 8.31416 9.11674 10.9681 5 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 5.5C6.86016 8.21022 8.65254 10.1669 15 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        <UploadSongComponent @isSongUploaded="isSongUploadedHandler" @songRemoved="makePostFlag = false"
                             ref="UploadSongComponent" @closePopup="closePopupAfterUpload" :showAttach="false" :identifier="'upload-popup'"/>
        <div class="make-post-switcher d-flex align-center mt-7" v-if="isFileUploaded">
          <span class="mr-3">Make post</span>
          <v-switch
              v-model="makePostFlag"
          ></v-switch>
        </div>
        <div class="upload-song__make-post" v-if="makePostFlag">
          <CreatePostTemplate :hideOptions="['song','publish']" ref="CreatePostTemplate" :disablePreloader="true"/>
        </div>
        <button class="btn-yellow w-full mt-5" v-if="isFileUploaded" @click="uploadHandler">Upload</button>
        <div class="btn-yellow btn-no-hover disabled w-full mt-5" v-else>Upload</div>

      </div>
      <div class="preloader" v-if="preloaderFlag">
        <PreLoader />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import UploadSongComponent from "@/components/UploadSongComponent";
import CreatePostTemplate from "@/components/post/CreatePostTemplate";
import PreLoader from "@/components/elements/Preloader";

export default {
  name: "UploadSongPopup",
  components: {PreLoader, CreatePostTemplate, UploadSongComponent},
  data() {
    return {
      dialog: false,
      makePostFlag: false,
      isFileUploaded: false,
      preloaderFlag: false,
    }
  },
  methods: {
    openPopup() {
      this.dialog = true
    },
    closePopup() {
      this.dialog = false
      this.$refs.UploadSongComponent.removeSong()
    },
    isSongUploadedHandler(data) {
      this.isFileUploaded = data
    },
    closePopupAfterUpload() {
      this.$emit('closePopupAfterUpload')
      this.closePopup()
    },
    async uploadHandler() {
      if (this.makePostFlag) {
        const isValidate = await this.$refs.UploadSongComponent.validateForm()
        if(isValidate) {
          let data = this.$refs.UploadSongComponent.dataForPost()
          this.$refs.CreatePostTemplate.handleSongDataFromParent(data)
          this.preloaderFlag = true
          await this.$refs.CreatePostTemplate.onSubmitAction()
          this.preloaderFlag = false
          this.$refs.UploadSongComponent.removeSong()
          this.closePopupAfterUpload()
        }

      } else {

        await this.$refs.UploadSongComponent.onSubmitAction()
      }

    }
  }
}
</script>

<style scoped>
.upload-song-popup-wrapper {
  position: relative;
  max-width: 846px;
  width: 100%;
  background: #FFFFFF;
  padding: 40px;
  margin: 0 auto;
  border-radius: 10px;
}

.upload-song-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.upload-song-header__title {
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 33px;
  color: var(--primary-blue);
}

.upload-song__make-post {
  border: 1px solid #D9D9D9;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.preloader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background: rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 600px){
  .upload-song-popup-wrapper {
    padding: 20px;
  }
}
</style>