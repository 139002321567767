<template>
  <div class="reset-wrapper">
    <h1 class="reset-title">Password reset</h1>
    <div class="w-full" v-if="form === 'email'">
      <ResetEmailForm ref="ResetEmailForm" @submitted="onSubmitEmailAction" v-if="!emailHasBeenSent"/>
      <div class="else" v-else>
        <div class="mt-10">We have sent a password reset link to {{ email }}. Click on link in a letter</div>
        <button class="btn-yellow w-full mt-10" @click="$router.push({name: 'login'})">Done</button>
      </div>
    </div>
    <div class="w-full" v-if="form === 'password'">
      <ResetPasswordForm ref="ResetPasswordForm" @submitted="onSubmitPasswordAction" v-if="!passwordHasBeenReset"/>
      <div class="else" v-else>
        <div class="mt-10">Password successfully changed</div>
        <button class="btn-yellow w-full mt-10" @click="$router.push({name: 'login'})">Sign in</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations} from "vuex";
import ResetEmailForm from "@/components/reset-password/ResetEmailForm";
import ResetPasswordForm from "@/components/reset-password/ResetPasswordForm";

export default {
  name: "ResetPasswordView",
  components: {ResetPasswordForm, ResetEmailForm},
  data() {
    return {
      form: '',
      emailHasBeenSent: false,
      email: '',
      passwordHasBeenReset: false,
    }
  },
  methods: {
    ...mapActions({
      resetPassword: 'authentication/resetPassword',
      resetPasswordConfirm: 'authentication/resetPasswordConfirm',
    }),
    ...mapMutations({
      setErrorMessage: 'error/SET_ERROR_MESSAGE',
      setDialog: 'error/SET_DIALOG'
    }),
    async onSubmitEmailAction(data) {
        try {
          const payload = {
            email: data
          }
          await this.resetPassword(payload)
          this.email = data
          this.$refs.ResetEmailForm.resetForm()
          this.emailHasBeenSent = true
        } catch (e) {
          this.setErrorMessage(e.response.data.errors[0].message)
          this.setDialog(true)
        }
    },
    async onSubmitPasswordAction(data) {
        try {
          const payload = {
            key: this.$route.query.token,
            body: {
              password: data
            }
          }
          await this.resetPasswordConfirm(payload)
          this.$refs.ResetPasswordForm.resetForm()
          this.passwordHasBeenReset = true
        } catch (e) {
          this.setErrorMessage(e.response.data.errors[0].message)
          this.setDialog(true)
        }
    },
  },
  mounted() {
    const token = this.$route.query.token
    if(token) {
      this.form = 'password'
    } else {
      this.form = 'email'
    }
  }
}
</script>

<style scoped>
.reset-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 150px 40px 0;
}

.reset-title {
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 75px;
  color: var(--primary-blue);
  margin: 0;
}

.else {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #0057B7
}

</style>