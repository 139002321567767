<template>
  <div class="playlist-secondary__item">
    <div class="secondary-playlist-cover" @click="routeToPlaylist">
      <img loading="lazy" :src="playList.playListCoverThumbnail" alt="Ukrmusic" class="secondary-playlist-cover-image">
    </div>
    <div class="header-info">
      <h2 class="playlist-name">{{ playList.playListName }}</h2>
      <div class="playlist-total-songs-and-likes">
        <div class="playlist-total-song">
          <img src="../assets/svg/music.svg" alt="Ukrmusic">
          {{ playList.totalSong }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlayListItemComponent",
  props: {
    playList: Object,
    forPage: String,
    userUuid: {
      type: String,
      required: true
    }
  },
  computed: {
    handleItemWidth() {
      return this.forPage === 'profile' ? 'calc(50% - 20px)' : 'calc(25%)'
    }
  },
  methods: {
    routeToPlaylist() {
      const nickName = this.$route.params.nickname
      this.$router.push({path: `/profile/${nickName}/playlist/${this.playList.id}`, query: {userUuid: this.userUuid}}).catch(() => {})
    }
  }
}
</script>

<style scoped>

.playlist-secondary__item {
  margin-bottom: 40px;
  width: calc(25%);
}

/*.playlist-secondary__item:last-child {*/
/*  margin-bottom: 0;*/
/*}*/

.header-info {
  padding: 0;
  display: block;
  width: 150px;
}

.header-info .playlist-name {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.secondary-playlist-cover {
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
  border-radius: 10px;
  cursor: pointer;
}

.secondary-playlist-cover-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.playlist-total-songs-and-likes {
  display: flex;
}

.playlist-total-songs-and-likes img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.playlist-total-song {
  display: flex;
  align-items: center;
  margin-right: 25px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: var(--primary-blue);
}

@media only screen and (max-width: 600px) {
  .playlist-secondary__item {
    width: calc(50% - 20px);
  }

  .secondary-playlist-cover {
    width: unset;
  }
}
</style>