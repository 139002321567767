<template>
  <div class="flex justify-center mx-auto w-7 h-7">
    <svg width="25" height="25" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"  v-if="$route.name === 'search'">
      <path d="M22.0395 12.4364C21.3781 18.6652 16.2535 21.6069 9.80644 22.1267C3.35942 22.6465 3.02842 15.5511 0.714301 12.4364C-1.59982 9.32163 2.69817 2.57353 8.48395 0.496506C14.2697 -1.58052 22.7009 6.20755 22.0395 12.4364Z" fill="#FFD700"/>
      <path d="M10.653 17.077C14.3916 17.077 17.4223 13.2002 17.4223 9.46161C17.4223 5.72307 14.3916 2.69238 10.653 2.69238C6.91448 2.69238 3.88379 6.56922 3.88379 10.3078C3.88379 14.0463 6.91448 17.077 10.653 17.077Z" stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M19.1153 18.7691L15.4346 15.0884" stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <svg width="25" height="25" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
      <path d="M11.5 19C15.9183 19 19.5 14.4183 19.5 10C19.5 5.58172 15.9183 2 11.5 2C7.08172 2 3.5 6.58172 3.5 11C3.5 15.4183 7.08172 19 11.5 19Z" stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M21.5 20.9999L17.15 16.6499" stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  </div>
</template>

<script>
export default {
  name: "NavSearchIcon",
  data() {
    return {
      isChatPageActive: false
    }
  },
  computed: {
    routePath() {
      let user = JSON.parse(localStorage.getItem('user'))
      return `/${user.nickName}/video`
    }
  }
}
</script>

<style scoped>

</style>