<template>
  <div class="signup-wrapper">
    <h1 class="signup-title">Sign up</h1>
    <div class="d-flex mt-10">
      <div class="already-have-account">Already have account?</div>
      <router-link to="/sign-in" class="signin">
        <div class="signin__text">Sign in</div>
        <div class="signin__underline"></div>
      </router-link>
    </div>
    <div class="register-form-wrapper mt-10 w-full">
      <form class="register-form w-full" @submit.prevent="onSubmitAction">
        <div style="position:relative;">
          <h2 class="input-title">Public name</h2>
          <input type="text" name="name" id="registration-name" class="input w-full mt-4" placeholder="Name"
                 v-model="publicName" :style="{borderColor: v$.publicName.$error ? 'red' : ''}">
          <span class="error" v-for="error of v$.publicName.$errors" :key="error.$uid">
              {{ error.$message }}
            </span>
        </div>
        <div class="mt-8" @click="$refs.DatePickerPopup.openPopup()" style="cursor:pointer; position:relative;">
          <h2 class="input-title">Date of birth</h2>
          <div id="registration-date" class="input w-full mt-4 bg-white" style="position:relative;"
               :style="{borderColor: v$.date.$error ? 'red' : ''}">
            <span>{{ date ? date : 'DD Month YYYY' }}</span>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"
                 class="calendar-icon">
              <path
                  d="M15.8335 3.83325C11.3038 3.93562 8.75612 3.96457 4.1668 3.83325C3.24633 3.83325 2.50013 4.57944 2.50013 5.49992C2.50013 5.49992 2.08352 13.8333 2.50013 17.1666C2.61429 18.08 3.39228 18.8284 4.1668 18.8333H15.8335C16.7539 18.8333 17.5001 18.0871 17.5001 17.1666V5.49992C17.5001 4.57944 16.7539 3.83325 15.8335 3.83325Z"
                  stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M13.3335 2.16675C13.3821 4.22564 13.3828 4.9343 13.3335 5.50008" stroke="#0057B7"
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                  d="M6.6665 2.16675C6.6665 2.16675 7.08317 3.83341 7.08317 4.25008C7.08317 4.66675 6.6665 5.50008 6.6665 5.50008"
                  stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M2.5 8.83336C7.68801 8.86979 15.4167 8.41675 17.5 8.83336" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </div>
          <span class="error" v-for="error of v$.date.$errors" :key="error.$uid">
              {{ error.$message }}
            </span>
        </div>
        <div class="separator"></div>
        <div style="position:relative;">
          <h2 class="input-title">Email</h2>
          <input type="text" name="email" id="registration-email" class="input w-full mt-4" placeholder="Email"
                 v-model="email" :style="{borderColor: v$.email.$error ? 'red' : ''}">
          <span class="error" v-for="error of v$.email.$errors" :key="error.$uid">
              {{ error.$message }}
            </span>
        </div>
        <div class="mt-8">
          <h2 class="input-title">Password</h2>
          <div style="position:relative; cursor:pointer;" class="mt-4">
            <input :type="togglePassword ? 'text' : 'password'" name="password" id="registration-password"
                   class="input w-full"
                   placeholder="Password"
                   v-model="password"
                   :style="{borderColor: v$.password.$error ? 'red' : ''}"
            >
            <div class="toggle-password" @click="togglePassword = !togglePassword">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1426_116035)">
                  <path
                      d="M1.6665 10.4166C1.6665 10.4166 5.99336 4.82958 10.4165 4.99997C14.4815 5.15657 19.1666 9.99998 19.1666 9.99998C19.1666 9.99998 15.8332 15.4166 9.99984 15.4166C4.1665 15.4166 1.6665 10.4166 1.6665 10.4166Z"
                      stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path
                      d="M10.4167 12.0833C11.7974 12.0833 12.9167 10.964 12.9167 9.58333C12.9167 8.20262 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 9.03595 12.0833 10.4167 12.0833Z"
                      stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_1426_116035">
                    <rect width="20" height="20" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div class="svg-cross-line" v-if="togglePassword" @click="togglePassword = !togglePassword"></div>
          </div>
          <div style="position:relative; cursor:pointer;" class="mt-4">
            <input :type="toggleResetPassword ? 'text' : 'password'" name="repeat-password"
                   id="registration-repeat-password" class="input w-full"
                   placeholder="Repeat Password"
                   v-model="repeatPassword"
                   :style="{borderColor: v$.repeatPassword.$error ? 'red' : ''}"
            >
            <div class="toggle-repeat-password" @click="toggleResetPassword = !toggleResetPassword">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1426_116035)">
                  <path
                      d="M1.6665 10.4166C1.6665 10.4166 5.99336 4.82958 10.4165 4.99997C14.4815 5.15657 19.1666 9.99998 19.1666 9.99998C19.1666 9.99998 15.8332 15.4166 9.99984 15.4166C4.1665 15.4166 1.6665 10.4166 1.6665 10.4166Z"
                      stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path
                      d="M10.4167 12.0833C11.7974 12.0833 12.9167 10.964 12.9167 9.58333C12.9167 8.20262 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 9.03595 12.0833 10.4167 12.0833Z"
                      stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_1426_116035">
                    <rect width="20" height="20" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div class="svg-cross-line" v-if="toggleResetPassword"
                 @click="toggleResetPassword = !toggleResetPassword"></div>
            <span class="error" v-for="error of v$.repeatPassword.$errors" :key="error.$uid">
              {{ error.$message }}
            </span>
          </div>
          <div class="mt-5 password-rules">
            <span :style="{color: hasMinimumLength ? 'green' : 'red', fontWeight: hasMinimumLength ? '600' : '400'}">8 or more characters</span>,
            <span :style="{color: hasLowercase ? 'green' : 'red', fontWeight: hasLowercase ? '600' : '400'}">Lowercase letter</span>,
            <span :style="{color: hasUppercase ? 'green' : 'red', fontWeight: hasUppercase ? '600' : '400'}">Uppercase letter</span>,
            <span :style="{color: hasSpecial ? 'green' : 'red', fontWeight: hasSpecial ? '600' : '400'}">Symbols</span>,
            <span :style="{color: hasNumber ? 'green' : 'red', fontWeight: hasNumber ? '600' : '400'}">Numbers</span>
          </div>
        </div>
        <div class="mt-4">
          <v-switch
              v-model="signAsArtist"
              label="Sign up like artist"
          ></v-switch>
        </div>
        <div class="mt-6">
          <div class="d-flex align-center registration-checkbox">
            <v-checkbox color="#0057B7" v-model="termsAgree"></v-checkbox>
            <div>I agree with <a href="/terms-and-conditions" style="text-decoration: underline" target="_blank">Terms & Conditions</a></div>
          </div>
          <div class="d-flex align-center registration-checkbox">
            <v-checkbox color="#0057B7" v-model="policyAgree"></v-checkbox>
            <div>I agree with <a href="/privacy-policy" style="text-decoration: underline" target="_blank">Privacy Policy</a></div>
          </div>
        </div>
        <div class="submit-button">
          <button class="btn-yellow w-full mt-5" v-if="agreedWithTermsAndPolicy && requireRulesForPassword"
                  style="line-height: 1.5">Sign up
          </button>
          <button disabled class="btn-yellow btn-no-hover disabled w-full mt-5" v-else>Sign up</button>
        </div>
      </form>
    </div>
    <DatePickerPopup ref="DatePickerPopup" @dateSubmitted="handleDate"/>
  </div>
</template>

<script>
import DatePickerPopup from "@/components/popups/DatePickerPopup";
import {useVuelidate} from "@vuelidate/core";
import {required, email, sameAs, helpers} from "@vuelidate/validators";
import {mapActions, mapMutations} from "vuex";


export default {
  name: "SignUpView",
  components: {DatePickerPopup},
  setup() {
    return {v$: useVuelidate({$lazy: true, $autoDirty: false})}
  },
  data() {
    return {
      publicName: '',
      email: '',
      date: '',
      password: '',
      repeatPassword: '',
      togglePassword: false,
      toggleResetPassword: false,
      signAsArtist: false,
      termsAgree: false,
      policyAgree: false,
      hasMinimumLength: false,
      hasNumber: false,
      hasLowercase: false,
      hasUppercase: false,
      hasSpecial: false,
      requireRulesForPassword: false
    }
  },
  validations() {
    return {
      publicName: {
        required: required
      },
      email: {
        required: required,
        email: email
      },
      date: {
        required: required,
      },
      password: {
        required: required,
      },
      repeatPassword: {
        required: helpers.withMessage('', required),
        sameAsPassword: helpers.withMessage('The passwords must match', sameAs(this.password)),
      },
    }
  },
  computed: {
    agreedWithTermsAndPolicy() {
      return this.termsAgree && this.policyAgree
    },
  },
  methods: {
    ...mapActions({
      signUpAction: 'registration/signUpAction',
      signInAction: 'authentication/login'
    }),
    ...mapMutations({
      setErrorMessage: 'error/SET_ERROR_MESSAGE',
      setDialog: 'error/SET_DIALOG'
    }),
    async onSubmitAction() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        return false
      } else {
        try {
          const registrationData = {
            email: this.email,
            password: this.password,
            publicName: this.publicName,
            dateOfBirth: this.date,
            isArtist: this.signAsArtist,
            signUpType: 'UKR_MUSIC'
          }
          await this.signUpAction(registrationData)
          const loginData = {
            email: this.email,
            password: this.password,
          }
          await this.signInAction(loginData)
          this.v$.$reset()
        } catch (e) {
          this.setErrorMessage(e.response.data.errors[0].message)
          this.setDialog(true)
        }

      }
    },
    handleDate(date) {
      this.date = date
    }
  },
  watch: {
    password(newVal) {
      this.hasMinimumLength = (newVal.length >= 8);
      this.hasNumber = /\d/.test(newVal);
      this.hasLowercase = /[a-z]/.test(newVal);
      this.hasUppercase = /[A-Z]/.test(newVal);
      this.hasSpecial = /[#?!@$%/^()}{><.&|:;`',*~"-]/.test(newVal);
      if (this.hasMinimumLength && this.hasNumber && this.hasLowercase && this.hasUppercase && this.hasSpecial) {
        this.requireRulesForPassword = true;
      } else {
        this.requireRulesForPassword = false;
      }
    },
  },
  mounted() {
    document.title = 'Sign up'
  }
}
</script>

<style scoped>
.signup-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 150px 40px 0;
}

.signup-title {
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 75px;
  color: var(--primary-blue);
  margin: 0;
}

.already-have-account {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-right: 10px;
}

.signin {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #006EE9;
  cursor: pointer;
}

.signin__text {
  margin-bottom: 5px;
}

.signin__underline {
  width: 50px;
  height: 2px;
  background: #FFD60A;
}

.calendar-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.separator {
  width: 100%;
  height: 1px;
  background: #D9D9D9;
  margin: 30px 0;
}

.toggle-password, .toggle-repeat-password {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  line-height: 0;
}

.svg-cross-line {
  position: absolute;
  top: 11px;
  right: 23px;
  height: 21px;
  width: 2px;
  background: #0057B7;
  transform: rotate(56deg);
  cursor: pointer;
}

.password-rules {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  opacity: 0.5;
}

.error {
  position: absolute;
  bottom: -26px;
  left: 0;
}

.registration-checkbox {
  color: var(--primary-blue);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}

@media only screen and (max-width: 600px) {
  .signup-wrapper {
    padding: 100px 15px 0;
  }
}
</style>