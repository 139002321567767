<template>
  <div data-app style="z-index: 100000">
    <v-dialog v-model="dialog">
      <div class="create-content-wrapper" style="max-height: calc(100vh - 100px); overflow-y: auto">
        <div class="content-header">
          <button class="ml-auto" @click="closePopup">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 5.5C11.4627 8.31416 9.11674 10.9681 5 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 5.5C6.86016 8.21022 8.65254 10.1669 15 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        <div class="content-body mt-5">
          {{ errorMessage }}
        </div>
        <button class="btn-yellow w-full mt-5" @click="closePopup">Close</button>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "ErrorPopup",
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      dialog: 'error/getDialog',
      errorMessage: 'error/getErrorMessage'
    })
  },
  methods: {
    ...mapMutations({
      setErrorMessage: 'error/SET_ERROR_MESSAGE',
      setDialog: 'error/SET_DIALOG'
    }),
    closePopup() {
      this.setDialog(false)
      this.setErrorMessage('')
    },
  },
}
</script>

<style scoped>
.create-content-wrapper {
  position: relative;
  max-width: 400px;
  width: 100%;
  background: #FFFFFF;
  padding: 40px;
  margin: 0 auto;
  border-radius: 10px;
}
.content-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.content-body {
  text-align: center;
}
</style>