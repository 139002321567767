import instance from "../api/instance";


export const content = {
    namespaced: true,
    state: {
        content: null
    },
    getters: {
        getContent(state) {
            return state.content
        }
    },
    mutations: {
        SET_CONTENT(state, payload) {
            state.content = payload
        }
    },
    actions: {
        async createPost(context, payload) {
            let uuid = payload.uuid
            let body = payload.body
            await instance.post(`/api/v1/contents/${uuid}`, body)
        },
        async getPostListAction(context, params) {
            return (await instance.get(`/api/v1/contents`, params)).data.body
        },
        async getArtistPostListAction(context, payload) {
            let params = payload.params
            let uuid = payload.uuid
            return (await instance.get(`/api/v1/contents/${uuid}`, params)).data.body
        },
        async makeVote(context, payload) {
            let pollId = payload.pollId
            let body = payload.body
            let res = await instance.post(`/api/v1/polls/${pollId}`, body)
            return res.data.body
        },
        async postLikeAction(context, contentId) {
            return await instance.post(`/api/v1/contents/likes/${contentId}`)
        },
        async postDeleteLikeAction(context, contentId) {
            return await instance.delete(`/api/v1/contents/likes/${contentId}`)
        }
    }
}