import {userService} from '@/services';
import router from '../router'
import LocalStorageService from "@/services/LocalStorageService";
import instance from "@/api/instance";
// LocalstorageService

const user = JSON.parse(localStorage.getItem('user'));

// const initialState = user
//     ? {status: {loggedIn: true}}
//     : {status: {}, user: null, error: null};

export const authentication = {
    namespaced: true,
    state: {
        status: {
            loggedIn: false
        },
        user: null,
        error: null,
        signInPopupDialog: false
    },
    getters: {
        isLogged(state) {
            return state.status.loggedIn
        },
        getUser(state) {
            return state.user
        },
        getSignInPopupDialog(state) {
            return state.signInPopupDialog
        }
    },
    actions: {
        login({dispatch, commit}, {email, password}) {
            if (!user) {
                router.push({name: 'login'}).catch(() => {
                });
            }
            LocalStorageService.clearToken()

            // commit('loginRequest', {username});
            userService.login(email, password)
                .then(
                    user => {
                        commit('loginSuccess', user)
                        router.push({name: 'home'}).catch(() => {
                        });
                    },
                    error => {
                        dispatch('error/invokeErrorPopup', error.response.data.errors[0].message, {root: true});
                    }
                );
        },
        clearState({commit}) {
            commit('logout');
        },
        logout({commit}) {
            userService.logout();
            commit('logout');
            // router.push({name: 'Login'}).catch(() => {});
        },
        async getServerTime() {
            await instance.get(`/api/v1/getServerTime`)
        },
        async resetPassword(ctx, body) {
            await instance.post('api/v1/login/password/reset', body)
        },
        async resetPasswordConfirm(ctx, {body, key}) {
            await instance.post(`api/v1/login/password/reset/confirm/${key}`, body)
        }
    },
    mutations: {
        loginRequest(state, user) {
            state.status = {loggedIn: true};
            state.user = user;
        },
        loginSuccess(state, user) {
            state.status = {loggedIn: true};
            state.user = user;
        },
        loginFailure(state) {
            state.status = {loggedIn: false};
            state.user = null;
        },
        logout(state) {
            state.status = {loggedIn: false};
            state.user = null;
        },
        openSignInPopup(state) {
            state.signInPopupDialog = true
        },
        closeSignInPopup(state) {
            state.signInPopupDialog = false
        },
    },

}
