<template>
  <div data-app>
    <v-dialog
        v-model="dialog"
        max-width="846"
        persistent
        v-if="offerData"
    >
      <div class="video-order-popup-wrapper" style="max-height: calc(100vh - 100px); overflow-y: auto">
        <div class="video-order-header">
          <h1 class="video-order-header__title">View offer</h1>
          <button @click="closePopup">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 5.5C11.4627 8.31416 9.11674 10.9681 5 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 5.5C6.86016 8.21022 8.65254 10.1669 15 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        <div class="d-flex justify-space-between align-center mt-10">
          <div class="d-flex">
            <img loading="lazy" :src="offerData.user.avatar" alt="Ukrmusic" v-if="offerData && offerData.user.avatar"
                 class="item-image mr-5">
            <img src="../../assets/img/default-avatar.png" alt="Ukrmusic" class="item-image mr-5" v-else>
            <div class="d-flex flex-column justify-space-between">
              <div class="nickName">{{ offerData.user.nickName }}</div>
              <div class="date open-sans">{{ offerData.orderedAt }}</div>
            </div>
          </div>
          <div class="d-flex align-start" style="height: 60px">
            <div class="done" v-if="offerData.status === 'COMPLETE'">Done!</div>
            <div class="sent" v-else-if="offerData.status === 'INIT'">Sent</div>
            <div class="declined" v-else>Declined</div>
          </div>
        </div>
        <div class="user-desire mt-5">
          <h2 class="input-title">User desire</h2>
          <textarea name="" id="user-desire" rows="3" class="input w-full mt-4"
                    :value="offerData.fanDesire" placeholder="Text" readonly></textarea>
        </div>
<!--        <div class="mt-10" v-if="offerData.status === 'INIT'">-->
<!--          <ButtonBlue>Cancel</ButtonBlue>-->
<!--        </div>-->
        <div class="show-video-wrapper mt-10" @click="playButtonFlag = !playButtonFlag" v-if="offerData.status === 'COMPLETE'" style="cursor: pointer">
          <div class="play-button">
            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" :style="{opacity: playButtonFlag ? 0 : 1}">
              <path d="M7.21361 10.1014C13.4056 3.01894 17.4365 0 31.9999 0C46.5632 0 50.7863 5.137 55.7302 13.0275C60.3137 20.3428 60.2148 26.2909 59.837 34.9733C59.5159 42.3536 55.3706 50.3963 48.8549 55.0999C33.019 66.5316 4.73582 56.8365 0.728896 37.6513C-1.21109 28.3626 0.645402 17.6141 7.21361 10.1014Z" fill="#0057B7"/>
              <path d="M38.9233 28.3908C38.9233 26.1972 25.1777 17.4166 25.1738 18.1487C25.1699 18.8808 25.5172 23.4075 25.5152 29.1213C25.5145 31.1207 25.1709 37.1669 25.1738 37.8995C25.1768 38.6321 38.9233 30.5843 38.9233 28.3908Z" fill="white" stroke="white" stroke-width="2" stroke-linecap="round"/>
            </svg>
          </div>
          <video class="my-video" :src="offerData.videoUrl" ref="videoRef" type="video/mp4"></video>
        </div>
        <a :href="offerData.videoUrl" class="btn-yellow w-full mt-10" download="video-congratulation" style="color: #FFFFFF" v-if="offerData.status === 'COMPLETE'">Download</a>
      </div>
    </v-dialog>
  </div>
</template>

<script>
// import ButtonBlue from "@/components/elements/ButtonBlue.vue";

export default {
  name: "VideoOfferForUserPopup",
  components: {},
  props: {
    offerData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dialog: false,
      status: '',
      errorMessage: '',
      playButtonFlag: false
    }
  },
  computed: {},
  methods: {
    openPopup() {
      this.dialog = true
    },
    closePopup() {
      if(this.offerData.status === 'COMPLETE') {
        this.resetVideo()
      }
      this.dialog = false
    },
    resetVideo() {
      this.playButtonFlag = false
      this.$refs.videoRef.pause()
      this.$refs.videoRef.currentTime = 0
    }
  },
  watch: {
    playButtonFlag(newVal) {
      if(newVal) {
        this.$refs.videoRef.play()
      } else {
        this.$refs.videoRef.pause()
      }
    }
  }
}
</script>

<style scoped>
.video-order-popup-wrapper {
  position: relative;
  max-width: 846px;
  width: 100%;
  background: #FFFFFF;
  padding: 40px;
  margin: 0 auto;
  border-radius: 10px;
}

.video-order-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.video-order-header__title {
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 33px;
  color: var(--primary-blue);
}

.item-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}



.nickName {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-blue);
}

.date {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: var(--primary-blue);
}

.done, .sent, .declined {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #40C800;
}

.sent {
  color: #FFB800;
}

.declined {
  color: #FF0000;
}

.show-video-wrapper {
  position: relative;
}

.my-video {
  width: 100%;
  height: 430px;
  object-fit: cover;
  border-radius: 10px;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 600px) {
  .video-order-popup-wrapper {
    padding: 20px 15px;
  }
}
</style>