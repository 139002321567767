<template>
  <textarea
      :name="name"
      :id="id"
      @input="$emit('input', $event.target.value); resize($event)"
      class="w-full"
      :class="setBorder"
      :value="value"
      :style="{'border-radius': borderRadius ? '10px' : '0'}"
  ></textarea>
</template>

<script>
export default {
  name: "FormTextarea",
  props: {
    name: {
      required: true,
      type: String
    },
    id: {
      required: true,
      type: String
    },
    value: {
      required: false
    },
    border: {
      type: String,
      default: ''
    },
    borderRadius: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    setBorder() {
      return this.border === 'full' ? 'border-full' : this.border === 'bottom' ? 'border-bottom' : 'none'
    }
  },
  methods: {
    resize(e) {
      e.target.style.height = 'auto'
      e.target.style.height = `${e.target.scrollHeight}px`
    }
  }
}
</script>

<style>
textarea {
  overflow: hidden;
}

.border-full {
  border: 1px solid #D9D9D9;
}

.border-bottom {
  border-bottom: 1px solid #D9D9D9;
}

</style>