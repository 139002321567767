<template>
  <div class="privacy-policy-wrapper">
    <h2 class="page-title">ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ UKRMUSIC</h2>
    <div class="separator mt-5"></div>
    <h3 class="mt-10">Зміст:</h3>
    <ul class="page-content">
      <li class="mt-5" @click="scrollTo(1)"><span class="list-number">1.</span>Загальні Положення</li>
      <li @click="scrollTo(2)"><span class="list-number">2.</span>Предмет політики конфіденційності</li>
      <li @click="scrollTo(3)"><span class="list-number">3.</span>Мета збору та обробки персональних даних</li>
      <li @click="scrollTo(4)"><span class="list-number">4.</span>Категоріі зібраних даних</li>
      <li @click="scrollTo(5)"><span class="list-number">5.</span>Способи і терміни обробки персональних даних</li>
      <li @click="scrollTo(6)"><span class="list-number">6.</span>Права користувачів</li>
      <li @click="scrollTo(7)"><span class="list-number">7.</span>Політика щодо файлів cookie</li>
      <li @click="scrollTo(8)"><span class="list-number">8.</span>Безпека персональних даних</li>
      <li @click="scrollTo(9)"><span class="list-number">9.</span>Додаткова інформація про персональні дані користувача</li>
      <li @click="scrollTo(10)"><span class="list-number">10.</span>Додаткові умови</li>
    </ul>
    <div class="separator mt-10"></div>
    <h3 class="mt-10" ref="1">1. Загальні Положення</h3>
    <div class="mt-6">
      <p class="mb-5">
        Дану угоду про конфіденційність (далі - Умови/Політика) розроблено відповідно до вимог Закону України «Про
        захист персональних даних» та інших нормативних актів українського законодавства, що регламентують правові
        відносини, пов'язані зі збором, обробкою, зберіганням персональних даних користувачами платформи
        <a href="https://ukrmusic.com" target="_blank" class="link">www.ukrmusic.com</a> (далі - Ви/Користувач).
      </p>
      <p class="mb-5">
        Товариство з обмеженою відповідальністю «ДЖУКІ» («Джукі/Компанія») створило онлайн-платформу для музичних
        артистів та їх фанів для подальшої взаємодії між ними (надалі – «Платформа/Сайт»). Доступ до Платформи
        здійснюється через веб-сайт <a href="https://ukrmusic.com" target="_blank" class="link">www.ukrmusic.com</a>
      </p>
      <p class="mb-5">
        Будь ласка, прочитайте дуже уважно ці Умови разом з її Додатками перед тим, як прийняти ці положення.
      </p>
      <p class="mb-5">
        Здійснивши реєстрацію на Платформі шляхом надання необхідної для реєстрації інформації, у тому числі
        персональної, та вираження своєї згоди з умовами та положеннями запропонованих до Вашого обов'язкового
        ознайомлення документів, зазначених далі в реченні, підтверджуючи її встановленням позначки «галочка» та
        натискання кнопки «Зареєструватися», Ви підтверджуєте, що Ви прочитали ці Умови та Додатки.
      </p>
      <p>
        Якщо Ви у будь-який момент не погоджуєтесь з цими Умовами, в такому випадку Ви не маєте права користуватися
        Платформою та негайно маєте залишити Платформу та видалити свій Акаунт (як визначено нижче).
      </p>
    </div>
    <div class="separator mt-6"></div>
    <h3 class="mt-10" ref="2">2. Предмет політики конфіденційності</h3>
    <div class="mt-6">
      <p class="mt-5">
        Користуючись Сайтом, Користувач підтверджує, що дає згоду Компанії уповноваженим ним особам, на обробку
        персональних даних, а також що є повнолітньою особою.
      </p>
      <p class="mt-5">
        Користувач дає свою згоду адміністрації Сайту на обробку отриманої інформації, включаючи збір, систематизацію,
        накопичення, зберігання, уточнення (оновлення, виправлення), використання, поширення, знеособлення, блокування,
        знищення персональних даних, зазначених в анкеті, з метою заходів, спрямованих на формування бази даних
        учасників, складання статистичної звітності, проведення маркетингових досліджень, а також здійснення взаємодії з
        користувачем, в тому числі по мережах електрозв'язку, включаючи надсилання SMS-повідомлень і електронних листів,
        до відкликання такої згоди.
      </p>
      <p class="mt-5">
        Користувач підтверджує свою згоду з тим, що адміністрація Сайту має право взаємодіяти з Користувачем шляхом
        здійснення прямих контактів за допомогою різних засобів зв'язку, включаючи, але не обмежуючись: поштова
        розсилка, електронна пошта, мережа Інтернет і ін.
      </p>
      <div class="mt-5">
        Персональні дані, дозволені до обробки в рамках цієї Політики конфіденційності, включають в себе, але не
        обмежуються наступною інформацією:
        <ol>
          <li>ПІБ Користувача;</li>
          <li>контактний телефон Користувача;</li>
          <li>адреса електронної пошти (e-mail);</li>
        </ol>
      </div>
    </div>
    <div class="separator mt-4"></div>
    <h3 class="mt-10" ref="3">3. Мета збору та обробки персональних даних</h3>
    <div class="mt-6">
      <p>
        Персональну інформацію Користувача Компанія використовує для таких цілей:
      </p>
      <ol>
        <li>ідентифікації Користувача, зареєстрованого на Сайті;</li>
        <li>надання Користувачеві ефективної клієнтської підтримки;</li>
        <li>надання Користувачеві послуг Платформи;</li>
        <li>зв'язку з Користувачем, в тому числі напрямок повідомлень, запитів та інформації, що стосуються надання
          послуг, а також обробки запитів і заявок від Користувача;
        </li>
        <li>поліпшення якості послуг, зручності їх використання, розробка нових сервісів і послуг;</li>
        <li>проведення статистичних та інших досліджень на основі знеособлених даних.</li>
      </ol>
    </div>
    <div class="separator mt-6"></div>
    <h3 class="mt-10" ref="4">4. Категоріі зібраних даних</h3>
    <div class="mt-6">
      <p class="mb-5">
        Категоріі персональних даних, збір яких здійснює сайт (самостійно або за посередництвом третіх осіб), включають:
        файли «cookie» і дані про використання мережевих ресурсів.
      </p>
      <p class="mb-5">
        Персональні дані можуть надаватися Користувачем самостійно або їх збір може автоматично здійснювати сайт при
        його використанні.
      </p>
      <p class="mb-5">
        Якщо не вказано інше, сайт <a href="https://ukrmusic.com" target="_blank" class="link">www.ukrmusic.com</a>
        використовує файли «cookie» (або інші засоби відстеження) для
        ідентифікації користувачів і запам'ятовування встановлених ними глобальних параметрів налаштування виключно в
        цілях надання послуги, що вимагається Користувачеві.
      </p>
      <p>
        Користувачі несуть відповідальність за gерсональні дані третіх осіб, які вони отримують, публікують або надають
        іншим особам, використовуючи сайт, і підтверджують, що у них є згода третьої особи на надання даних власнику.
      </p>
    </div>
    <div class="separator mt-6"></div>
    <h3 class="mt-10" ref="5">5. Способи і терміни обробки персональних даних</h3>
    <div class="mt-6">
      <p class="mb-5">
        Обробка персональних даних Користувача здійснюється без обмеження терміну, будь-яким законним способом, в тому
        числі в інформаційних системах персональних даних з використанням засобів автоматизації або без використання
        таких засобів.
      </p>
      <p class="mb-5">
        Персональні дані зберігаються терміном не більше, ніж це необхідно відповідно до мети їх обробки. Після того, як
        суб'єкт персональних даних перестав бути користувачем Сайту шляхом видалення свого облікового запису на Сайті,
        його персональні дані також автоматично видаляються.
      </p>
      <p class="mb-5">
        Компанія вживає всіх необхідних заходів для захисту даних від несанкціонованого доступу, зміни, розкриття чи
        знищення. До цих заходів належать, зокрема, внутрішня перевірка процесів збору, зберігання та обробки даних і
        заходів безпеки, включаючи відповідне шифрування і заходи щодо забезпечення фізичної безпеки даних для
        запобігання неавторизованого доступу до систем, в яких ми зберігаємо особисті дані.
      </p>
    </div>
    <div class="separator mt-6"></div>
    <h3 class="mt-10" ref="6">6. Права користувачів</h3>
    <div class="mt-6">
      <div>
        <p>Користувачі мають право:</p>
        <ol>
          <li>дізнатися, чи були збережені їх персональні дані</li>
          <li>звертатися до адміністрації Сайту, щоб дізнатися їх зміст і походження</li>
          <li>перевірити їх достовірність, або попросити їх доповнити</li>
          <li>видалити, оновити або виправити персональні дані</li>
          <li>перетворити в формат, що забезпечує анонімність</li>
          <li>блокувати будь-які дані, що зберігаються в порушення закону</li>
          <li>виступити проти їх обробки за всіма без винятку законних підставах.</li>
        </ol>
        <p class="mt-5">
          Відповідні запити слід направляти адміністрації Сайту за адресою, вказаною в контактних відомостях на сайті.
        </p>
      </div>
    </div>
    <div class="separator mt-6"></div>
    <h3 class="mt-10" ref="7">7. Політика щодо файлів cookie</h3>
    <div class="mt-6">
      <p class="mb-5">
        Сайт збирає інформацію про відвідування Вами наших веб-сайтів з метою отримання статистики відвідувань і
        ефективності їх використання, формування персонального підходу і адаптації до інтересів кожного користувача. Ми
        робимо це за допомогою різних технологій, одна з яких - cookie-файли. Це дані, які веб-сайт може відправити
        вашому браузеру, і вони будуть збережені на вашому комп'ютері для подальшої ідентифікації його веб-сайтом.
      </p>
      <p>
        Cookie-файли використовуються для оцінки ефективності веб-сайтів (наприклад, для вимірювання кількості
        відвідувачів і тривалості їх перебування на сайті), виявлення найбільш цікавих і відвідуваних тем і сторінок, а
        також для забезпечення зручності навігації та користування сайтом, без збору будь-якої персональної інформації.
        Також cookie-файли можуть бути використані для формування персонального підходу на основі вже наявного досвіду
        взаємодії користувача з сайтом і його перевагами. З плином часу ця інформація дозволяє поліпшити досвід.
      </p>
    </div>
    <div class="separator mt-6"></div>
    <h3 class="mt-10" ref="8">8. Безпека персональних даних</h3>
    <div class="mt-6">
      <p class="mb-5">
        Компанія приймає необхідні організаційні та технічні заходи для захисту персональної інформації Користувача від
        неправомірного або випадкового доступу, знищення, перекручення, блокування, копіювання, поширення, а також від
        інших неправомірних дій третіх осіб.
      </p>
      <p class="mb-5">
        Дана Політика конфіденційності поширюється тільки на Сайті. Якщо за посиланнями, розміщеними на сайті
        останнього, Користувач зайде на ресурси третіх осіб, Компанія за його дії відповідальності не несе.
      </p>
      <p class="mb-5">
        Персональні дані Користувача можуть бути використані в юридичних цілях в суді або на етапах, що ведуть до
        можливого звернення до суду в зв'язку з тим, що Сайт або послуги використовувалися неналежним чином.
      </p>
      <p>
        Користувач обізнаний про те, що адміністарції Сайту може бути змушена повідомити персональні дані на вимогу
        органів державної влади.
      </p>
    </div>
    <div class="separator mt-6"></div>
    <h3 class="mt-10" ref="9">9. Додаткова інформація про персональні дані користувача</h3>
    <div class="mt-6">
      <p class="mb-5">
        Крім інформації, що міститься в цих правилах забезпечення конфіденційності персональних даних, сайт може
        надавати Користувачу за запитом додаткову і контекстуальну інформацію, що стосується конкретних послуг і
        сервісів або збору і обробки персональних даних.
      </p>
      <p>
        З метою забезпечення роботи сайту та його технічного обслуговування, сайт і будь-які сторонні сервіси можуть
        вести файли, в яких реєструється взаємодія сайту з Користувачами (системні журнали), або використовувати з цією
        метою інші персональні дані (зокрема, IP-адреса).
      </p>
    </div>
    <div class="separator mt-6"></div>
    <h3 class="mt-10" ref="10">10. Додаткові умови</h3>
    <div class="mt-6">
      <p class="mb-5">
        Адміністрація Сайту має право вносити зміни в справжню Політику конфіденційності без згоди Користувача.
      </p>
      <p class="mb-5">
        Нова Політика конфіденційності вступає в силу з моменту її розміщення на Сайті інтернет-магазину, якщо інше не
        передбачено новою редакцією Політики конфіденційності.
      </p>
      <p>
        У разі незгоди з будь-якими змінами, внесеними до Правил, Користувач повинен припинити користуватися Сайтом, а
        також вимагати, щоб адміністрація Сайту видалила його персональні дані.
      </p>
    </div>
    <div class="separator mt-6"></div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  methods: {
    scrollTo(number) {
      this.$refs[number].scrollIntoView({behavior: "smooth"})
    }
  }
}
</script>

<style scoped>
div {
  line-height: 24px;
}

.privacy-policy-wrapper {
  background-color: #FFFFFF;
  position: relative;
  padding: 40px;
}

h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: var(--primary-blue);
}

p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.list-number {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #0057B7;
  margin-right: 8px;
}

.page-content li {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  width: 100%;
  background-color: #F9F9F9;
  border-radius: 10px;
  padding: 12px;
  margin-bottom: 8px;
  cursor: pointer;
}

.link {
  text-decoration: underline;
  color: var(--primary-blue);
}

ol li {
  list-style: disc;
  margin-left: 25px;
  line-height: 24px;
}
</style>