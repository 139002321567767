<template>
  <div data-app style="z-index: 100000">
    <v-dialog v-model="dialog">
      <div class="create-content-wrapper" style="max-height: calc(100vh - 100px); overflow-y: auto">
        <div class="content-header">
          <h2 class="popup-title">Send image</h2>
<!--          <button @click="closePopup" class="close-button">-->
<!--            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M15 5.5C11.4627 8.31416 9.11674 10.9681 5 15.5" stroke="#0057B7" stroke-width="2"-->
<!--                    stroke-linecap="round" stroke-linejoin="round"/>-->
<!--              <path d="M5 5.5C6.86016 8.21022 8.65254 10.1669 15 15.5" stroke="#0057B7" stroke-width="2"-->
<!--                    stroke-linecap="round" stroke-linejoin="round"/>-->
<!--            </svg>-->
<!--          </button>-->
        </div>
        <div class="content-body">
          <img v-if="src" :src="getSrc" alt="Ukrmuusic" class="preview-image">
        </div>
        <div class="content-button w-full d-flex">
          <ButtonBlue class="flex mr-2" @clickEvent="closePopup">Cancel</ButtonBlue>
          <button class="btn-yellow ml-auto flex" @click="$emit('sendImage')">Send</button>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import ButtonBlue from "@/components/elements/ButtonBlue";
export default {
  name: "ImagePreviewPopup",
  components: {ButtonBlue},
  props: {
    src: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    getSrc() {
      return this.src[0]?.filePreview
    }
  },
  methods: {
    openPopup() {
      this.dialog = true
    },
    closePopup() {
      this.dialog = false
      this.$emit('closePopup')
    }
  }
}
</script>

<style scoped>
.create-content-wrapper {
  position: relative;
  max-width: 400px;
  width: 100%;
  background: #FFFFFF;
  padding: 40px;
  margin: 0 auto;
  border-radius: 10px;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
}

.content-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.content-header__title {
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 33px;
  color: var(--primary-blue);
}

.content-body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.content-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.preview-image {
  max-width: 300px;
  max-height: 200px;
  object-fit: contain;
  border-radius: 10px;
}

.content-button {
  margin-top: 20px;
}

.popup-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #0057B7;
}
</style>