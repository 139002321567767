import instance from "../api/instance";
// import router from '../router'


export const profile = {
    namespaced: true,
    state: {
        profile: {},
        instagram: null,
        facebook: null,
        twitter: null,
        tiktok: null,
        youtube: null,
        products: null
    },
    getters: {
        getProfile(state) {
            return state.profile
        },
        getInstagram(state) {
            return state.instagram
        },
        getFacebook(state) {
            return state.facebook
        },
        getTwitter(state) {
            return state.twitter
        },
        getTiktok(state) {
            return state.tiktok
        },
        getYoutube(state) {
            return state.youtube
        },
        getProducts(state) {
            return state.products
        }
    },
    mutations: {
        SET_PROFILE(state, payload) {
            state.profile = payload
        },
        SET_SOCIAL_LINKS(state, payload) {
            state.instagram = payload.socialLinks.filter(i => i.type === 'INSTAGRAM')[0]
            state.facebook = payload.socialLinks.filter(i => i.type === 'FACEBOOK')[0]
            state.twitter = payload.socialLinks.filter(i => i.type === 'TWITTER')[0]
            state.tiktok = payload.socialLinks.filter(i => i.type === 'TIK_TOK')[0]
            state.youtube = payload.socialLinks.filter(i => i.type === 'YOUTUBE')[0]
        },
        SET_PRODUCTS(state, payload) {
            state.products = payload
        }
    },
    actions: {
        async getProfile(context, uuid) {
            const profile = (await instance.get(`/api/v1/profiles/${uuid}`)).data.body
            context.commit('SET_PROFILE', profile)
            context.commit('SET_SOCIAL_LINKS', profile)
            context.dispatch('getProducts', profile.uuid)
            return profile
        },
        async updateProfile(context, payload) {
            const res = (await instance.patch(`/api/v1/profiles/${payload.uuid}`, payload.body)).data.body
            context.commit('SET_PROFILE', res)
            context.commit('SET_SOCIAL_LINKS', res)
        },
        async getProducts(context, payload) {
            const res = (await instance.get(`/api/v1/products/${payload}`)).data.body
            context.commit('SET_PRODUCTS', res)
        }
    }
}