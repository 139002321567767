import axios from "axios";
import LocalStorageService from "../services/LocalStorageService";
import router from "../router";

const localStorageService = LocalStorageService.getService();

const instance = axios.create({
    baseURL: process.env.VUE_APP_API,
    headers: {
        "Content-Type": "application/json",
    },
});

instance.interceptors.request.use(
    (config) => {
        const token = localStorageService.getAccessToken();
        if (token) {
            config.headers['Authorization'] = token;
        }
        config.headers['Cache-Control'] = 'no-cache, no-store, must-revalidate';
        config.headers['Pragma'] = 'no-cache';
        config.headers['Expires'] = '0';
        // config.headers['Content-Type'] = 'application/json';
        config.headers['accept'] = 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8';
        // config.headers['Content-Type'] = 'application/json';
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


// let flag = true

instance.interceptors.response.use(
    async (res) => {
        let fe_version = res.headers['x-app-hash']
            // && res.config.method === 'get'
        if (fe_version !== localStorage.getItem('fe-version')) {
            if(localStorage.getItem('fe-version')) {
                localStorage.removeItem('fe-version')
                // window.location.reload()
            }
            localStorage.setItem('fe-version', fe_version)
            return res;
        }
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        const user = JSON.parse(localStorage.getItem('user'))
        if (err.response) {
            if (err.response.status === 401 && !originalConfig._retry && user) {
                originalConfig._retry = true;
                try {
                    let rs = await axios.post(`${process.env.VUE_APP_API}/api/v1/login/token/refresh`, {
                        "refresh": localStorageService.getRefreshToken()
                    })
                    const {access_token, refresh_token} = rs.data.body;
                    localStorageService.setToken(access_token, refresh_token)

                    instance.defaults.headers.common["Authorization"] = access_token;
                    return instance(originalConfig);
                } catch (_error) {
                    if (_error.response && _error.response.data) {
                        localStorageService.clearToken()
                        delete instance.defaults.headers.common["Authorization"];
                        await router.push({name: 'login'});
                        return Promise.reject(_error.response.data);
                    }
                    localStorageService.clearToken()
                    await router.push({name: 'login'});
                    return Promise.reject(_error);
                }
            }
            if (err.response.status === 403 && err.response.data) {
                return Promise.reject(err.response.data);
            }
            if (err.response.status === 404 && err.response.data) {
                await router.push({name: 'Error-404'})
                return Promise.reject(err.response.data);
            }
        }
        return Promise.reject(err);
    }
);
//
// function refreshToken() {
//     alert('refresh')
//     return instance.post(`${process.env.VUE_APP_API}api/v2/token/refresh`, {
//         "refresh": localStorageService.getRefreshToken()
//     });
// }

export default instance
