<template>
  <div data-app>
    <v-dialog
        v-model="dialog"
        persistent
    >
      <div class="unsubscribe-popup-wrapper" style="max-height: 90%; overflow-y: auto">
        <div class="unsubscribe-header">
          <h1 class="unsubscribe-header__title">Unsubscribe</h1>
          <button @click="closePopup">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 5.5C11.4627 8.31416 9.11674 10.9681 5 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 5.5C6.86016 8.21022 8.65254 10.1669 15 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        <div class="unsubscribe-body">
          Are you sure you want to unsubscribe from <span>{{ $route.params.nickname }}</span>? after unsubscribing, you will not be able to access exclusive content
        </div>
        <div class="unsubscribe-footer">
          <ButtonBlue @clickEvent="closePopup">Cancel</ButtonBlue>
          <button class="btn-yellow" @click="unsubscribeHandler">Unsubscribe</button>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import ButtonBlue from "@/components/elements/ButtonBlue";
export default {
  name: "UnsubscribePopup",
  components: {ButtonBlue},
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    openPopup() {
      this.dialog = true
    },
    closePopup() {
      this.dialog = false
    },
    unsubscribeHandler() {
      this.$emit('unsubscribe')
      this.closePopup()
    }
  }
}
</script>

<style scoped>
.unsubscribe-popup-wrapper {
  position: relative;
  max-width: 846px;
  width: 100%;
  background: #FFFFFF;
  padding: 40px;
  margin: 0 auto;
  border-radius: 10px;
}

.unsubscribe-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.unsubscribe-header__title {
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 33px;
  color: var(--primary-blue);
}

.unsubscribe-body {
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #0057B7;
}

.unsubscribe-footer {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
}

@media only screen and (max-width: 600px) {
  .unsubscribe-popup-wrapper {
    padding: 20px;
  }
}
</style>