import instance from "../api/instance";


export const search = {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async searchAction(context, {body, type, params}) {
            return (await instance.post(`/api/v1/search/${type}`, body, {params})).data.body
        },
    }
}