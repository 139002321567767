import instance from "../api/instance";


export const hallOfFame = {
    namespaced: true,
    state: {
        erasList: [
            {
                name: 'Classic era',
                value: 'CLASSIC_ERA'
            },
            {
                name: '80s era',
                value: 'EIGHTIES'
            },
            {
                name: '90s era',
                value: 'NINETIES'
            },
            {
                name: '00s era',
                value: 'ZERO_YEARS'
            },
            {
                name: 'Modern era',
                value: 'MODERN_ERA'
            },
        ],
    },
    getters: {
        getEraList(state) {
            return state.erasList
        }
    },
    mutations: {

    },
    actions: {
        async getAllLegends(ctx, {body, params}) {
            return (await instance.post(`/api/v1/legends/search`, body, {params})).data.body
        },
        async getLegend(ctx, uuid) {
            return (await instance.get(`/api/v1/legends/${uuid}`)).data.body
        },
        async voteForLegend(ctx, uuid) {
            await instance.post(`/api/v1/legends/votes/${uuid}`)
        },
        async getLegendComments(ctx, payload) {
            let postId = payload.postId
            let params = payload.params
            return (await instance.get(`/api/v1/legends/${postId}/comments`, params)).data.body
        },
        async getLegendCommentsReplies(context, payload) {
            let postId = payload.postId
            let params = payload.params
            let commentId = payload.commentId
            return (await instance.get(`/api/v1/legends/${postId}/comments/${commentId}`, params)).data.body
        },
        async addLegendComment(ctx, payload) {
            let postId = payload.postId
            let body = payload.body
            return (await instance.post(`/api/v1/legends/${postId}/comments`, body)).data.body
        },
        async addLegendCommentsReplies(context, payload) {
            let postId = payload.postId
            let body = payload.body
            let commentId = payload.commentId
            return (await instance.post(`/api/v1/legends/${postId}/comments/${commentId}`, body)).data.body
        },
        async likeLegendAction(ctx, legendUuid) {
            return await instance.post(`/api/v1/legends/likes/${legendUuid}`)
        },
        async dislikeLegendAction(ctx, legendUuid) {
            return await instance.delete(`/api/v1/legends/likes/${legendUuid}`)
        },
        async createLegendSong(ctx, body) {
            await instance.post(`/api/v1/legends/songs`, body)
        },
        async createLegendPerson(ctx, body) {
            await instance.post(`/api/v1/legends/persons`, body)
        },
        async getLegendPlaylist() {
            return (await instance.get(`/api/v1/legends/songs-legends`)).data.body
        },
        async getLegendSongsAmount() {
            return (await instance.get(`/api/v1/legends/songs-legends/counts`)).data.body
        }
    }
}