<template>
  <div class="home-wrapper">
    <div class="mobile-header mb-2">
      <div class="d-flex align-center justify-space-between w-full">
        <h2 class="page-title">Home</h2>
      </div>
    </div>
    <div class="sign-in-button-wrapper mobile px-3 mt-7" v-if="!isLogged">
      <button class="btn-yellow w-full mobile" @click="$router.push({name: 'login'})">Sign in</button>
    </div>
    <CreatePostTemplate v-if="isArtist && device === 'desktop'" :inputForId="'home-page'"/>
    <div class="home-wall-wrapper">
      <div class="home-wall-header d-flex justify-space-between align-center flex-wrap">
        <ul class="tab-wrapper d-flex justify-space-between w-full">
          <li :style="{background: tab === 'recommendations' ? 'linear-gradient(90.41deg, rgba(0, 110, 233, 0.6) -2.47%, rgba(30, 150, 252, 0.36) 103.59%)' : '', color: tab === 'recommendations' ? '#FFFFFF' : '#0057B7'}"
              @click="switchTab('recommendations')">Recommendations
          </li>
          <li :style="{background: tab === 'premium' ? 'linear-gradient(90.41deg, rgba(0, 110, 233, 0.6) -2.47%, rgba(30, 150, 252, 0.36) 103.59%)' : '', color: tab === 'premium' ? '#FFFFFF' : '#0057B7'}"
              @click="switchTab('premium')">Premium subscription
          </li>
        </ul>
      </div>
    </div>
    <div class="show-post-else" v-if="tab === 'premium' && !postList.length">You haven't subscribed to anyone yet.
      Subscribe to an artist to see their premium content
    </div>

    <vue-loadmore
        :on-refresh="onRefresh"
        :on-loadmore="onLoad"
        :finished="finished"
        :loading-text="'load'"
        :refresh-text="' '"
        :finished-text="' '"
        :pulling-text="' '"
        :loosing-text="' '"
        :show-success-text="false"
        :load-offset="500"
        ref="vueLoadMore"
    >
      <ShowPostTemplate v-for="(post, index) in postList" :key="index" :post="post" :index="index"
                        ref="ShowPostTemplate" @signIn="openSignInPopup"/>
    </vue-loadmore>
  </div>
</template>

<script>
// @ is an alias to /src

import CreatePostTemplate from "@/components/post/CreatePostTemplate";
import ShowPostTemplate from "@/components/post/ShowPostTemplate";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: 'HomeView',
  data() {
    return {
      description: '',
      tab: 'recommendations',
      page: 1,
      pageSize: 20,
      count: 1,
      postList: [],
      finished: false,
      loadFlag: false,
      localStorageUser: JSON.parse(localStorage.getItem('user')),
      device: 'desktop'
    }
  },
  components: {ShowPostTemplate, CreatePostTemplate},
  computed: {
    ...mapGetters({
      isLogged: 'authentication/isLogged'
    }),
    postType() {
      return this.tab === 'recommendations' ? 'PUBLIC' : 'PREMIUM'
    },
    isArtist() {
      return this.localStorageUser?.authorities.includes('ARTIST')
    },
  },
  methods: {
    ...mapActions({
      getPostListAction: 'content/getPostListAction'
    }),
    ...mapMutations({
      openSignInPopup: 'authentication/openSignInPopup'
    }),
    switchTab(tabName) {
      this.tab = tabName
      this.loadFlag = false
      this.postList = [];
      this.page = 0;
      this.finished = false;
      this.fetch();
      this.loadFlag = true
      // this.postListRequest()
    },
    async postListRequest() {
      let dateNow = Math.floor(Date.now() / 1000)
      const payload = {
        params: this.getRequestParams(this.page, this.pageSize, this.postType, dateNow)
      }
      let res = await this.getPostListAction(payload)
      this.count = res.totalPages
      this.postList = [...this.postList, ...res.elements]
    },
    getRequestParams(page, pageSize, postType, initialTimeStamp) {
      let params = {};

      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }
      if (postType) {
        params["postType"] = postType
      }
      if (initialTimeStamp) {
        params["initialTimeStamp"] = initialTimeStamp
      }
      return params;
    },
    onRefresh(done) {
      done();
      this.postList = [];
      this.page = 0;
      this.finished = false;
      this.fetch();

    },
    onLoad(done) {
      if (this.page < this.count) {
        this.$refs.vueLoadMore.checkSroll()
        this.fetch();
      } else {
        // all data loaded
        this.finished = true;
      }
      done();
    },
    fetch() {
      this.page++;
      this.postListRequest()
    },
    deviceHandler() {
      const width = window.innerWidth
      this.device = width <= 600 ? 'mobile' : 'desktop'
    }
  },
  created() {
    this.postListRequest()
  },
  beforeDestroy() {
    removeEventListener('resize', this.deviceHandler)
  },
  mounted() {
    document.title = 'Home'
    window.addEventListener('resize', this.deviceHandler)
    this.deviceHandler()
  }
}
</script>

<style scoped>
.home-wall-header {
  margin-top: 30px;
  padding: 20px 40px;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 10px 10px 0 0;
}

.post-search input {
  width: 100%;
  padding: 13px 15px 13px 50px;
  color: var(--primary-blue);
}

.tab-wrapper li {
  width: calc(50% - 10px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 16px 0;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
}

.show-post-else {
  width: 100%;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #0057B7;
  background: #FFFFFF;
  border-radius: 0 0 10px 10px;
  border: 1px solid #D9D9D9;
}


@media only screen and (max-width: 600px) {
  .home-wall-header, .show-post-else {
    padding: 15px;
  }

  .show-post-else {
    text-align: center;
  }
}
</style>
