var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"induct-legend-wrapper"},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('h2',{staticClass:"page-title"},[_vm._v("Induct a Legend")]),_c('svg',{staticClass:"mobile",attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"},on:{"click":function($event){return _vm.$router.back()}}},[_c('path',{attrs:{"d":"M18 6C13.7552 9.37699 10.9401 12.5617 6 18","stroke":"#0057B7","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M6 6C8.23219 9.25226 10.383 11.6003 18 18","stroke":"#0057B7","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('div',{staticClass:"induct-legend-tabs"},[_c('ul',{staticClass:"tabs-wrapper"},[_c('li',{staticClass:"tabs-item",style:({
        background: _vm.tab === 'SONG' ? 'linear-gradient(90.41deg, rgba(0, 110, 233, 0.6) -2.47%, rgba(30, 150, 252, 0.36) 103.59%)' : '',
        color: _vm.tab === 'SONG' ? '#FFFFFF' : '#0057B7',
        fontWeight: _vm.tab === 'SONG' ? 800 : 600
      }),on:{"click":function($event){_vm.tab = 'SONG'}}},[_c('span',[_vm._v("Music work")])]),_c('li',{staticClass:"tabs-item",style:({
        background: _vm.tab === 'PERSON' ? 'linear-gradient(90.41deg, rgba(0, 110, 233, 0.6) -2.47%, rgba(30, 150, 252, 0.36) 103.59%)' : '',
        color: _vm.tab === 'PERSON' ? '#FFFFFF' : '#0057B7',
        fontWeight: _vm.tab === 'PERSON' ? 800 : 600
        }),on:{"click":function($event){_vm.tab = 'PERSON'}}},[_c('span',[_vm._v("Music creator")])])]),(_vm.tab === 'PERSON')?_c('div',{staticClass:"induct-legend-form-wrapper"},[_c('CreateLegendPersonForm',{ref:"CreateLegendPersonForm"})],1):_vm._e(),(_vm.tab === 'SONG')?_c('div',{staticClass:"induct-legend-form-wrapper"},[_c('CreateLegendSongForm',{ref:"CreateLegendSongForm"})],1):_vm._e()]),_c('ButtonBack')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }