var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-5"},[_c('h2',{staticClass:"title"},[_vm._v("Comments")]),_c('div',{staticClass:"input-wrapper"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.reply),expression:"reply"}],ref:`replyTextArea`,staticClass:"input w-full mt-5 mr-2",attrs:{"name":"","rows":"2","placeholder":"Text"},domProps:{"value":(_vm.reply)},on:{"input":function($event){if($event.target.composing)return;_vm.reply=$event.target.value}}}),_vm._l((_vm.v$.reply.$errors),function(error){return _c('p',{key:error.$uid,staticClass:"error top15"},[_vm._v(" "+_vm._s(error.$message)+" ")])}),_c('button',{staticClass:"send-message",on:{"click":_vm.addComment}},[_c('v-icon',{attrs:{"size":"25","color":"#0057B7"}},[_vm._v("mdi-send-circle-outline")])],1)],2),_c('div',{staticClass:"separator my-5"}),_c('div',[_c('v-slide-y-transition',{attrs:{"group":""}},_vm._l((_vm.comments),function(item){return _c('ul',{key:item.id,ref:"commentQwe",refInFor:true,staticClass:"comments"},[(_vm.renderFlag)?_c('CommentComponent',_vm._b({on:{"replied":function($event){return _vm.$emit('replied')}}},'CommentComponent',{
        user: item.userCommenter,
        publishedAt: item.publishedAt,
        comment: item.message,
        tailIds: item.tailIds,
        id: item.id,
        postId: item.postId,
        root: true,
        commentsFor: _vm.commentsFor
      },false)):_vm._e()],1)}),0)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }