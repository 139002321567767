<template>
  <div class="chat-wrapper">
    <div class="chat-header w-full">
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-4 mobile" @click="$emit('back')">
        <rect width="30" height="30" rx="15" fill="#0057B7"/>
        <path d="M13.413 9L9.5 15M9.5 15L13.413 21M9.5 15L21.5 15" stroke="#FFD60A" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <img v-if="opponent && opponent.avatar" :src="opponent.avatar" alt="Urkmusic" class="item-avatar">
      <img v-else src="../../assets/img/default-avatar.png" alt="Urkmusic" class="item-avatar">
      <div class="d-flex flex-column justify-space-between item-sender-info">
        <div class="nick-name">{{ opponent.nickName }}</div>
<!--        <div class="last-message">{{ 'Last seen few minutes ago' }}</div>-->
      </div>
      <!--      <button class="btn-yellow" @click="addMessage">add message</button>-->
    </div>
    <div class="chat-body w-full">
      <ul class="messages">
        <ChatMessageComponent v-for="item in getMessages" :key="item.id" :item="item"/>
        <li v-if="messages.length === 0" style="margin: 0 auto">You don't have any messages</li>
      </ul>
    </div>
    <div class="chat-input w-full px-10 d-flex align-center" v-if="isChatActive">
      <textarea name="" id="chat-add-message" rows="2" class="w-full"
                v-model="reply" placeholder="Start typing...." :ref="`chatReplyTextArea`"></textarea>
      <button class="send-message" @click="addMessage">
        <v-icon size="25" color="#0057B7">mdi-send-circle-outline</v-icon>
      </button>
      <label for="chat-attach-img">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
             class="attach-img">
          <path
              d="M15.4513 5.15673C13.921 8.21721 12.0964 11.1003 10.4679 14.101C10.1047 14.7701 9.4463 15.9156 9.5511 16.7278C9.6187 17.2517 10.575 17.7372 11.0026 17.9384C11.8206 18.3233 11.9551 17.3034 12.2191 16.6985C13.0467 14.8023 14.0618 13.0351 15.0281 11.2097C16.1798 9.03423 17.7005 7.23933 18.037 4.73948C18.1758 3.70836 18.4979 1.95291 17.0321 2.00097C15.9512 2.0364 14.7987 2.29088 13.8646 2.85308C12.4667 3.69439 11.9484 5.71133 11.2788 7.08427C9.43621 10.8628 7.40424 14.7743 6.27781 18.8493C6.123 19.4094 5.87694 20.2736 6.07213 20.8591C6.48806 22.1069 8.85889 22.267 9.85669 22.0462C12.7418 21.4077 14.6134 17.7672 16.0624 15.5466C16.7935 14.4263 17.9289 12.5637 18.5776 11.3272C20.3632 7.92393 18.329 11.6382 19.6824 8.75323"
              stroke="#006EE9" stroke-width="2" stroke-linecap="round"/>
        </svg>
      </label>
    </div>
    <div class="chat-input w-full px-10 d-flex align-center justify-center" v-else>To chat with an artist you must be <span class="subscribe-text mx-1" @click="subscribe">subscribed</span> to him</div>
    <input type="file" name="chat-attach-img" id="chat-attach-img" accept="image/*" @change="openPreview">
    <ImagePreviewPopup ref="ImagePreviewPopup" :src="results" @closePopup="results = []" @sendImage="sendImage"/>
  </div>
</template>

<script>
import {mapActions, mapMutations} from "vuex";
import ChatMessageComponent from "@/components/chat/ChatMessageComponent";
import ImagePreviewPopup from "@/components/popups/ImagePreviewPopup";

export default {
  name: "ChatTemplate",
  components: {ImagePreviewPopup, ChatMessageComponent},
  props: {
    messages: {
      type: Array,
      default: () => []
    },
    opponent: {
      type: Object,
      required: true
    },
    isChatActive: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      showChat: false,
      reply: '',
      messagesData: [],
      interval: null,
      results: [],
      subscribePrice: null
    }
  },
  computed: {
    getMessages: {
      get() {
        return this.messagesData
      },
      set(value) {
        this.messagesData.unshift(value)
      }
    }
  },
  methods: {
    ...mapActions({
      sendMessage: 'chat/sendMessage',
      subscribeRequest: 'payment/purchase',
      getServicesPrice: 'prices/getServicePricesAction'
    }),
    ...mapMutations({
      setErrorMessage: 'error/SET_ERROR_MESSAGE',
      setDialog: 'error/SET_DIALOG'
    }),
    async addMessage() {
      if(this.reply) {
        const formData = new FormData()
        formData.set('message', this.reply)
        formData.set('messageType', 'TEXT')
        formData.set('receiverUserId', this.opponent.id)
        await this.sendMessage(formData)
        this.$emit('getData')

        this.reply = ''
      }
    },
    openPreview(event) {
      this.processFileInput(event.target.files)
      event.target.value = ''
      this.$refs.ImagePreviewPopup.openPopup()
    },
    async sendImage() {
      const formData = new FormData()
      formData.set('file', this.results[0].file)
      formData.set('messageType', 'IMAGE')
      formData.set('receiverUserId', this.opponent.id)

      await this.sendMessage(formData)
      this.$refs.ImagePreviewPopup.closePopup()
      this.$emit('getData')
    },
    processFileInput(files) {
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        if (file.type.match('image.*')) {
          let reader = new FileReader();
          reader.onloadend = function () {
            if (this.results === null)
              this.results = [];
            const results = {
              filePreview: reader.result,
              file: file
            };
            this.results.push(results);
          }.bind(this);
          reader.readAsDataURL(file);
        }
      }
    },
    async subscribe() {
      let payload = {
        paymentGoal: 'SUBSCRIBE',
        money: {
          amount: this.subscribePrice,
          currency: 'UAH'
        },
        artistUuid: this.opponent.uuid,
      }
      try {
        // this.subscribeFlag = false
        window.location.href = (await this.subscribeRequest(payload)).checkoutUrl
        // this.$emit('subscribed')
      } catch (e) {
        this.setErrorMessage(e.response.data.errors[0].message)
        this.setDialog(true)
      }
    },
  },
  updated() {

  },
  created() {

  },
  async mounted() {
    this.subscribePrice = (await this.getServicesPrice(this.opponent.uuid)).subscribePrice.price
    this.interval = setInterval(() => {
      this.$emit('getData')
      this.messagesData = this.messages
    }, 500)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style scoped>
.chat-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  border: 1px solid #E3E3E3;
  border-radius: 10px;
  background: #FFFFFF;
  height: 750px;
}

.chat-header {
  display: flex;
  align-items: center;
  padding: 0 30px;
  height: 80px;
  border-bottom: 1px solid #E3E3E3;
}

.nick-name {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 8px;
}

.last-message {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #707070;
}

.item-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.chat-input {
  position: relative;
  border-top: 1px solid #E3E3E3;
  height: 65px;
}

.chat-body {
  flex: 1;
  padding: 15px;
  overflow-y: auto;
}

.chat-body ul {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  height: 100%;
  overflow-y: auto;
}

.send-message {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.attach-img {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

#chat-attach-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
}

.subscribe-text {
  color: var(--primary-blue);
  font-weight: 600;
  cursor: pointer;
}

.mobile {
  display: none;
}

@media only screen and (max-width: 800px) {
  .mobile {
    display: block;
  }

}

@media only screen and (max-width: 600px) {
  .chat-wrapper {
    max-height: unset;
    width: 100%;
  }

  .chat-input {
    flex-wrap: wrap;
  }

}
</style>