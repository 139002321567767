<template>
  <div data-app style="z-index: 100000">
    <v-dialog v-model="dialog">
      <div class="create-content-wrapper  registration-date-picker"
           style="max-height: calc(100vh - 100px); overflow-y: auto">
        <div class="content-body" style="position:relative;">
          <v-date-picker v-model="picker" @change="submitDate"
                         :max='setMaxDate()'></v-date-picker>
        </div>
        <div class="close-button">
          <button @click="closePopup">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 5.5C11.4627 8.31416 9.11674 10.9681 5 15.5" stroke="#FFFFFF" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 5.5C6.86016 8.21022 8.65254 10.1669 15 15.5" stroke="#FFFFFF" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
      </div>
    </v-dialog>

  </div>
</template>

<script>

export default {
  name: "DatePickerPopup",
  components: {},
  data() {
    return {
      picker: this.setMaxDate(),
      dialog: false
    }
  },
  methods: {
    openPopup() {
      this.dialog = true
    },
    closePopup() {
      this.picker = ''
      this.dialog = false
    },
    submitDate() {
      this.$emit('dateSubmitted', this.picker)
      this.closePopup()
    },
    setMaxDate() {
      const date = new Date()
      date.setFullYear( date.getFullYear() - 16 )
      return date.toISOString().substring(0, 10)
    }
  },
}
</script>

<style scoped>
.create-content-wrapper {
  position: relative;
  max-width: 290px;
  background: #FFFFFF;
  margin: 0 auto;
  border-radius: 10px;
}

.content-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
}

</style>