<template>
  <div class="mt-5">
    <h2 class="title">Comments</h2>
    <div class="input-wrapper">
      <textarea name=""  rows="2" class="input w-full mt-5 mr-2"
                v-model="reply" placeholder="Text" :ref="`replyTextArea`"></textarea>
      <p class="error top15" v-for="error of v$.reply.$errors" :key="error.$uid">
        {{ error.$message }}
      </p>
      <button class="send-message" @click="addComment">

        <v-icon size="25" color="#0057B7">mdi-send-circle-outline</v-icon>
      </button>
    </div>
    <div class="separator my-5"></div>
    <div>
      <v-slide-y-transition group>
        <ul v-for="item in comments" :key="item.id" class="comments" ref="commentQwe">
<!--          <component :is="currentComponent" v-if="currentComponent"/>-->
          <CommentComponent
              @replied="$emit('replied')"
              v-bind="{
        user: item.userCommenter,
        publishedAt: item.publishedAt,
        comment: item.message,
        tailIds: item.tailIds,
        id: item.id,
        postId: item.postId,
        root: true,
        commentsFor: commentsFor
      }"
              v-if="renderFlag"
          />
        </ul>
      </v-slide-y-transition>

    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import CommentComponent from "@/components/CommentComponent.vue";
import {useVuelidate} from '@vuelidate/core'
import {maxLength} from '@vuelidate/validators'

export default {
  name: "ShowCommentsTemplate",
  props: {
    postId: {
      type: Number,
      required: true
    },
    commentsFor: {
      type: String,
      default: 'POST'
    }
  },
  setup() {
    return {v$: useVuelidate({$lazy: true, $autoDirty: true})}
  },
  data() {
    return {
      reply: '',
      comments: [],
      page: 1,
      pageSize: 10,
      count: 0,
      renderFlag: true,
      currentComponent: null
    }
  },
  validations() {
    return {
      reply: {
        maxLength: maxLength(50)
      },
    }
  },
  computed: {
    ...mapGetters({
      isLogged: 'authentication/isLogged'
    }),
  },
  methods: {
    ...mapActions({
      getPostComments: 'comments/getPostComments',
      addPostComment: 'comments/addPostComment',
      getLegendComments: 'hallOfFame/getLegendComments',
      addLegendComment: 'hallOfFame/addLegendComment'
    }),
    ...mapMutations({
      openSignInPopup: 'authentication/openSignInPopup'
    }),
    async commentsRequest() {
      let dateNow = Math.floor(Date.now() / 1000)
      const payload = {
        params: {
          params: this.getRequestParams(this.page, this.pageSize, dateNow)
        },
        postId: this.postId
      }
      let res
      if(this.commentsFor === 'POST') {
        res = await this.getPostComments(payload)
      } else if(this.commentsFor === 'LEGEND') {
        res = await this.getLegendComments(payload)
      }
      this.count = res.totalPages
      this.comments = [...this.comments, ...res.elements]
      // console.log(this.comments)
    },
    async addComment() {
      if(this.isLogged) {
        const isFormCorrect = await this.v$.$validate()
        if(!isFormCorrect) {
          return false
        } else {
          const form = new FormData()
          form.set('comment', this.reply)
          const payload = {
            body: form,
            postId: this.postId
          }
          if(this.reply) {
            let res
            if(this.commentsFor === 'POST') {
              res = await this.addPostComment(payload)
            } else if(this.commentsFor === 'LEGEND') {
              res = await this.addLegendComment(payload)
            }

            // arr.push(res)
            // console.log(arr)
            // this.comments = [...arr, this.comments]
            this.comments.unshift(res)
            this.currentComponent = 'CommentComponent'

            // this.renderFlag = false
            // setTimeout(() => {
            //   this.renderFlag = true
            // }, 100)
            this.$emit('replied')
            this.showReplies = true
            this.reply = ''
          }
        }
      } else {
        this.openSignInPopup()
      }


    },
    getRequestParams(page, pageSize, initialTimeStamp) {
      let params = {};

      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }
      if (initialTimeStamp) {
        params["initialTimeStamp"] = initialTimeStamp
      }
      return params;
    },
  },
  mounted() {
    this.commentsRequest()

  },
  components: {CommentComponent}
}
</script>

<style scoped>
.title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: var(--primary-blue);
}

.input-wrapper {
  position: relative;
}

.send-message {
  position: absolute;
  bottom: 10px;
  right: 3px;
}

.comments ul {
  padding-left: 16px;
  margin: 6px 0;
}

.error {
  position: absolute;
  bottom: -15px;
  left: 0;
}

</style>