<template>
  <div class="mt-5">
     <p>{{ message.context }}</p>
  </div>
</template>

<script>
export default {
  name: "ShowPostMessageComponent",
  props: {
    message: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: var(--primary-blue);
}
</style>