<template>
  <div class="mt-5 video-wrapper">
    <video controls class="videoo" :src="video.urlFile" ref="videoRef" height="100%" width="100%" @play="onPlay" @pause="onPause"></video>
    <button class="play-button">
      <svg width="40" height="42" viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="togglePlayerFlag" @click="toggleMusic">
        <path d="M5.24489 7.5C9.2977 2.65915 11.936 0.595703 21.468 0.595703C31 0.595703 33.7641 4.10685 37 9.5C40 14.5 39.9352 18.5656 39.688 24.5C39.4778 29.5444 36.7646 35.0416 32.5 38.2565C22.1351 46.0701 3.62313 39.4435 1.00051 26.3304C-0.269249 19.9816 0.945866 12.6349 5.24489 7.5Z" fill="#0057B7"/>
        <path d="M25.9993 20.0007C25.9993 18.5014 17.0026 12.4998 17 13.0002C16.9975 13.5007 17.2247 16.5946 17.2234 20.5C17.223 21.8666 16.9981 25.9992 17 26.4999C17.0019 27.0007 25.9993 21.5 25.9993 20.0007Z" stroke="white" stroke-width="2" stroke-linecap="round"/>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: "ShowPostImageComponent",
  props: {
    video: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      togglePlayerFlag: true
    }
  },
  methods: {
    toggleMusic() {
      this.$refs.videoRef.play()
      this.togglePlayerFlag = false;
    },
    onPause() {
      this.togglePlayerFlag = true;
    },
    onPlay() {
      this.togglePlayerFlag = false;
    }
  },
  watch: {

  },
  mounted() {
  }
}
</script>

<style scoped>
.video-wrapper {
  position: relative;
  width: 100%;
  height: 350px;
  border-radius: 10px;
}

.video {
  width: 100%;
  height: 100%;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .video-wrapper {
    height: 270px;
  }
}
</style>