export const error = {
    namespaced: true,
    state: {
        message: '',
        dialog: false
    },
    getters: {
        getErrorMessage(state) {
            return state.message
        },
        getDialog(state) {
            return state.dialog
        }
    },
    mutations: {
        SET_ERROR_MESSAGE(state, payload) {
            state.message = payload
        },
        SET_DIALOG(state, payload) {
            state.dialog = payload
        }
    },
    actions: {
        invokeErrorPopup({ commit }, message) {
            commit('SET_ERROR_MESSAGE', message)
            commit('SET_DIALOG', true)

        }
    }
}