<template>
  <div class="content-event-wrapper">
    <div class="event-items">
      <EventItemComponent
          v-for="event in slicedEvents"
          :key="event.id"
          :event="event"
          :userUuid="userUuid"
          :routePage="true"
      />
    </div>
  </div>
</template>

<script>
import EventItemComponent from "@/components/EventItemComponent";
export default {
  name: "ProfileContentEventComponent",
  components: {EventItemComponent},
  props: {
    events: {
      type: Array,
      default: () => []
    },
    userUuid: {
      type: String,
      default: ''
    }
  },
  computed: {
    slicedEvents() {
      return this.events.slice(0, 3)
    }
  },
  methods: {
    check() {

    }
  }
}
</script>

<style scoped>
.content-event-wrapper {
  margin-top: 40px;
}

.event-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  padding: 20px 0;
}

@media only screen and (max-width: 600px) {
  .event-items {
    flex-direction: column;
  }
}
</style>