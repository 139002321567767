<template>
  <div class="mt-5" data-app v-if="voteData">
    <h2 class="vote-title">{{ voteData.title }}</h2>
    <ul v-if="isVoted" class="vote-result">
      <li v-for="option in voteData.options" :key="option.id" class="option w-full mt-5">
        <div class="d-flex">
          <p class="mr-5 percent">{{ `${option.percentVoted}%` }}</p>
          <p class="name open-sans">{{ `${option.description}` }}</p>
        </div>
        <div class="option-line mt-3" :style="{width: `${option.percentVoted}%`}"></div>
      </li>
    </ul>
    <div v-else>
      <div v-if="!voteData.isMultiAnswer">
        <v-radio-group v-model="voteAnswers">
          <v-radio
              class="vote-option mt-4 mb-0"
              v-for="n in voteData.options"
              :key="n.id"
              :label="`${n.description}`"
              :value="n.id"
          ></v-radio>
        </v-radio-group>
        <button class="btn-yellow w-full" @click="singleVoteHandler" v-if="voteAnswers">Vote</button>
        <div class="btn-yellow btn-no-hover disabled w-full" v-else>Vote</div>
      </div>
      <div v-else>
        <v-checkbox
            v-for="n in voteData.options"
            :key="n.id"
            v-model="multipleAnswers"
            :label="n.description"
            :value="n.id"
            class="vote-option"
        ></v-checkbox>
        <button class="btn-yellow w-full" @click="multipleVoteHandler" v-if="multipleAnswers.length">Vote</button>
        <div class="btn-yellow btn-no-hover disabled w-full" v-else>Vote</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "ShowPostPollComponent",
  props: {
    vote: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      voteAnswers: null,
      width: 0,
      isVoted: false,
      multipleAnswers: [],
      voteData: null
    }
  },
  computed: {
    ...mapGetters({
      isLogged: 'authentication/isLogged'
    }),

  },
  methods: {
    ...mapActions({
      makeVote: 'content/makeVote'
    }),
    ...mapMutations({
      openSignInPopup: 'authentication/openSignInPopup'
    }),
    async singleVoteHandler() {
      if(this.isLogged) {
        let payload = {
          pollId: this.voteData.id,
          body: [this.voteAnswers]
        }
        let res = await this.makeVote(payload)
        this.isVoted = true
        this.voteData.options = res.options
      } else {
        this.openSignInPopup()
      }

    },
    async multipleVoteHandler() {
      if(this.isLogged) {
        let payload = {
          pollId: this.voteData.id,
          body: this.multipleAnswers
        }
        let res = await this.makeVote(payload)
        this.isVoted = true
        this.voteData.options = res.options
      } else {
        this.openSignInPopup()
      }
    },

  },
  mounted() {
    this.voteData = this.vote
    this.isVoted = this.voteData.isVoted
  }
}
</script>

<style scoped>
.vote-title {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  color: var(--primary-blue);
}

.option-line {
  height: 6px;
  background-color: #FFD60A;
  border-radius: 10px;
  transition: width 1s ease-in-out;
}

.vote-result .name {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: var(--primary-blue);
}

.vote-result .percent {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-blue);
}
</style>