<template>
  <div data-app>
    <v-dialog
        v-model="dialog"
        max-width="846"
    >
      <div class="filter-popup-wrapper" style="max-height: calc(100vh - 100px); overflow-y: auto">
        <div class="filter-header">
          <h1 class="filter-header__title">Filter</h1>
          <button @click="closePopup">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 5.5C11.4627 8.31416 9.11674 10.9681 5 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 5.5C6.86016 8.21022 8.65254 10.1669 15 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        <form class="induct-legend-form" @submit.prevent="onSubmitAction">
          <ul class="tabs-wrapper">
            <li class="tabs-item" :style="{
          background: filterTab === 'SONG' ? 'linear-gradient(90.41deg, rgba(0, 110, 233, 0.6) -2.47%, rgba(30, 150, 252, 0.36) 103.59%)' : '#FFFFFF',
          color: filterTab === 'SONG' ? '#FFFFFF' : '#0057B7',
          fontWeight: filterTab === 'SONG' ? 800 : 500
        }" @click="tabHandler('SONG')">
              <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="mr-2">
                <path
                    d="M8.72755 17.189C8.72755 17.2411 8.20332 20.4931 7.24539 20.582C5.49921 20.7441 2.25465 19.0314 2.51472 17.386C2.73912 15.9662 4.45595 15.4636 5.92253 15.4636C7.12273 15.5548 7.81451 16.4512 8.72755 17.189ZM8.72755 17.189C9.29585 14.8709 9.10107 15.7222 9.50242 13.3137C9.8517 11.2176 9.777 9.11506 10.1207 7.02291C10.1961 6.56353 10.0209 5.87496 10.6192 5.68143C12.1867 5.17442 13.6626 4.48616 15.246 3.99001C16.7989 3.50338 18.5258 3.14045 20.0123 2.59854C20.477 2.42913 20.2716 5.98003 20.2716 7.31453C20.2716 9.75862 20.3398 12.1863 20.477 14.6258M20.477 14.6258C20.5209 15.4065 20.5119 15.5986 20.3613 16.1883C19.8202 18.3071 17.0677 19.3206 15.9539 19.1212C14.482 18.8576 13.1759 17.8077 13.3115 16.4799C13.4854 14.7775 14.8727 14.1289 16.8314 13.9886C17.9338 13.9096 20.4331 13.8451 20.477 14.6258Z"
                    :stroke="filterTab === 'SONG' ? '#FFFFFF' : '#0057B7'" stroke-width="2" stroke-linecap="round"/>
              </svg>
              <span>Song</span>
            </li>
            <li class="tabs-item" :style="{
          background: filterTab === 'ARTIST' ? 'linear-gradient(90.41deg, rgba(0, 110, 233, 0.6) -2.47%, rgba(30, 150, 252, 0.36) 103.59%)' : '#FFFFFF',
          color: filterTab === 'ARTIST' ? '#FFFFFF' : '#0057B7',
          fontWeight: filterTab === 'ARTIST' ? 800 : 500
          }"
                @click="tabHandler('ARTIST')">
              <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="mr-2">
                <path
                    d="M16.2542 19.8427H7.09021C5.54199 19.8427 5.80128 18.9082 4.83462 18.0737C4.14005 17.4741 4.02366 16.7071 4.31877 15.8791C4.70377 14.799 4.95912 13.412 6.13942 12.8805C7.1872 12.4087 8.40716 12.521 9.53292 12.521C10.5231 12.521 11.4912 12.5534 12.4611 12.7575C14.1293 13.1085 15.7532 13.6111 17.2859 14.3278C17.8432 14.5884 17.7886 14.9445 17.9686 15.5244C18.1454 16.0938 18.3394 16.6688 18.5401 17.2319C18.6463 17.5298 18.8239 17.8205 18.8334 18.14C18.8475 18.6154 18.2327 19.1282 17.8826 19.4028C17.3217 19.8428 16.9875 19.8427 16.2808 19.8427L16.2542 19.8427Z"
                    :stroke="filterTab === 'ARTIST' ? '#FFFFFF' : '#0057B7'" stroke-width="2"
                    stroke-linecap="round"/>
                <path
                    d="M11.3476 7.75927C10.8058 7.99081 10.4753 7.73996 9.89298 7.73996C9.26631 7.73996 8.825 7.56001 8.31481 7.18021C7.79404 6.79254 7.07171 6.41492 6.98857 5.65538C6.90049 4.85064 6.76886 3.37207 7.46393 2.8325C8.14189 2.30622 8.9352 2.44165 9.74087 2.44165C10.4325 2.44165 10.9343 2.59021 11.571 2.78908C12.6791 3.13519 13.0138 3.85794 13.249 4.97982C13.4173 5.78274 13.3668 6.33004 12.8021 6.87139C12.3733 7.28249 11.8818 7.53091 11.3476 7.75927Z"
                    :stroke="filterTab === 'ARTIST' ? '#FFFFFF' : '#0057B7'" stroke-width="2"
                    stroke-linecap="round"/>
              </svg>
              <span>Artist</span>
            </li>
            <li class="tabs-item" :style="{
          background: filterTab === 'EVENT' ? 'linear-gradient(90.41deg, rgba(0, 110, 233, 0.6) -2.47%, rgba(30, 150, 252, 0.36) 103.59%)' : '#FFFFFF',
          color: filterTab === 'EVENT' ? '#FFFFFF' : '#0057B7',
          fontWeight: filterTab === 'EVENT' ? 800 : 500
          }"
                @click="tabHandler('EVENT')">
              <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="mr-2">
                <path
                    d="M16.2542 19.8427H7.09021C5.54199 19.8427 5.80128 18.9082 4.83462 18.0737C4.14005 17.4741 4.02366 16.7071 4.31877 15.8791C4.70377 14.799 4.95912 13.412 6.13942 12.8805C7.1872 12.4087 8.40716 12.521 9.53292 12.521C10.5231 12.521 11.4912 12.5534 12.4611 12.7575C14.1293 13.1085 15.7532 13.6111 17.2859 14.3278C17.8432 14.5884 17.7886 14.9445 17.9686 15.5244C18.1454 16.0938 18.3394 16.6688 18.5401 17.2319C18.6463 17.5298 18.8239 17.8205 18.8334 18.14C18.8475 18.6154 18.2327 19.1282 17.8826 19.4028C17.3217 19.8428 16.9875 19.8427 16.2808 19.8427L16.2542 19.8427Z"
                    :stroke="filterTab === 'EVENT' ? '#FFFFFF' : '#0057B7'" stroke-width="2"
                    stroke-linecap="round"/>
                <path
                    d="M11.3476 7.75927C10.8058 7.99081 10.4753 7.73996 9.89298 7.73996C9.26631 7.73996 8.825 7.56001 8.31481 7.18021C7.79404 6.79254 7.07171 6.41492 6.98857 5.65538C6.90049 4.85064 6.76886 3.37207 7.46393 2.8325C8.14189 2.30622 8.9352 2.44165 9.74087 2.44165C10.4325 2.44165 10.9343 2.59021 11.571 2.78908C12.6791 3.13519 13.0138 3.85794 13.249 4.97982C13.4173 5.78274 13.3668 6.33004 12.8021 6.87139C12.3733 7.28249 11.8818 7.53091 11.3476 7.75927Z"
                    :stroke="filterTab === 'EVENT' ? '#FFFFFF' : '#0057B7'" stroke-width="2"
                    stroke-linecap="round"/>
              </svg>
              <span>Event</span>
            </li>
          </ul>
          <div class="form-genre mt-lg-10 mt-5" v-if="filterTab === 'SONG'">
            <h3 class="input-title">Genre:</h3>
            <div class="genres-wrapper">
              <v-checkbox
                  v-model="selectAll"
                  label="Select all"
              ></v-checkbox>
              <v-checkbox
                  v-for="item in genresList"
                  :key="item.value"
                  v-model="filterData.genres"
                  :label="item.name"
                  :value="item.value"
              ></v-checkbox>
            </div>
          </div>
          <div class="form-person mt-lg-10 mt-5" v-if="filterTab === 'ARTIST'">
            <h3 class="input-title">Search:</h3>
            <div class="person-wrapper">
              <input type="text" name="search-person" id="search-person" class="input w-full"
                     placeholder="Search by artist name, artist nick name" v-model="filterData.artist">
            </div>
          </div>
          <div class="form-person mt-lg-10 mt-5" v-if="filterTab === 'EVENT'">
            <h3 class="input-title">Search:</h3>
            <div class="person-wrapper">
              <input type="text" name="search-person" id="search-person" class="input w-full"
                     placeholder="Search by event name, event description" v-model="filterData.event">
            </div>
          </div>
          <div class="form-apply mt-lg-10 mt-5">
            <button class="btn-yellow">Apply</button>
          </div>
        </form>
      </div>

    </v-dialog>
  </div>
</template>

<script>


import {mapGetters} from "vuex";

export default {
  name: "SearchPageFilterPopup",
  components: {},
  data() {
    return {
      dialog: false,
      filterData: {
        genres: [],
        artist: '',
        event: ''
      },
      selectAll: false,
      filterTab: 'SONG',
      formData: new FormData()
    }
  },
  computed: {
    ...mapGetters({
      genresList: 'song/getGenresList',
      erasList: 'hallOfFame/getEraList'
    })
  },
  methods: {
    openPopup() {
      this.dialog = true
    },
    closePopup() {
      this.resetForm()
      this.dialog = false
    },
    onSubmitAction() {
      let payload = {
        type: this.filterTab
      }
      switch (this.filterTab) {
        case 'SONG':
          // this.formData.set('genres', this.filterData.genres)
          payload.body = {
            genres: this.filterData.genres
          }
          break
        case 'ARTIST':
          // this.formData.set('textSearch', this.filterData.artist)
            payload.body = {
              textSearch: this.filterData.artist
          }
          break
        case 'EVENT':
          // this.formData.set('textSearch', this.filterData.event)
            payload.body = {
              textSearch: this.filterData.event
          }
          break
      }


      this.$emit('submitFilter', payload)
      this.closePopup()
    },
    resetForm() {
      this.formData = new FormData()
      this.filterData = {
        genres: [],
        artist: '',
        event: ''
      }
      this.selectAll = false
    },
    tabHandler(tabName) {
      this.filterTab = tabName
      this.resetForm()
    }
  },
  watch: {
    selectAll(newVal) {
      if (!newVal) {
        this.filterData.genres = []
      } else {
        let res = []
        this.genresList.forEach(i => res.push(i.value))
        this.filterData.genres = res
      }
    }
  }
}
</script>

<style scoped>
.filter-popup-wrapper {
  position: relative;
  max-width: 846px;
  width: 100%;
  background: #FFFFFF;
  padding: 40px;
  margin: 0 auto;
  border-radius: 10px;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.filter-header__title {
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 33px;
  color: var(--primary-blue);
}

.placeholder {
  position: absolute;
  left: 10px;
  top: 50%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #0057B7;
  opacity: 0.5;
}

.svg {
  position: absolute;
  top: 50%;
  right: 10px;
}

input, select {
  margin-top: 15px;
}

.genres-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.form-apply {
  display: flex;
  justify-content: flex-end;
}


.tabs-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-radius: 10px;
  padding: 15px 0;
}

.tabs-item {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 13px 0;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .genres-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .filter-popup-wrapper {
    padding: 20px;
  }

}

@media only screen and (max-width: 500px) {
  .genres-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>