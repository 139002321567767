<template>
  <div data-app>
    <v-dialog
        v-model="dialog"
        max-width="846"
    >
      <div class="filter-popup-wrapper" style="max-height: calc(100vh - 100px); overflow-y: auto">
        <div class="filter-header">
          <h1 class="filter-header__title">Filter</h1>
          <button @click="closePopup">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 5.5C11.4627 8.31416 9.11674 10.9681 5 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 5.5C6.86016 8.21022 8.65254 10.1669 15 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        <form class="induct-legend-form" enctype='multipart/form-data' @submit.prevent="onSubmitAction">
          <div class="form-era mt-lg-10 mt-5">
            <h3 class="input-title">Era</h3>
            <div style="position:relative; cursor: pointer">
              <select class="input w-full" name="era" style="cursor: pointer" v-model="filterData.era">
                <option v-for="item in erasList" :key="item.name" :value="item.value" style="cursor: pointer">{{
                    item.name
                  }}
                </option>
              </select>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                   class="svg">
                <path d="M6.175 6.53345L10 10.3501L13.825 6.53345L15 7.70845L10 12.7084L5 7.70845L6.175 6.53345Z"
                      fill="#C2C9D1"/>
                <path d="M6.175 6.53345L10 10.3501L13.825 6.53345L15 7.70845L10 12.7084L5 7.70845L6.175 6.53345Z"
                      fill="black" fill-opacity="0.25"/>
              </svg>
              <span class="placeholder" v-if="!filterData.era">Era</span>

            </div>
          </div>
          <div class="form-genre mt-lg-10 mt-5">
            <h3 class="input-title">Genre:</h3>
            <div class="genres-wrapper">
              <v-checkbox
                  v-model="selectAll"
                  label="Select all"
              ></v-checkbox>
              <v-checkbox
                  v-for="item in genresList"
                  :key="item.value"
                  v-model="filterData.genres"
                  :label="item.name"
                  :value="item.value"
              ></v-checkbox>
            </div>
          </div>
          <div class="form-aply mt-lg-10 mt-5">
            <button class="btn-yellow">Apply</button>
          </div>
        </form>
      </div>

    </v-dialog>
  </div>
</template>

<script>


import {mapGetters} from "vuex";

export default {
  name: "HallOfFameFilterPopup",
  components: {},
  data() {
    return {
      dialog: false,
      filterData: {
        era: '',
        genres: []
      },
      selectAll: false
    }
  },
  computed: {
    ...mapGetters({
      genresList: 'song/getGenresList',
      erasList: 'hallOfFame/getEraList'
    })
  },
  methods: {
    openPopup() {
      this.dialog = true
    },
    closePopup() {
      this.dialog = false
    },
    async onSubmitAction() {
      this.$emit('submitFilter', this.filterData)
      this.closePopup()
    },
    resetForm() {
      this.filterData.era = ''
      this.filterData.genres = []
    }
  },
  watch: {
    selectAll(newVal) {
      if (!newVal) {
        this.filterData.genres = []
      } else {
        let res = []
        this.genresList.forEach(i => res.push(i.value))
        this.filterData.genres = res
      }
    }
  }
}
</script>

<style scoped>
.filter-popup-wrapper {
  position: relative;
  max-width: 846px;
  width: 100%;
  background: #FFFFFF;
  padding: 40px;
  margin: 0 auto;
  border-radius: 10px;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.filter-header__title {
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 33px;
  color: var(--primary-blue);
}

.placeholder {
  position: absolute;
  left: 10px;
  top: 50%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #0057B7;
  opacity: 0.5;
}

.svg {
  position: absolute;
  top: 50%;
  right: 10px;
}

input, select {
  margin-top: 15px;
}

.genres-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.form-aply {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 600px) {
  .genres-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .filter-popup-wrapper {
    padding: 20px;
  }

}

@media only screen and (max-width: 500px) {
  .genres-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>