<template>
  <div class="flex justify-center mx-auto w-7 h-7">
    <img src="../../assets/svg/navbar/home.svg" v-if="$route.path !== '/'"/>
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
      <path d="M22.4633 12.5902C21.802 18.819 16.6773 21.7607 10.2303 22.2805C3.78325 22.8003 3.45225 15.7049 1.13813 12.5902C-1.17599 9.47544 3.122 2.72734 8.90778 0.650314C14.6936 -1.42671 23.1247 6.36136 22.4633 12.5902Z" fill="#FFD700"/>
      <path d="M16.572 8.96875C16.572 10.5156 16.572 12.0625 16.572 13.6094C16.572 14.5957 16.8163 15.5252 16.8163 16.4792C16.8163 16.9132 16.9283 17.522 16.8095 17.9379C16.7065 18.2984 15.0679 16.8009 14.7402 16.7845C12.7132 16.6832 10.7025 16.9067 8.70199 16.9067C8.15641 16.9067 7.69518 17.0288 7.16869 17.0288C6.55215 17.0288 6.0281 17.273 5.45898 17.273" stroke="#0057B7" stroke-width="1.69231" stroke-linecap="round"/>
      <path d="M5.21505 17.6398C5.21505 16.3575 5.21505 15.0752 5.21505 13.7929C5.21505 12.9177 5.21505 12.0425 5.21505 11.1673C5.21505 10.5766 4.98438 8.81939 4.98438 9.41014C4.98438 9.54417 5.41313 9.81006 5.52714 9.83078C6.20379 9.95381 6.79118 10.1938 7.53536 10.0411C9.1503 9.70983 10.8719 9.55306 12.4474 9.06413C13.6911 8.67814 14.784 8.60278 16.0839 8.60278" stroke="#0057B7" stroke-width="1.69231" stroke-linecap="round"/>
      <path d="M2.65039 10.068C4.2627 9.79931 5.37761 8.03569 6.6804 7.19817C7.3974 6.73725 8.24843 6.36966 8.85145 5.76664C8.95549 5.66259 9.29432 5.35224 9.43492 5.31207C9.6793 5.24225 9.76851 4.93691 10.0387 4.8168C10.6995 4.52314 10.8655 4.4009 11.5042 4.75574C11.9909 5.0261 12.6379 5.02202 13.1257 5.30529C13.6347 5.60085 14.0767 5.86277 14.4962 6.28226C14.8579 6.64399 15.8836 7.38135 16.3891 7.38135C16.9744 7.38135 17.7601 7.88029 18.282 8.14122C18.7976 8.39902 19.2594 8.72495 19.7474 8.96894" stroke="#0057B7" stroke-width="1.69231" stroke-linecap="round"/>
      <path d="M9.24414 16.6628C9.24414 15.5026 9.24414 14.3425 9.24414 13.1823C9.24414 12.3309 10.2277 11.9428 10.9538 11.9001C11.2867 11.8805 11.6626 11.9001 11.9919 11.9001C12.3912 11.9001 12.0841 13.0947 11.9919 13.2977C11.7811 13.7614 11.7777 16.1588 12.2972 16.4186" stroke="#0057B7" stroke-width="1.69231" stroke-linecap="round"/>
    </svg>

  </div>
</template>

<script>
export default {
  name: 'NavHomeIcon',
  data() {
    return {

    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>