<template>
  <div data-app style="z-index: 100000">
    <v-dialog v-model="dialog">
      <div class="create-content-wrapper" style="max-height: calc(100vh - 100px); overflow-y: auto">
        <div class="content-header">
          <h1 class="content-header__title">New event</h1>
          <button @click="closePopup">
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 5.5C11.4627 8.31416 9.11674 10.9681 5 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 5.5C6.86016 8.21022 8.65254 10.1669 15 15.5" stroke="#0057B7" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        <CreateEventComponent :userUuid="userUuid" @closePopup="dialog = false; $emit('updateEvents')" ref="CreateEventComponent"/>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import CreateEventComponent from "@/components/CreateEventComponent";

export default {
  name: "CreateContentPopup",
  components: {CreateEventComponent},
  props: {
    userUuid: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    openPopup() {
      this.dialog = true
    },
    closePopup() {
      this.$refs.CreateEventComponent.resetForm()
      this.dialog = false
    },
  },
}
</script>

<style scoped>
.create-content-wrapper {
  max-width: 846px;
  width: 100%;
  background: #FFFFFF;
  padding: 40px;
  margin: 0 auto;
  border-radius: 10px;
}

.content-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.content-header__title {
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 33px;
  color: var(--primary-blue);
}

@media only screen and (max-width: 600px) {
  .create-content-wrapper {
    padding: 15px;
  }
}
</style>