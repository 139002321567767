import instance from "../api/instance";


export const payment = {
    namespaced: true,
    state: {
        payment: '',
        key: '',
        url: '',
        data: '',
        req_token: '',
        sign: '',
        email: '',
        errorUrl: '',
        order: '',
        onSubmit: false
    },
    getters: {
        payment: state => state.payment,
        key: state => state.key,
        url: state => state.url,
        data: state => state.data,
        req_token: state => state.req_token,
        sign: state => state.sign,
        email: state => state.email,
        errorUrl: state => state.errorUrl,
        order: state => state.order,
        onSubmit: state => state.onSubmit
    },
    mutations: {
        setPayment(state, payload) {
            state.payment = payload
        },
        setKey(state, payload) {
            state.key = payload
        },
        setUrl(state, payload) {
            state.url = payload
        },
        setData(state, payload) {
            state.data = payload
        },
        setReqToken(state, payload) {
            state.req_token = payload
        },
        setSign(state, payload) {
            state.sign = payload
        },
        setEmail(state, payload) {
            state.email = payload
        },
        setErrorUrl(state, payload) {
            state.errorUrl = payload
        },
        setOrder(state, payload) {
            state.order = payload
        },
        setOnSubmit(state, payload) {
            state.onSubmit = payload
        },
        reset(state) {
            state.payment = ''
            state.key = ''
            state.url = ''
            state.data = ''
            state.req_token = ''
            state.sign = ''
            state.email = ''
            state.errorUrl = ''
            state.order = ''
            state.onSubmit = false
        }
    },
    actions: {
        async purchase(context, body) {
            let { payment, key, url, data, reqToken, email, sign, errorUrl, order } = (await instance.post(`/api/v1/payments/purchases`, body)).data.body
            context.commit('setPayment', payment)
            context.commit('setKey', key)
            context.commit('setUrl', url)
            context.commit('setData', data)
            context.commit('setReqToken', reqToken)
            context.commit('setEmail', email)
            context.commit('setSign', sign)
            context.commit('setErrorUrl', errorUrl)
            context.commit('setOrder', order)
            context.commit('setOnSubmit', !context.state.onSubmit)
        },
    }
}