<template>
  <li class="d-flex align-center" v-click-outside="closeMenu">
    <SongItemComponent :song="song" :ref="`audio-player-${identifier}-${index}`" :showDuration="showDuration"/>
    <slot></slot>
    <svg v-if="showMenu" width="28" height="7" viewBox="0 0 28 7" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-8 mr-3" @click="toggleMenu">
      <path d="M5.5 3.50049C5.5 5.15734 4.65685 6.50049 3 6.50049C1.34315 6.50049 0 5.15734 0 3.50049C0 1.84363 1.34315 0.500488 3 0.500488C4.65685 0.500488 5.5 1.84363 5.5 3.50049Z" fill="#0057B7"/>
      <path d="M17 3.50049C17 5.15734 15.6569 6.50049 14 6.50049C12.3431 6.50049 10.5 5.15637 10.5 3.49951C10.5 1.84266 11.8431 0.499512 13.5 0.499512C15.1569 0.499512 17 1.84363 17 3.50049Z" fill="#0057B7"/>
      <path d="M28.0001 3.50049C28.0001 5.15734 26.6758 6.17732 25.0189 6.17732C23.3621 6.17732 22.0189 4.83417 22.0189 3.17732C22.0189 1.52047 23.3432 0.500488 25.0001 0.500488C26.6569 0.500488 28.0001 1.84363 28.0001 3.50049Z" fill="#0057B7"/>
    </svg>
    <div class="menu" v-if="isMenuOpen">
      <div class="delete" @click="deleteSongRequest">Delete</div>
<!--      <div class="archive">Archive</div>-->
    </div>
  </li>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import SongItemComponent from "@/components/SongItemComponent";
export default  {
  name: "SongItemTemplateComponent",
  components: {SongItemComponent},
  props: {
    song: Object,
    identifier: String,
    index: Number,
    showDuration: {
      type: Boolean,
      default: true
    },
    showMenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mp3: null,
      isMenuOpen: false
    }
  },
  computed: {
    ...mapGetters({
      current: 'song/getCurrent'
    })
  },
  methods: {
    ...mapActions({
      deleteSong: 'song/deleteSong'
    }),
    closeMenu() {
      this.isMenuOpen = false
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    async deleteSongRequest() {
      const payload = {
        uuid: this.$route.query.userUuid,
        songId: this.song.id
      }
      await this.deleteSong(payload)
      this.$emit('songDeleted')
    }
  },
  watch: {
    current(newVal) {
      if (newVal !== this.song.songName) {
        this.$refs[`audio-player-${this.identifier}-${this.index}`].refreshSong()
      }
    }
  },
  updated() {
    this.$refs[`audio-player-${this.identifier}-${this.index}`].refreshSong()
  },
  mounted() {
    this.mp3 = this.song.fileUrl
  },
  beforeDestroy() {
    this.$refs[`audio-player-${this.identifier}-${this.index}`].refreshSong()
  },
}
</script>

<style scoped>
li {
  margin-bottom: 20px;
  position: relative;
}

li:last-child {
  margin-bottom: 0;
}

.menu {
  position: absolute;
  right: 10px;
  bottom: -40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 20px;
  background: #FFFFFF;
  width: 150px;
  height: 50px;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  z-index: 1000;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #0057B7;
}

svg {
  cursor: pointer;
}

.delete, .archive {
  cursor: pointer;
}

.delete:hover, .archive:hover {
  text-decoration: underline;
}


</style>