import { render, staticRenderFns } from "./ProfileContentComponent.vue?vue&type=template&id=f2d570be&scoped=true&"
import script from "./ProfileContentComponent.vue?vue&type=script&lang=js&"
export * from "./ProfileContentComponent.vue?vue&type=script&lang=js&"
import style0 from "./ProfileContentComponent.vue?vue&type=style&index=0&id=f2d570be&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2d570be",
  null
  
)

export default component.exports