<template>
  <form class="reset-password-form" @submit.prevent="onSubmitAction">
    <div class="input-wrapper">
      <div style="position:relative;">
        <input :type="togglePassword ? 'text' : 'password'" name="password" id="reset-password" class="input w-full"
               placeholder="New Password" v-model="password"
               :style="{borderColor: v$.password.$error ? 'red' : '#C2C9D1'}">
        <div class="toggle-password" @click="togglePassword = !togglePassword">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1426_116035)">
              <path
                  d="M1.6665 10.4166C1.6665 10.4166 5.99336 4.82958 10.4165 4.99997C14.4815 5.15657 19.1666 9.99998 19.1666 9.99998C19.1666 9.99998 15.8332 15.4166 9.99984 15.4166C4.1665 15.4166 1.6665 10.4166 1.6665 10.4166Z"
                  stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                  d="M10.4167 12.0833C11.7974 12.0833 12.9167 10.964 12.9167 9.58333C12.9167 8.20262 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 9.03595 12.0833 10.4167 12.0833Z"
                  stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_1426_116035">
                <rect width="20" height="20" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="svg-cross-line" v-if="togglePassword" @click="togglePassword = !togglePassword"></div>
      </div>
      <p class="error" v-for="error of v$.password.$errors" :key="error.$uid">
        {{ error.$message }}
      </p>
    </div>
    <div class="input-wrapper">
      <div style="position:relative;">
        <input :type="toggleConfirmPassword ? 'text' : 'password'" name="repeated-password" id="reset-repeated-password"
               class="input w-full mt-7" placeholder="Repeat New Password" v-model="repeatedPassword"
               :style="{borderColor: v$.repeatedPassword.$error ? 'red' : '#C2C9D1'}">
        <div class="toggle-password" @click="toggleConfirmPassword = !toggleConfirmPassword">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1426_116035)">
              <path
                  d="M1.6665 10.4166C1.6665 10.4166 5.99336 4.82958 10.4165 4.99997C14.4815 5.15657 19.1666 9.99998 19.1666 9.99998C19.1666 9.99998 15.8332 15.4166 9.99984 15.4166C4.1665 15.4166 1.6665 10.4166 1.6665 10.4166Z"
                  stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                  d="M10.4167 12.0833C11.7974 12.0833 12.9167 10.964 12.9167 9.58333C12.9167 8.20262 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 9.03595 12.0833 10.4167 12.0833Z"
                  stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_1426_116035">
                <rect width="20" height="20" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="svg-cross-line" v-if="toggleConfirmPassword"
             @click="toggleConfirmPassword = !toggleConfirmPassword"></div>
      </div>
      <p class="error" v-for="error of v$.repeatedPassword.$errors" :key="error.$uid">
        {{ error.$message }}
      </p>
    </div>
    <div class="mt-5 password-rules">
      <span :style="{color: hasMinimumLength ? 'green' : 'red', fontWeight: hasMinimumLength ? '600' : '400'}">8 or more characters</span>,
      <span
          :style="{color: hasLowercase ? 'green' : 'red', fontWeight: hasLowercase ? '600' : '400'}">Lowercase letter</span>,
      <span
          :style="{color: hasUppercase ? 'green' : 'red', fontWeight: hasUppercase ? '600' : '400'}">Uppercase letter</span>,
      <span :style="{color: hasSpecial ? 'green' : 'red', fontWeight: hasSpecial ? '600' : '400'}">Symbols</span>,
      <span :style="{color: hasNumber ? 'green' : 'red', fontWeight: hasNumber ? '600' : '400'}">Numbers</span>
    </div>
    <button type="submit" class="btn-yellow w-full submit-button">Send</button>
  </form>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {helpers, required, sameAs} from "@vuelidate/validators";

export default {
  name: "ResetPasswordForm",
  setup() {
    return {v$: useVuelidate({$lazy: true})}
  },
  data() {
    return {
      password: '',
      repeatedPassword: '',
      hasMinimumLength: false,
      hasNumber: false,
      hasLowercase: false,
      hasUppercase: false,
      hasSpecial: false,
      togglePassword: false,
      toggleConfirmPassword: false
    }
  },
  validations() {
    return {
      password: {
        required: helpers.withMessage(`This field is required`, required),
      },
      repeatedPassword: {
        required: helpers.withMessage(`This field is required`, required),
        sameAsPassword: helpers.withMessage('The passwords must match', sameAs(this.password)),
      },
    }
  },
  methods: {
    async onSubmitAction() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        return false
      } else {
        this.$emit('submitted', this.password)
      }
    },
    resetForm() {
      this.password = ''
      this.repeatedPassword = ''
      this.hasMinimumLength = false
      this.hasNumber = false
      this.hasLowercase = false
      this.hasUppercase = false
      this.hasSpecial = false
      this.v$.$reset()
    }
  },
  watch: {
    password(newVal) {
      this.hasMinimumLength = (newVal.length >= 8);
      this.hasNumber = /\d/.test(newVal);
      this.hasLowercase = /[a-z]/.test(newVal);
      this.hasUppercase = /[A-Z]/.test(newVal);
      this.hasSpecial = /[#?!@$%/^()}{><.&|:;`',*~"-]/.test(newVal);
      if (this.hasMinimumLength && this.hasNumber && this.hasLowercase && this.hasUppercase && this.hasSpecial) {
        this.requireRulesForPassword = true;
      } else {
        this.requireRulesForPassword = false;
      }
    },
  },
}
</script>

<style scoped>
.reset-password-form {
  margin-top: 40px;
  width: 100%;
}

input {
  background: #FFFFFF;
}

.submit-button {
  margin-top: 40px;
}

.error {
  /*position: absolute;*/
  /*left: 0;*/
  /*bottom: -18px;*/
  font-size: 14px;
}

.input-wrapper {
  position: relative;
}

.password-rules {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  opacity: 0.5;
}

.toggle-password {
  position: absolute;
  right: 10px;
  bottom: 0;
  transform: translateY(-50%);
  line-height: 0;
  cursor: pointer;
}

.svg-cross-line {
  position: absolute;
  bottom: 10px;
  right: 18px;
  height: 21px;
  width: 2px;
  background: #0057B7;
  transform: rotate(56deg);
  cursor: pointer;
}
</style>