<template>
  <div>
    <div class="button-back mobile-header mb-8">
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-4 mobile"
           @click="$router.back()">
        <rect width="30" height="30" rx="15" fill="#0057B7"/>
        <path d="M13.413 9L9.5 15M9.5 15L13.413 21M9.5 15L21.5 15" stroke="#FFD60A" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <div class="playlist-page-wrapper" ref="top">
      <div class="d-flex" v-if="playlist">
        <img loading="lazy" :src="playlist.playListCoverThumbnail" alt="Ukrmusic" v-if="playlist.playListCoverThumbnail"
             class="playlist-cover">
        <div class="d-flex flex-column justify-center">
          <h2 class="playlist-name">{{ playlist.playListName }}</h2>
          <div class="d-flex align-center playlist-songs-length">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
              <path
                  d="M6.5356 13.9745C6.5356 14.0208 6.06962 16.9115 5.21813 16.9905C3.66596 17.1346 0.78191 15.6122 1.01308 14.1496C1.21255 12.8876 2.73862 12.4408 4.04225 12.4408C5.10909 12.5218 5.724 13.3187 6.5356 13.9745ZM6.5356 13.9745C7.04075 11.914 6.86762 12.6707 7.22438 10.5298C7.53484 8.6666 7.46844 6.79767 7.77391 4.93798C7.84099 4.52964 7.68525 3.91758 8.21709 3.74555C9.6104 3.29488 10.9223 2.68309 12.3297 2.24207C13.7102 1.80951 15.2452 1.48691 16.5665 1.00521C16.9795 0.854625 16.7969 4.01097 16.7969 5.1972C16.7969 7.36972 16.8576 9.52765 16.9796 11.6961M16.9796 11.6961C17.0186 12.39 17.0106 12.5608 16.8767 13.085C16.3957 14.9683 13.949 15.8692 12.959 15.692C11.6507 15.4577 10.4897 14.5244 10.6102 13.3442C10.7648 11.831 11.998 11.2544 13.739 11.1297C14.7189 11.0595 16.9405 11.0022 16.9796 11.6961Z"
                  stroke="#0057B7" stroke-width="2" stroke-linecap="round"/>
            </svg>
            {{ playlist.songs.length }}
          </div>
        </div>
      </div>
      <div class="playlist-search-wrap mt-10">
        <input type="search" name="playlist-search" id="playlist-search" class="input w-full" placeholder="Search"
               v-model="search">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"
             class="playlist-search-icon">
          <path
              d="M10.0833 17.4167C14.1334 17.4167 17.4167 13.2167 17.4167 9.16666C17.4167 5.11657 14.1334 1.83333 10.0833 1.83333C6.03324 1.83333 2.75 6.03324 2.75 10.0833C2.75 14.1334 6.03324 17.4167 10.0833 17.4167Z"
              stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M19.2502 19.25L15.2627 15.2625" stroke="#0057B7" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
      </div>
      <div class="playlist-songs-list-wrap mt-10">
        <ul class="songs-list" v-if="playlist && playlist.songs.length">
          <SongItemTemplateComponent
              v-for="(song, index) in playlist.songs"
              :key="index"
              :song="song"
              :identifier="'playlist-song-component'"
              :index="index"
          />
        </ul>
      </div>
      <div class="playlist-also-listen mt-10" v-if="getPlayLists">
        <h2 class="playlist-name">Also listen</h2>
        <div class="d-flex flex-wrap justify-space-between">
          <PlayListItemComponent
              v-for="(playList, index) in getPlayLists"
              :key="index"
              :playList="playList"
              :forPage="'my-songs'"
              ref="PlayListItemComponent"
              :userUuid="$route.query.userUuid"
          />
        </div>
      </div>
      <ButtonBack/>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SongItemTemplateComponent from "@/components/SongItemTemplateComponent";
import PlayListItemComponent from "@/components/PlayListItemComponent";
import ButtonBack from "@/components/elements/ButtonBack.vue";

export default {
  name: "PlaylistPageView",
  components: {
    ButtonBack,
    SongItemTemplateComponent,
    PlayListItemComponent
  },
  data() {
    return {
      playlist: null,
      search: '',
      page: 1,
      count: null,
      pageSize: 4
    }
  },
  computed: {
    ...mapGetters({
      getPlayLists: 'products/getPlayLists'
    })
  },
  methods: {
    ...mapActions({
      getPlaylistRequest: 'products/getOnePlayListRequest',
      getAllPlayListRequest: 'products/getAllPlayListRequest'
    }),
    check() {

    },
    async playListRequest() {
      const uuid = this.$route.query.userUuid
      let payload = {
        uuid: uuid,
        playlistId: this.$route.params.id
      }
      this.playlist = await this.getPlaylistRequest(payload)
    },
    async allPlayListsRequest() {
      const uuid = this.$route.query.userUuid
      const payload = {
        uuid: uuid,
        params: this.getRequestParams(this.page, this.pageSize)
      }
      let res = await this.getAllPlayListRequest(payload)
      this.count = res.totalPages
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }

      return params;
    },
  },
  updated() {
    // console.log('updated')
  },
  created() {
    this.playListRequest()
    this.allPlayListsRequest()
  },

  watch: {
    '$route.params.id'() {
      this.playListRequest()
      this.$refs.top.scrollIntoView({behavior: "smooth"})
    }
  },
  mounted() {
    document.title = 'Play list'
    this.$refs.top.scrollIntoView({behavior: "smooth"})
  }
}
</script>

<style scoped>
.playlist-page-wrapper {
  position: relative;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 40px;
}

.playlist-cover {
  width: 128px;
  height: 128px;
  border-radius: 10px;
  margin-right: 50px;
}

.playlist-name {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  color: var(--primary-blue);
  margin-bottom: 25px;
}

.playlist-songs-length {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: var(--primary-blue);
}

.playlist-search-wrap {
  position: relative;
}

.playlist-search-wrap input {
  padding: 15px 45px;
}

.playlist-search-icon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.playlist-songs-list-wrap {
  max-height: 400px;
  overflow-y: auto;
}

@media only screen and (max-width: 600px) {
  .playlist-page-wrapper {
    padding: 20px 15px;
  }
}
</style>