<template>
  <div class="d-flex justify-space-between align-center mb-5">
    <div class="d-flex align-center">
      <img loading="lazy" :src="sub.follower.avatar" alt="Ukrmusic" class="sub-image" v-if="sub.follower.avatar">
      <img src="../assets/img/default-avatar.png" alt="Ukrmusic" class="sub-image" v-else>
      <span class="sub-name">{{ sub.follower.nickName }}</span>
    </div>
    <div class="d-flex align-center">
      <p class="mr-5 sub-from">Subscribed from:</p>
      <span class="sub-date-from">{{ dateFromWithoutTime }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubscriberItemComponent",
  props: {
    sub: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    dateFromWithoutTime() {
      return this.sub.subscribeAt.split(' ')[0]
    }
  }
}
</script>

<style scoped>
.sub-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 20px;
}

.sub-name, .sub-date-from {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-blue);
}

@media only screen and (max-width: 600px) {
  .sub-image {
    width: 36px;
    height: 36px;
    margin-right: 8px;
  }

  .sub-name {
    font-size: 14px;
  }

  .sub-from, .sub-date-from {
    font-size: 12px;
  }
}
</style>