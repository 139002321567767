<template>
  <form class="reset-password-form" @submit.prevent="onSubmitAction">
    <div class="input-wrapper">
      <input type="email" name="email" id="reset-password-email" class="input w-full email-input" placeholder="Email" v-model="email" :style="{borderColor: v$.email.$error ? 'red' : '#C2C9D1'}">
      <p class="error" v-for="error of v$.email.$errors" :key="error.$uid">
        {{ error.$message }}
      </p>
    </div>
    <button type="submit" class="btn-yellow w-full submit-button">Send</button>
  </form>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {email, helpers, required} from "@vuelidate/validators";

export default {
  name: "ResetEmailForm",
  setup() {
    return {v$: useVuelidate({$lazy: true})}
  },
  data() {
    return {
      email: ''
    }
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage(`Email is required`, required),
        email: email
      }
    }
  },
  methods: {
    async onSubmitAction() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        return false
      } else {
        this.$emit('submitted', this.email)
      }
    },
    resetForm() {
      this.email = ''
      this.v$.$reset()
    }
  },
}
</script>

<style scoped>
.reset-password-form {
  margin-top: 40px;
  width: 100%;
}

.email-input {
  background: #FFFFFF;
}

.submit-button {
  margin-top: 40px;
}

.error {
  position: absolute;
  left: 0;
  bottom: -20px;
}

.input-wrapper {
  position: relative;
}
</style>