<template>
  <div class="video-congratz-wrapper align-self-start" ref="top">
    <div class="mobile-header mb-8">
      <div class="d-flex align-center justify-space-between w-full">
        <h2 class="page-title">My video congratulations</h2>
      </div>
    </div>
    <h1 class="page-title desktop">My video congratulations</h1>
    <div class="video-search w-full input mt-10 bg-white">
      <input type="search" id="video-search" placeholder="Search" class="w-full">
      <div class="search-icon">
        <img src="../assets/svg/icons/search.svg" alt="Ukrmusic">
      </div>
    </div>
    <div class="video-order-list mt-10" v-if="videoOrders && videoOrders.length">
      <VideoCongratulationOrderComponent
          v-for="(item, index) in videoOrders"
          :item="item"
          :key="index"
          :index="index"
          :isArtist="false"
          identity="user-video"
          @viewOffer="viewOfferHandler"
      />
    </div>
    <div class="video-order-list-else mt-10" v-else>
      <p class="mb-5">Here you can see orders from your subscribers for video congratulations. You can set the price and rules for your congratulations yourself, as well as accept and reject offers</p>
      <p>At the moment, you don’t have offers</p>
    </div>
    <ButtonBack />
    <VideoOfferForUserPopup ref="VideoOfferForUserPopup" :offerData="dataForPopup"/>
  </div>
</template>

<script>
import ButtonBack from "@/components/elements/ButtonBack.vue";
import VideoCongratulationOrderComponent from "@/components/VideoCongratulationOrderComponent.vue";
import {mapActions} from "vuex";
import VideoOfferForUserPopup from "@/components/popups/VideoOfferForUserPopup.vue";

export default {
  name: "VideoCongratulationsView",
  components: {VideoOfferForUserPopup, VideoCongratulationOrderComponent, ButtonBack},
  data() {
    return {
      videoOrders: null,
      dataForPopup: null
    }
  },
  methods: {
    ...mapActions({
      getVideoOrders: 'video/getVideoOrdersAction'
    }),
    async getVideoOrdersRequest() {
      let payload = {
        isArtistFlow: false
      }
      this.videoOrders = await this.getVideoOrders(payload)
    },
    viewOfferHandler(data) {
      this.dataForPopup = data
      this.$refs.VideoOfferForUserPopup.openPopup()
    }
  },
  created() {
    this.getVideoOrdersRequest()
  }
}
</script>

<style scoped>
.video-congratz-wrapper {
  position: relative;
}

.video-search {
  position: relative;
  padding: 15px 47px;
}

.video-search input {
  color: var(--primary-blue-inputs);
}

.search-icon {
  height: 24px;
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.video-order-list {
  background: #FFFFFF;
  padding: 20px;
  max-height: 550px;
  overflow-y: auto;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
}

.video-order-list-else {
  background: #FFFFFF;
  padding: 0 120px;
  height: 220px;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.video-order-list-else p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #0057B7;
}

.desktop {
  display: block;
}

@media only screen and (max-width: 600px) {
  .desktop {
    display: none;
  }

  .video-order-list-else {
    padding: 20px 15px;
    height: 100%;
  }
}
</style>