import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import '../src/assets/main.css'
import '../src/assets/normalize.css'
import colors from './assets/variables.css'
import store from './store'
import ApiPlugin from './plugins/api'
import VueLoadmore from 'vuejs-loadmore';
import VueChatScroll from 'vue-chat-scroll'


Vue.config.productionTip = false
Vue.use(ApiPlugin)
Vue.use(VueLoadmore);
Vue.use(VueChatScroll)
// Vue.component('buttonBlue', ButtonBlue)


Vue.mixin({
    data() {
        return {
            appColors: colors
        }
    }
})


new Vue({
    router,
    vuetify,
    store,

    render: function (h) {
        return h(App)
    }
}).$mount('#app')
