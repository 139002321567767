import instance from "../api/instance";


export const comments = {
    namespaced: true,
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        async getPostComments(context, payload) {
            let postId = payload.postId
            let params = payload.params
            return (await instance.get(`/api/v1/comments/${postId}`, params)).data.body
        },
        async getCommentsReplies(context, payload) {
            let postId = payload.postId
            let params = payload.params
            let commentId = payload.commentId
            return (await instance.get(`/api/v1/comments/${postId}/${commentId}`, params)).data.body
        },
        async addPostComment(context, payload) {
            let postId = payload.postId
            let body = payload.body
            return (await instance.post(`/api/v1/comments/${postId}`, body)).data.body
        },
        async addCommentReply(context, payload) {
            let postId = payload.postId
            let body = payload.body
            let commentId = payload.commentId
            return (await instance.post(`/api/v1/comments/${postId}/${commentId}`, body)).data.body
        },
    }
}