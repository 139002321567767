import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProfileView from "@/views/ProfileView";
import LoginView from "@/views/LoginView";
import ProfileSongAndPlaylistView from "@/views/ProfileSongAndPlaylistView";
import ProfileEventsView from "@/views/ProfileEventsView";
import EventView from "@/views/EventView";
import CreatePlaylistView from "@/views/CreatePlaylistView";
import PlaylistPageView from "@/views/PlaylistPageView";
import ProfileSubscribersView from "@/views/ProfileSubscribersView.vue";
import ProfileVideoCongratzView from "@/views/ProfileVideoCongratzView.vue";
import VideoCongratulationsView from "@/views/VideoCongratulationsView.vue";
import Test from "@/components/Test";
import ChatView from "@/views/ChatView";
import SignUpView from "@/views/SignUpView";
import HallOfFameView from "@/views/HallOfFameView";
import HallOfFameArtistPage from "@/components/hall-of-fame/HallOfFameArtistPage";
import PrivacyPolicyTemplate from "@/components/static/PrivacyPolicyTemplate";
import TermsAndConditionsTemplate from "@/components/static/TermsAndConditionsTemplate";
import InductALegendComponent from "@/components/hall-of-fame/InductALegendComponent";
import HallOfFamePlaylistPage from "@/components/hall-of-fame/HallOfFamePlaylistPage";
import SearchView from "@/views/SearchView";
import ResetPasswordView from "@/views/ResetPasswordView";
import NotFoundComponent from "@/components/NotFoundComponent";

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'not found',
    component: NotFoundComponent
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/profile/:nickname',
    name: 'profile',
    component: ProfileView,
    meta: { requiresAuth: false },
  },
  {
    path: '/profile/:nickname/songs',
    name: 'profile my songs',
    component: ProfileSongAndPlaylistView,
    meta: { requiresAuth: false },
  },
  {
    path: '/profile/:nickname/playlist/:id',
    name: 'playlist',
    component: PlaylistPageView,
  },
  {
    path: '/create-playlist',
    name: 'create playlist',
    component: CreatePlaylistView,
  },
  {
    path: '/profile/:nickname/events',
    name: 'profile events',
    component: ProfileEventsView,
    meta: { requiresAuth: false },
  },
  {
    path: '/profile/:nickname/events/:id',
    name: 'event',
    component: EventView,
  },
  {
    path: '/profile/:nickname/subscribers',
    name: 'subscribers',
    component: ProfileSubscribersView,
  },
  {
    path: '/profile/:nickname/video',
    name: 'profile video congratulations',
    component: ProfileVideoCongratzView,
  },
  {
    path: '/video',
    name: 'video congratulations',
    component: VideoCongratulationsView,
  },
  {
    path: '/sign-in',
    name: 'login',
    component: LoginView
  },
  {
    path: '/sign-up',
    name: 'sign up',
    component: SignUpView
  },
  {
    path: '/chat',
    name: 'chat',
    component: ChatView,
    meta: { requiresAuth: false },
  },
  {
    path: '/hall-of-fame',
    name: 'hall of fame',
    component: HallOfFameView,
  },
  {
    path: '/hall-of-fame/:uuid',
    name: 'hall of fame legend page',
    component: HallOfFameArtistPage
  },
  {
    path: '/playlist/hall-of-fame',
    name: 'hall of fame playlist',
    component: HallOfFamePlaylistPage
  },
  {
    path: '/induct-a-legend',
    name: 'induct a legend',
    component: InductALegendComponent
  },
  {
    path: '/test',
    name: 'test',
    component: Test
  },
  {
    path: '/privacy-policy',
    name: 'privacy policy',
    component: PrivacyPolicyTemplate
  },
  {
    path: '/terms-and-conditions',
    name: 'terms and conditions',
    component: TermsAndConditionsTemplate
  },
  {
    path: '/search',
    name: 'search',
    component: SearchView
  },
  {
    path: '/password/reset',
    name: 'reset password',
    component: ResetPasswordView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('isLogged') == null) {
      return next('/sign-in');
    } else {
      return next()
    }

  } else {
    return next();
  }
})

export default router
