<template>
  <div class="flex justify-center mx-auto w-7 h-7">
    <img src="../../assets/svg/navbar/profile.svg" v-if="!matchProfile"/>
    <img src="../../assets/svg/navbar/profile-active.svg" v-else/>
  </div>
</template>

<script>
export default {
  name: 'NavNotificationsIcon',
  data() {
    return {

    }
  },
  computed: {
    matchProfile() {
      return this.$route.path.includes('profile')
    }
  },
}
</script>

<style scoped>

</style>