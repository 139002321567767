<template>
  <div class="hall-of-fame-page-wrapper align-self-start" ref="top">
    <div class="hall-of-fame__header desktop">
      <div class="left">
        <h1 class="page-title">Hall of music Legends</h1>
      </div>
      <div class="right">
        <!--        <div class="total-vp">You have: <span>{{ `${0}VP` }}</span></div>-->
        <button class="btn-yellow" @click="$router.push({path: '/induct-a-legend'})">Induct a Legend</button>
      </div>
    </div>
    <div class="mobile-header mobile justify-space-between">
      <div class="left">
        <h1 class="page-title">Hall of fame</h1>
      </div>
      <div class="right">
        <button class="btn-yellow" @click="$router.push({path: '/induct-a-legend'})">Induct a Legend</button>
      </div>
    </div>
    <div class="hall-of-fame__playlist" v-if="getCase === 'case2'">
      <div class="playlist-header">
        <div class="header-cover">
          <img loading="lazy" src="../assets/img/legends-playlist-cover.png" alt="Ukrmusic" class="playlist-cover">
        </div>
        <div class="header-content">

          <h2 class="playlist-name">The Legends Playlist</h2>
          <div class="d-flex align-center">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
              <path
                  d="M6.5356 13.9745C6.5356 14.0208 6.06962 16.9115 5.21813 16.9905C3.66596 17.1346 0.78191 15.6122 1.01308 14.1496C1.21255 12.8876 2.73862 12.4408 4.04225 12.4408C5.10909 12.5218 5.724 13.3187 6.5356 13.9745ZM6.5356 13.9745C7.04075 11.914 6.86762 12.6707 7.22438 10.5298C7.53484 8.6666 7.46844 6.79767 7.77391 4.93798C7.84099 4.52964 7.68525 3.91758 8.21709 3.74555C9.6104 3.29488 10.9223 2.68309 12.3297 2.24207C13.7102 1.80951 15.2452 1.48691 16.5665 1.00521C16.9795 0.854625 16.7969 4.01097 16.7969 5.1972C16.7969 7.36972 16.8576 9.52765 16.9796 11.6961M16.9796 11.6961C17.0186 12.39 17.0106 12.5608 16.8767 13.085C16.3957 14.9683 13.949 15.8692 12.959 15.692C11.6507 15.4577 10.4897 14.5244 10.6102 13.3442C10.7648 11.831 11.998 11.2544 13.739 11.1297C14.7189 11.0595 16.9405 11.0022 16.9796 11.6961Z"
                  stroke="#0057B7" stroke-width="2" stroke-linecap="round"/>
            </svg>
            <span class="playlist-songs-amount">{{ legendSongsAmount }}</span>
          </div>
        </div>
      </div>
      <button class="btn-yellow w-full mt-5 mt-sm-10" @click="$router.push({path: '/playlist/hall-of-fame'})">View
        Playlist
      </button>

    </div>
    <div class="hall-of-fame__about" v-else>
      <div class="about-wrapper">
        <h2 class="about-title">About voting</h2>
        <p>
          You can nominate music and people of your choice to the Hall of Legends. Your nominee will be inducted to the
          Hall of Legends if being supported by other users and experts through the transparent voting process. When it
          happens you will get credits related with your Legend.
        </p>
      </div>
      <img src="../assets/img/ukrmusic-singer.png" alt="ukrmusic">
    </div>
    <div class="hall-of-fame__tabs">
      <ul class="tabs-wrapper">
        <li class="tabs-item" :style="{
          background: generalTab === 'SONG' ? '#FFFFFF' : '',
          color: generalTab === 'SONG' ? '#0057B7' : '#FFFFFF',
          fontWeight: generalTab === 'SONG' ? 800 : 500
        }"
            @click="$router.push({path: `/hall-of-fame`, query: {generalTab: 'SONG', contentTab: 'voting', page: '0'}}).catch(() => {})"
        >
          <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
            <path
                d="M8.72755 17.189C8.72755 17.2411 8.20332 20.4931 7.24539 20.582C5.49921 20.7441 2.25465 19.0314 2.51472 17.386C2.73912 15.9662 4.45595 15.4636 5.92253 15.4636C7.12273 15.5548 7.81451 16.4512 8.72755 17.189ZM8.72755 17.189C9.29585 14.8709 9.10107 15.7222 9.50242 13.3137C9.8517 11.2176 9.777 9.11506 10.1207 7.02291C10.1961 6.56353 10.0209 5.87496 10.6192 5.68143C12.1867 5.17442 13.6626 4.48616 15.246 3.99001C16.7989 3.50338 18.5258 3.14045 20.0123 2.59854C20.477 2.42913 20.2716 5.98003 20.2716 7.31453C20.2716 9.75862 20.3398 12.1863 20.477 14.6258M20.477 14.6258C20.5209 15.4065 20.5119 15.5986 20.3613 16.1883C19.8202 18.3071 17.0677 19.3206 15.9539 19.1212C14.482 18.8576 13.1759 17.8077 13.3115 16.4799C13.4854 14.7775 14.8727 14.1289 16.8314 13.9886C17.9338 13.9096 20.4331 13.8451 20.477 14.6258Z"
                :stroke="generalTab === 'SONG' ? '#0057B7' : '#FFFFFF'" stroke-width="2" stroke-linecap="round"/>
          </svg>
          <span>Music work</span>
        </li>
        <li class="tabs-item" :style="{
          background: generalTab === 'PERSON' ? '#FFFFFF' : '',
          color: generalTab === 'PERSON' ? '#0057B7' : '#FFFFFF',
          fontWeight: generalTab === 'PERSON' ? 800 : 500
          }"
            @click="$router.push({path: `/hall-of-fame`, query: {generalTab: 'PERSON', contentTab: 'voting', page: '0'}}).catch(() => {})"
        >
          <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
            <path
                d="M16.2542 19.8427H7.09021C5.54199 19.8427 5.80128 18.9082 4.83462 18.0737C4.14005 17.4741 4.02366 16.7071 4.31877 15.8791C4.70377 14.799 4.95912 13.412 6.13942 12.8805C7.1872 12.4087 8.40716 12.521 9.53292 12.521C10.5231 12.521 11.4912 12.5534 12.4611 12.7575C14.1293 13.1085 15.7532 13.6111 17.2859 14.3278C17.8432 14.5884 17.7886 14.9445 17.9686 15.5244C18.1454 16.0938 18.3394 16.6688 18.5401 17.2319C18.6463 17.5298 18.8239 17.8205 18.8334 18.14C18.8475 18.6154 18.2327 19.1282 17.8826 19.4028C17.3217 19.8428 16.9875 19.8427 16.2808 19.8427L16.2542 19.8427Z"
                :stroke="generalTab === 'PERSON' ? '#0057B7' : '#FFFFFF'" stroke-width="2"
                stroke-linecap="round"/>
            <path
                d="M11.3476 7.75927C10.8058 7.99081 10.4753 7.73996 9.89298 7.73996C9.26631 7.73996 8.825 7.56001 8.31481 7.18021C7.79404 6.79254 7.07171 6.41492 6.98857 5.65538C6.90049 4.85064 6.76886 3.37207 7.46393 2.8325C8.14189 2.30622 8.9352 2.44165 9.74087 2.44165C10.4325 2.44165 10.9343 2.59021 11.571 2.78908C12.6791 3.13519 13.0138 3.85794 13.249 4.97982C13.4173 5.78274 13.3668 6.33004 12.8021 6.87139C12.3733 7.28249 11.8818 7.53091 11.3476 7.75927Z"
                :stroke="generalTab === 'PERSON' ? '#0057B7' : '#FFFFFF'" stroke-width="2"
                stroke-linecap="round"/>
          </svg>
          <span>Music creator</span>
        </li>
      </ul>
    </div>
    <div class="hall-of-fame__content">
      <div class="content-header">
        <div class="content-header__tabs">
          <ul class="content-tabs-wrapper">
            <li class="content-tab-item" :style="{
          background: contentTab === 'voting' ? 'linear-gradient(90.41deg, rgba(0, 110, 233, 0.6) -2.47%, rgba(30, 150, 252, 0.36) 103.59%)' : '#FFFFFF',
          color: contentTab === 'voting' ? '#FFFFFF' : '#0057B7',
          fontWeight: contentTab === 'voting' ? 800 : 500
        }" @click="contentTabSwitchTo('voting')">On the voting
            </li>
            <li class="content-tab-item" :style="{
          background: contentTab === 'legends' ? 'linear-gradient(90.41deg, rgba(0, 110, 233, 0.6) -2.47%, rgba(30, 150, 252, 0.36) 103.59%)' : '#FFFFFF',
          color: contentTab === 'legends' ? '#FFFFFF' : '#0057B7',
          fontWeight: contentTab === 'legends' ? 800 : 500
        }" @click="contentTabSwitchTo('legends')">The Legends
            </li>
          </ul>
        </div>
        <div class="content-header__search">
          <div class="search-input">
            <input type="search" name="search" id="hall-of-fame-search" class="input" placeholder="Search"
                   v-model="searchText" @input="debounceRequest">
            <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M11.9167 21.176C16.7031 21.176 20.5833 16.2125 20.5833 11.426C20.5833 6.63952 16.7031 2.75932 11.9167 2.75932C7.1302 2.75932 3.25 7.72285 3.25 12.5093C3.25 17.2958 7.1302 21.176 11.9167 21.176Z"
                  stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M22.7496 23.3426L18.0371 18.6301" stroke="#0057B7" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>

          </div>
          <div class="filter" @click="$refs.HallOfFameFilterPopup.openPopup()">
            <span>Filter</span>
            <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M3.25 7.60422C3.25 5.52662 4.93423 3.84239 7.01183 3.84239C7.01183 3.84239 15.1667 3.30111 19.0496 3.84239C21.0737 4.12456 22.2946 4.81908 22.75 6.55111C23.1507 8.07514 22.2708 9.66456 21.4398 10.3677C21.4398 10.3677 18.7933 12.0313 17.6261 13.5946C16.5613 15.021 15.9355 15.4772 15.7083 17.2427C15.6613 17.6083 15.6603 18.1946 15.6715 18.6981C15.6857 19.3331 15.6125 19.9717 15.3576 20.5535C15.1368 21.0578 14.8283 21.6654 14.4814 22.068C13.8186 22.8374 13.3869 23.4785 12.3784 23.5975C11.2345 23.7325 9.75 23.4109 9.75 22.2591C9.75 20.6341 10.1366 19.378 9.75 17.6002C9.43075 16.1321 9.17897 14.9459 8.35623 13.9824C8.13085 13.7185 7.89237 13.4655 7.68834 13.1848C7.29516 12.6437 6.58733 11.681 5.95833 10.8847C5.02592 9.70434 3.25 8.78732 3.25 7.60422Z"
                  stroke="#0057B7" stroke-width="2"/>
            </svg>

          </div>
          <div class="sort-by" @click="isSortOpen = !isSortOpen">
            <div>Sort by: <span style="text-transform: capitalize">{{ sortBy }}</span></div>
            <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                 :style="{transform: isSortOpen ? 'rotate(180deg)' : 'rotate(0deg)'}"
            >
              <path
                  d="M2.25 4.67599C3.73045 6.39584 4.34179 7.16878 5.7877 8.92342C6.13291 9.34235 8.25 11.3843 8.63267 11.0927C8.69755 11.0432 8.7255 11.0093 8.78528 10.9561C9.41015 10.4002 9.99667 9.71686 10.5 9.34263C11.8745 8.32066 12.4265 7.49822 13.6958 6.32187C14.2303 5.82655 14.6906 5.35257 15 4.67599"
                  stroke="#0057B7" stroke-width="2" stroke-linecap="round"/>
            </svg>
            <div class="sort-options" v-if="isSortOpen">
              <ul id="hall-of-fame-sort">
                <li @click.stop="sortByHandler('Recent', 'RECENT')">Recent</li>
                <li @click.stop="sortByHandler('More votes', 'MORE_VOTES')">More votes</li>
                <li @click.stop="sortByHandler('Less votes', 'LES_VOTES')">Less votes</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body">
        <div class="body-wrapper" v-if="loadFlag">
          <HallOfFameCardComponent v-for="(item, index) in legends" :item="item" :key="item.legendUuid"
                                   :getCase="getCase" :index="index" :identifier="identifier" :type="generalTab" @openSignInPopup="openSignInPopup"/>
        </div>
        <div class="body-wrapper-else" v-else>
          <PreLoader/>
        </div>
      </div>
    </div>
    <v-pagination
        v-if="legends && legends.length"
        v-model="page"
        :length="count"
        :total-visible="7"
        :color="'#0057B7'"
        prev-icon="<"
        next-icon=">"
        @input="onPagination"
        class="pagination my-5 mt-sm-10"
    ></v-pagination>
    <HallOfFameFilterPopup ref="HallOfFameFilterPopup" @submitFilter="submitFilter"/>
  </div>
</template>

<script>

import HallOfFameCardComponent from "@/components/hall-of-fame/HallOfFameCardComponent";
import {mapActions, mapMutations} from "vuex";
import PreLoader from "@/components/elements/Preloader";
import HallOfFameFilterPopup from "@/components/popups/HallOfFameFilterPopup";
import debounce from "@/mixins/debouncer";

export default {
  name: "HallOfFameView",
  components: { HallOfFameFilterPopup, PreLoader, HallOfFameCardComponent},
  mixins: [debounce],
  data() {
    return {
      sortBy: 'recent',
      orderBy: 'RECENT',
      isSortOpen: false,
      legends: [],
      loadFlag: false,
      identifier: 'hall-of-fame-audio',
      page: 1,
      count: null,
      pageSize: 9,
      searchText: '',
      era: '',
      genres: '',
      debounceRequest: null,
      legendSongsAmount: null
    }
  },
  computed: {
    getCase() {
      let result
      if (this.generalTab === 'SONG' && this.contentTab === 'voting') {
        result = 'case1'
      } else if (this.generalTab === 'SONG' && this.contentTab === 'legends') {
        result = 'case2'
      } else if (this.generalTab === 'PERSON' && this.contentTab === 'voting') {
        result = 'case3'
      } else if (this.generalTab === 'PERSON' && this.contentTab === 'legends') {
        result = 'case4'
      }
      return result
    },
    generalTab() {
      return this.$route.query.generalTab
    },
    contentTab() {
      return this.$route.query.contentTab
    },
  },
  methods: {
    ...mapActions({
      getLegends: 'hallOfFame/getAllLegends',
      getLegendSongsAmount: 'hallOfFame/getLegendSongsAmount'
    }),
    ...mapMutations({
      openSignInPopup: 'authentication/openSignInPopup'
    }),
    contentTabSwitchTo(name) {
      if (name === 'voting') {
      this.$router.push({path: `/hall-of-fame`, query: {generalTab: this.$route.query.generalTab, contentTab: 'voting', page: '0'}}).catch(() => {})
        // this.contentTab = 'voting'
      }
      if (name === 'legends') {
        this.$router.push({path: `/hall-of-fame`, query: {generalTab: this.$route.query.generalTab, contentTab: 'legends', page: '0'}}).catch(() => {})
        // this.contentTab = 'legends'
      }
    },
    sortByHandler(name, value) {
      this.sortBy = name
      this.orderBy = value
      this.isSortOpen = false
    },
    async getLegendsRequest() {
      let payload = {
        params: this.getRequestParams(this.page, this.pageSize, this.$route.query.generalTab),
        body: this.getRequestBody(this.searchText, this.orderBy, this.era, this.genres)
      }
      this.loadFlag = false
      let res = await this.getLegends(payload)
      this.legendSongsAmount = (await this.getLegendSongsAmount()).value
      this.count = res.totalPages
      this.legends = res.elements
      this.loadFlag = true
    },
    linkGen(pageNum) {
      return {
        path: `/hall-of-fame`,
        query: {page: pageNum, generalTab: this.$route.query.generalTab, contentTab: this.$route.query.contentTab}
      }
    },
    getRequestParams(page, pageSize, legendType) {
      let params = {};

      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }
      if (legendType) {
        params["legendType"] = legendType;
      }
      params["isLegend"] = this.$route.query.contentTab !== 'voting';
      params["sort"] = ""

      return params;
    },
    getRequestBody(searchText, orderBy, eras, genres) {
      let body = {};


      body["searchText"] = searchText;

      if (orderBy) {
        body["orderBy"] = orderBy;
      }
      if (eras) {
        body["eras"] = [eras];
      }
      if (genres) {
        body["genres"] = genres;
      }
      return body;
    },
    onPagination() {
      this.$router.push(this.linkGen(this.page)).catch(() => {
      })
    },
    submitFilter(data) {
      this.era = data.era
      this.genres = data.genres
      this.getLegendsRequest()
    }
  },
  watch: {
    '$route.query.generalTab'(newVal) {
      if (newVal) {
        this.era = ''
        this.genres = ''
        if (this.$route.query.page === '1') {
          this.getLegendsRequest()
        } else {
          this.$router.push(this.linkGen(1)).catch(() => {
          })
        }
      }
    },
    '$route.query.contentTab'(newVal) {
      if (newVal) {
        this.era = ''
        this.genres = ''
        if (this.$route.query.page === '1') {
          this.getLegendsRequest()
        } else {
          this.$router.push(this.linkGen(1)).catch(() => {
          })
        }
      }
    },
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.page = +newVal
          this.getLegendsRequest()
          if (this.count < newVal) {
            this.page = 1
          }
        } else {
          this.page = 1
        }
      }
    },
    orderBy(newVal) {
      if (newVal) {
        this.getLegendsRequest()
      }
    }
  },
  created() {
    this.getLegendsRequest()
  },
  mounted() {
    this.debounceRequest = this.debounce(() => {
      this.getLegendsRequest()
    }, 500)
  }
}
</script>

<style scoped>
.hall-of-fame__header.desktop {
  display: flex;
}

.mobile {
  display: none;
}

.hall-of-fame__header {
  justify-content: space-between;
  align-items: center;
}

.right {
  display: flex;
  align-items: center;
}

.total-vp {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #0057B7;
  margin-right: 20px;
}

.total-vp span {
  font-weight: 600;
}

.hall-of-fame__about, .hall-of-fame__playlist {
  margin-top: 40px;
  background: #FFFFFF;
  padding: 40px;
  display: flex;
  border-radius: 10px;
}

.hall-of-fame__playlist {
  flex-direction: column;
}

.about-title {
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 38px;
  color: #0057B7;
}

.about-wrapper {
  margin-right: 60px;
}

.about-wrapper p {
  margin-top: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #000000;
}

.hall-of-fame__about img {
  width: 180px;
}

.hall-of-fame__tabs {
  margin-top: 40px;
}

.tabs-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90.41deg, rgba(0, 110, 233, 0.6) -2.47%, rgba(30, 150, 252, 0.36) 103.59%);
  border-radius: 10px;
  padding: 15px;
}

.tabs-item {
  width: calc(50% - 10px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 13px 0;
  cursor: pointer;
}


.hall-of-fame__content {
  background: #FFFFFF;
  border-radius: 10px;
  margin-top: 40px;
}

.content-header {
  padding: 20px 40px;
  border: 1px solid #D9D9D9;
  border-radius: 10px 10px 0 0;
}

.content-tabs-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-tab-item {
  width: calc(50% - 10px);
  padding: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
}

.content-header__search {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-input {
  position: relative;
  margin-right: 20px;
  flex: 1;
}

.search-input input {
  padding: 15px;
  width: 100%;
}

.search-input svg {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.sort-by {
  position: relative;
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #0057B7;
  cursor: pointer;
}

.sort-by svg {
  position: relative;
  top: 2px;
  margin-left: 8px;
  cursor: pointer;
}

.sort-options {
  position: absolute;
  bottom: -135px;
  left: 0;
  background: #FFFFFF;
  padding: 15px 10px;
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  width: 140px;
}

.sort-options ul li {
  margin-bottom: 15px;
  cursor: pointer;
}

.sort-options ul li:last-child {
  margin-bottom: 0;
}

.body-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.body-wrapper-else {
  display: grid;
  grid-template-columns: 1fr;
}


.filter {
  display: flex;
  align-items: center;
  margin-right: 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #0057B7;
  cursor: pointer;
}

.filter svg {
  margin-left: 6px;
}

.playlist-header {
  display: flex;
}

.playlist-cover {
  width: 128px;
  height: 128px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 40px;
}

.playlist-songs-amount {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #0057B7;
}

.header-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.playlist-name {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  color: #0057B7;
  margin-bottom: 20px;
}

@media only screen and (max-width: 800px) {
  .body-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .hall-of-fame__about img {
    display: none;
  }

  .content-header {
    padding: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .body-wrapper {
    display: grid;
    grid-template-columns: 1fr;
  }

  .hall-of-fame__about, .hall-of-fame__playlist {
    padding: 20px;
  }

  .content-header__search {
    flex-direction: column;
  }

  .search-input {
    width: 100%;
    margin-right: 0;
  }

  .sort-by {
    margin-top: 15px;
  }

  .hall-of-fame__about, .hall-of-fame__tabs {
    margin: 20px 15px;
  }

  .hall-of-fame__header.desktop {
    display: none;
  }

  .mobile {
    display: flex;
  }

  .filter {
    margin-top: 15px;
  }

  .about-wrapper {
    margin-right: 0;
  }
}
</style>