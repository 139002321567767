<template>
  <div class="flex justify-center mx-auto w-7 h-7">
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="$route.name === 'hall of fame' || $route.name === 'induct a legend'">
      <path
          d="M22.0395 12.7441C21.3781 18.9729 16.2535 21.9146 9.80644 22.4344C3.35942 22.9542 3.02842 15.8589 0.714301 12.7441C-1.59982 9.62935 2.69817 2.88125 8.48395 0.804222C14.2697 -1.2728 22.7009 6.51527 22.0395 12.7441Z"
          fill="#FFD700"/>
      <path
          d="M6.1471 10.6154C7.09772 12.6165 9.13739 14 11.5002 14C13.3215 14 15.4909 12.7548 16.5774 11.4615C17.4423 10.432 17.4233 9.52681 17.4233 8.07691C17.4233 6.62701 16.7654 5.29879 15.9005 4.26922C14.8139 2.97589 13.3215 2.15383 11.5002 2.15383C9.88631 2.15383 8.50694 2.79929 7.43864 3.84614C6.34156 4.92119 5.1543 6.41956 5.1543 8.07691C5.1543 8.98534 5.78161 9.846 6.1471 10.6154Z"
          stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path
          d="M8.29338 13.0608C8.29338 13.0608 8.14738 15.873 7.94645 17.3846C7.74575 18.8946 7.26953 20.7692 7.26953 20.7692C8.26086 20.1083 9.80799 19.0769 9.80799 19.0769C9.80799 19.0769 10.7614 18.2308 11.5003 18.2308C12.0228 18.2308 12.3159 18.3946 12.7695 18.6538C13.3782 19.0016 13.4719 19.5108 14.0388 19.9231C14.6363 20.3577 15.7311 20.7692 15.7311 20.7692C15.7311 20.7692 15.8417 19.4725 15.7311 18.6538C15.6398 17.9787 15.39 17.6378 15.308 16.9615C15.2285 16.3054 15.382 15.9259 15.308 15.2692C15.2076 14.3779 14.7072 13.0523 14.7072 13.0523"
          stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"
         class="" v-else>
      <path
          d="M7.56491 14.6667C9.06286 17.8199 12.2769 20 16.0001 20C18.87 20 22.2884 18.038 24.0005 16C25.3634 14.3776 25.3334 12.9513 25.3334 10.6667C25.3334 8.38197 24.2968 6.28902 22.9338 4.66666C21.2217 2.6287 18.87 1.33333 16.0001 1.33333C13.457 1.33333 11.2834 2.35041 9.60006 3.99999C7.87133 5.69402 6.00049 8.05508 6.00049 10.6667C6.00049 12.0981 6.98899 13.4543 7.56491 14.6667Z"
          stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path
          d="M10.9468 18.52C10.9468 18.52 10.7168 22.9514 10.4002 25.3333C10.0839 27.7127 9.3335 30.6667 9.3335 30.6667C10.8956 29.6253 13.3335 28 13.3335 28C13.3335 28 14.8358 26.6667 16.0002 26.6667C16.8235 26.6667 17.2853 26.9249 18.0002 27.3333C18.9592 27.8813 19.1069 28.6837 20.0002 29.3333C20.9418 30.0181 22.6668 30.6667 22.6668 30.6667C22.6668 30.6667 22.8412 28.6234 22.6668 27.3333C22.5231 26.2695 22.1293 25.7323 22.0002 24.6667C21.8749 23.6328 22.1167 23.0349 22.0002 22C21.842 20.5954 21.0535 18.5067 21.0535 18.5067"
          stroke="#0057B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: "NavHallOfFameIcon"
}
</script>

<style scoped>

</style>